import {
  CarDetailExteriorCodeEnum,
  CarDetailTireCodeEnum,
} from '~/shared/config/enums'
import { Polygon } from '~/shared/ui/Polygon/types'

export const CAR_BODY_POLYGONS: Polygon<
  CarDetailExteriorCodeEnum | CarDetailTireCodeEnum | string
>[] = [
  {
    key: 1,
    code: 'FRONT_LEFT_DOOR',
    coordinates: [
      { x: 85, y: 169 },
      { x: 84, y: 181 },
      { x: 82, y: 183 },
      { x: 83, y: 187 },
      { x: 85, y: 249 },
      { x: 68, y: 247 },
      { x: 38, y: 249 },
      { x: 37, y: 171 },
      { x: 58, y: 166 },
      { x: 77, y: 166 },
    ],
  },
  {
    key: 2,
    code: 'FRONT_RIGHT_DOOR',
    coordinates: [
      { x: 281, y: 169 },
      { x: 283, y: 181 },
      { x: 285, y: 182 },
      { x: 284, y: 190 },
      { x: 281, y: 249 },
      { x: 297, y: 247 },
      { x: 327, y: 249 },
      { x: 328, y: 171 },
      { x: 308, y: 166 },
      { x: 300, y: 166 },
      { x: 290, y: 166 },
    ],
  },
  {
    key: 3,
    code: 'REAR_LEFT_DOOR',
    coordinates: [
      { x: 38, y: 249 },
      { x: 69, y: 248 },
      { x: 85, y: 250 },
      { x: 87, y: 296 },
      { x: 88, y: 302 },
      { x: 89, y: 310 },
      { x: 93, y: 319 },
      { x: 88, y: 321 },
      { x: 83, y: 321 },
      { x: 78, y: 320 },
      { x: 73, y: 317 },
      { x: 66, y: 310 },
      { x: 60, y: 304 },
      { x: 55, y: 300 },
      { x: 50, y: 298 },
      { x: 41, y: 295 },
      { x: 39, y: 289 },
      { x: 39, y: 286 },
      { x: 39, y: 282 },
    ],
  },
  {
    key: 4,
    code: 'REAR_RIGHT_DOOR',
    coordinates: [
      { x: 281, y: 249 },
      { x: 297, y: 247 },
      { x: 327, y: 249 },
      { x: 327, y: 285 },
      { x: 327, y: 289 },
      { x: 326, y: 293 },
      { x: 324, y: 295 },
      { x: 322, y: 296 },
      { x: 317, y: 298 },
      { x: 312, y: 300 },
      { x: 308, y: 302 },
      { x: 304, y: 306 },
      { x: 301, y: 309 },
      { x: 298, y: 313 },
      { x: 292, y: 318 },
      { x: 286, y: 321 },
      { x: 281, y: 321 },
      { x: 277, y: 320 },
      { x: 274, y: 318 },
      { x: 276, y: 313 },
      { x: 277, y: 308 },
      { x: 278, y: 303 },
      { x: 278, y: 300 },
    ],
  },
  {
    key: 5,
    code: 'LEFT_THRESHOLD',
    coordinates: [
      { x: 31, y: 164 },
      { x: 37, y: 164 },
      { x: 37, y: 171 },
      { x: 39, y: 286 },
      { x: 32, y: 286 },
    ],
  },
  {
    key: 6,
    code: 'RIGHT_THRESHOLD',
    coordinates: [
      { x: 328, y: 164 },
      { x: 334, y: 164 },
      { x: 333, y: 285 },
      { x: 327, y: 285 },
      { x: 328, y: 172 },
    ],
  },
  {
    key: 7,
    code: 'HOOD',
    coordinates: [
      { x: 135, y: 165 },
      { x: 133, y: 157 },
      { x: 132, y: 151 },
      { x: 132, y: 146 },
      { x: 132, y: 111 },
      { x: 145, y: 99 },
      { x: 172, y: 90 },
      { x: 184, y: 90 },
      { x: 193, y: 90 },
      { x: 219, y: 98 },
      { x: 233, y: 111 },
      { x: 233, y: 153 },
      { x: 231, y: 165 },
      { x: 225, y: 163 },
      { x: 211, y: 160 },
      { x: 199, y: 158 },
      { x: 185, y: 158 },
      { x: 172, y: 158 },
      { x: 159, y: 159 },
      { x: 149, y: 161 },
      { x: 140, y: 163 },
    ],
  },
  {
    key: 8,
    code: 'HOOD',
    coordinates: [
      { x: 293, y: 88 },
      { x: 293, y: 92 },
      { x: 288, y: 108 },
      { x: 286, y: 125 },
      { x: 282, y: 147 },
      { x: 280, y: 152 },
      { x: 280, y: 161 },
      { x: 279, y: 153 },
      { x: 281, y: 139 },
      { x: 284, y: 111 },
      { x: 286, y: 104 },
      { x: 288, y: 98 },
      { x: 290, y: 93 },
    ],
  },
  {
    key: 9,
    code: 'HOOD',
    coordinates: [
      { x: 73, y: 88 },
      { x: 78, y: 99 },
      { x: 82, y: 111 },
      { x: 83, y: 118 },
      { x: 87, y: 153 },
      { x: 86, y: 162 },
      { x: 86, y: 152 },
      { x: 84, y: 149 },
      { x: 83, y: 144 },
      { x: 78, y: 110 },
      { x: 73, y: 90 },
    ],
  },
  {
    key: 10,
    code: 'TRUNK',
    coordinates: [
      { x: 144, y: 360 },
      { x: 151, y: 361 },
      { x: 159, y: 362 },
      { x: 170, y: 363 },
      { x: 184, y: 363 },
      { x: 195, y: 363 },
      { x: 204, y: 362 },
      { x: 216, y: 361 },
      { x: 222, y: 360 },
      { x: 226, y: 364 },
      { x: 226, y: 369 },
      { x: 226, y: 369 },
      { x: 226, y: 369 },
      { x: 225, y: 376 },
      { x: 223, y: 383 },
      { x: 217, y: 385 },
      { x: 206, y: 387 },
      { x: 192, y: 389 },
      { x: 174, y: 389 },
      { x: 155, y: 387 },
      { x: 142, y: 383 },
      { x: 140, y: 374 },
      { x: 139, y: 367 },
      { x: 140, y: 364 },
      { x: 141, y: 362 },
    ],
  },
  {
    key: 11,
    code: 'TRUNK',
    coordinates: [
      { x: 94, y: 362 },
      { x: 93, y: 375 },
      { x: 93, y: 386 },
      { x: 92, y: 387 },
      { x: 84, y: 387 },
      { x: 85, y: 384 },
      { x: 86, y: 381 },
      { x: 87, y: 375 },
      { x: 89, y: 369 },
      { x: 91, y: 366 },
      { x: 94, y: 363 },
    ],
  },
  {
    key: 12,
    code: 'TRUNK',
    coordinates: [
      { x: 272, y: 364 },
      { x: 277, y: 369 },
      { x: 278, y: 374 },
      { x: 279, y: 380 },
      { x: 280, y: 383 },
      { x: 282, y: 388 },
      { x: 274, y: 387 },
      { x: 273, y: 386 },
      { x: 273, y: 384 },
      { x: 274, y: 377 },
      { x: 273, y: 372 },
    ],
  },
  {
    key: 13,
    code: 'TRUNK',
    coordinates: [
      { x: 141, y: 404 },
      { x: 144, y: 400 },
      { x: 148, y: 399 },
      { x: 159, y: 399 },
      { x: 185, y: 399 },
      { x: 211, y: 399 },
      { x: 222, y: 400 },
      { x: 226, y: 402 },
      { x: 227, y: 405 },
      { x: 224, y: 409 },
      { x: 207, y: 410 },
      { x: 205, y: 411 },
      { x: 203, y: 413 },
      { x: 209, y: 418 },
      { x: 219, y: 418 },
      { x: 215, y: 426 },
      { x: 212, y: 428 },
      { x: 209, y: 429 },
      { x: 190, y: 429 },
      { x: 157, y: 430 },
      { x: 153, y: 427 },
      { x: 152, y: 425 },
      { x: 148, y: 418 },
      { x: 159, y: 418 },
      { x: 165, y: 413 },
      { x: 161, y: 410 },
      { x: 159, y: 410 },
      { x: 144, y: 409 },
      { x: 144, y: 409 },
      { x: 141, y: 406 },
    ],
  },
  {
    key: 14,
    code: 'ROOF',
    coordinates: [
      { x: 147, y: 216 },
      { x: 172, y: 213 },
      { x: 194, y: 213 },
      { x: 218, y: 217 },
      { x: 216, y: 320 },
      { x: 203, y: 322 },
      { x: 190, y: 323 },
      { x: 176, y: 323 },
      { x: 162, y: 322 },
      { x: 150, y: 320 },
    ],
  },
  {
    key: 15,
    code: 'REAR_GLASS',
    coordinates: [
      { x: 143, y: 360 },
      { x: 142, y: 356 },
      { x: 142, y: 350 },
      { x: 142, y: 342 },
      { x: 142, y: 329 },
      { x: 143, y: 325 },
      { x: 144, y: 320 },
      { x: 145, y: 319 },
      { x: 147, y: 319 },
      { x: 149, y: 320 },
      { x: 153, y: 321 },
      { x: 159, y: 322 },
      { x: 169, y: 323 },
      { x: 180, y: 323 },
      { x: 193, y: 323 },
      { x: 202, y: 322 },
      { x: 211, y: 321 },
      { x: 215, y: 320 },
      { x: 219, y: 319 },
      { x: 221, y: 319 },
      { x: 222, y: 322 },
      { x: 223, y: 334 },
      { x: 223, y: 338 },
      { x: 223, y: 345 },
      { x: 223, y: 354 },
      { x: 222, y: 360 },
      { x: 214, y: 361 },
      { x: 206, y: 362 },
      { x: 198, y: 363 },
      { x: 191, y: 363 },
      { x: 181, y: 363 },
      { x: 174, y: 363 },
      { x: 167, y: 363 },
      { x: 159, y: 362 },
      { x: 151, y: 361 },
    ],
  },
  {
    key: 16,
    code: 'FRONT_GLASS',
    coordinates: [
      { x: 134, y: 171 },
      { x: 139, y: 168 },
      { x: 145, y: 166 },
      { x: 153, y: 165 },
      { x: 171, y: 163 },
      { x: 188, y: 163 },
      { x: 204, y: 164 },
      { x: 216, y: 165 },
      { x: 223, y: 167 },
      { x: 227, y: 168 },
      { x: 230, y: 170 },
      { x: 230, y: 175 },
      { x: 228, y: 183 },
      { x: 218, y: 217 },
      { x: 195, y: 212 },
      { x: 174, y: 212 },
      { x: 147, y: 216 },
    ],
  },
  {
    key: 17,
    code: 'FRONT_RIGHT_WING',
    coordinates: [
      { x: 293, y: 103 },
      { x: 290, y: 106 },
      { x: 288, y: 110 },
      { x: 287, y: 118 },
      { x: 286, y: 128 },
      { x: 285, y: 134 },
      { x: 284, y: 141 },
      { x: 283, y: 146 },
      { x: 282, y: 149 },
      { x: 280, y: 152 },
      { x: 280, y: 160 },
      { x: 281, y: 169 },
      { x: 290, y: 166 },
      { x: 307, y: 166 },
      { x: 318, y: 168 },
      { x: 328, y: 171 },
      { x: 328, y: 164 },
      { x: 323, y: 164 },
      { x: 316, y: 163 },
      { x: 310, y: 161 },
      { x: 304, y: 156 },
      { x: 300, y: 151 },
      { x: 297, y: 144 },
      { x: 296, y: 137 },
      { x: 297, y: 132 },
      { x: 298, y: 128 },
      { x: 300, y: 123 },
      { x: 301, y: 121 },
      { x: 300, y: 118 },
      { x: 296, y: 112 },
      { x: 293, y: 116 },
      { x: 291, y: 116 },
      { x: 292, y: 110 },
    ],
  },
  {
    key: 18,
    code: 'FRONT_LEFT_WING',
    coordinates: [
      { x: 74, y: 103 },
      { x: 75, y: 115 },
      { x: 74, y: 116 },
      { x: 72, y: 115 },
      { x: 69, y: 112 },
      { x: 64, y: 120 },
      { x: 68, y: 127 },
      { x: 69, y: 135 },
      { x: 69, y: 143 },
      { x: 66, y: 150 },
      { x: 62, y: 156 },
      { x: 55, y: 161 },
      { x: 49, y: 163 },
      { x: 45, y: 164 },
      { x: 37, y: 164 },
      { x: 37, y: 171 },
      { x: 47, y: 169 },
      { x: 57, y: 166 },
      { x: 76, y: 166 },
      { x: 85, y: 169 },
      { x: 86, y: 153 },
      { x: 84, y: 149 },
      { x: 83, y: 144 },
      { x: 78, y: 112 },
      { x: 77, y: 109 },
      { x: 77, y: 109 },
    ],
  },
  {
    key: 19,
    code: 'REAR_RIGHT_WING',
    coordinates: [
      { x: 284, y: 322 },
      { x: 288, y: 321 },
      { x: 294, y: 317 },
      { x: 298, y: 313 },
      { x: 302, y: 309 },
      { x: 307, y: 303 },
      { x: 313, y: 300 },
      { x: 320, y: 297 },
      { x: 324, y: 295 },
      { x: 326, y: 294 },
      { x: 327, y: 291 },
      { x: 327, y: 285 },
      { x: 333, y: 285 },
      { x: 333, y: 301 },
      { x: 324, y: 301 },
      { x: 319, y: 301 },
      { x: 313, y: 303 },
      { x: 308, y: 306 },
      { x: 304, y: 311 },
      { x: 301, y: 316 },
      { x: 299, y: 323 },
      { x: 299, y: 331 },
      { x: 302, y: 341 },
      { x: 306, y: 346 },
      { x: 307, y: 348 },
      { x: 305, y: 369 },
      { x: 305, y: 373 },
      { x: 302, y: 375 },
      { x: 292, y: 382 },
      { x: 287, y: 359 },
      { x: 286, y: 357 },
      { x: 283, y: 358 },
      { x: 282, y: 362 },
      { x: 282, y: 386 },
      { x: 280, y: 382 },
      { x: 279, y: 376 },
      { x: 278, y: 372 },
      { x: 277, y: 369 },
      { x: 275, y: 367 },
      { x: 272, y: 364 },
      { x: 262, y: 326 },
      { x: 260, y: 321 },
      { x: 257, y: 321 },
      { x: 257, y: 321 },
      { x: 255, y: 312 },
      { x: 253, y: 300 },
      { x: 252, y: 295 },
      { x: 256, y: 294 },
      { x: 257, y: 300 },
      { x: 259, y: 305 },
      { x: 263, y: 311 },
      { x: 267, y: 317 },
      { x: 270, y: 320 },
      { x: 272, y: 320 },
      { x: 272, y: 320 },
      { x: 274, y: 318 },
      { x: 277, y: 320 },
      { x: 280, y: 321 },
    ],
  },
  {
    key: 20,
    code: 'REAR_LEFT_WING',
    coordinates: [
      { x: 111, y: 292 },
      { x: 109, y: 299 },
      { x: 105, y: 308 },
      { x: 101, y: 314 },
      { x: 97, y: 319 },
      { x: 95, y: 320 },
      { x: 93, y: 319 },
      { x: 88, y: 321 },
      { x: 83, y: 321 },
      { x: 80, y: 321 },
      { x: 74, y: 318 },
      { x: 70, y: 315 },
      { x: 64, y: 308 },
      { x: 58, y: 303 },
      { x: 51, y: 299 },
      { x: 45, y: 297 },
      { x: 40, y: 294 },
      { x: 39, y: 291 },
      { x: 39, y: 287 },
      { x: 32, y: 287 },
      { x: 32, y: 301 },
      { x: 39, y: 301 },
      { x: 45, y: 301 },
      { x: 50, y: 302 },
      { x: 55, y: 304 },
      { x: 60, y: 309 },
      { x: 64, y: 314 },
      { x: 66, y: 320 },
      { x: 67, y: 327 },
      { x: 66, y: 334 },
      { x: 64, y: 341 },
      { x: 59, y: 347 },
      { x: 60, y: 368 },
      { x: 61, y: 373 },
      { x: 64, y: 376 },
      { x: 74, y: 382 },
      { x: 76, y: 372 },
      { x: 78, y: 362 },
      { x: 79, y: 358 },
      { x: 81, y: 356 },
      { x: 83, y: 358 },
      { x: 84, y: 362 },
      { x: 84, y: 369 },
      { x: 84, y: 387 },
      { x: 85, y: 383 },
      { x: 86, y: 379 },
      { x: 87, y: 375 },
      { x: 87, y: 373 },
      { x: 88, y: 370 },
      { x: 89, y: 368 },
      { x: 91, y: 366 },
      { x: 93, y: 364 },
      { x: 97, y: 348 },
      { x: 103, y: 329 },
      { x: 107, y: 321 },
      { x: 109, y: 321 },
      { x: 111, y: 311 },
      { x: 114, y: 292 },
    ],
  },
  {
    key: 21,
    code: 'FRONT_RIGHT_GLASS',
    coordinates: [
      { x: 273, y: 189 },
      { x: 263, y: 211 },
      { x: 259, y: 223 },
      { x: 257, y: 233 },
      { x: 256, y: 249 },
      { x: 281, y: 242 },
      { x: 283, y: 190 },
      { x: 282, y: 192 },
      { x: 279, y: 192 },
      { x: 273, y: 191 },
      { x: 273, y: 188 },
    ],
  },
  {
    key: 22,
    code: 'FRONT_LEFT_GLASS',
    coordinates: [
      { x: 94, y: 189 },
      { x: 97, y: 198 },
      { x: 107, y: 221 },
      { x: 109, y: 232 },
      { x: 110, y: 249 },
      { x: 85, y: 241 },
      { x: 83, y: 189 },
      { x: 85, y: 193 },
      { x: 88, y: 193 },
      { x: 93, y: 191 },
      { x: 94, y: 190 },
    ],
  },
  {
    key: 23,
    code: 'REAR_RIGHT_GLASS',
    coordinates: [
      { x: 256, y: 260 },
      { x: 280, y: 257 },
      { x: 278, y: 301 },
      { x: 271, y: 300 },
      { x: 266, y: 299 },
      { x: 261, y: 296 },
      { x: 258, y: 293 },
      { x: 257, y: 285 },
      { x: 256, y: 278 },
    ],
  },
  {
    key: 24,
    code: 'REAR_LEFT_GLASS',
    coordinates: [
      { x: 85, y: 258 },
      { x: 88, y: 301 },
      { x: 98, y: 299 },
      { x: 104, y: 296 },
      { x: 107, y: 294 },
      { x: 108, y: 292 },
      { x: 109, y: 286 },
      { x: 110, y: 274 },
      { x: 110, y: 260 },
    ],
  },
  {
    key: 25,
    code: 'FRONT_BUMPER',
    coordinates: [
      { x: 139, y: 22 },
      { x: 128, y: 22 },
      { x: 128, y: 25 },
      { x: 129, y: 27 },
      { x: 128, y: 39 },
      { x: 128, y: 50 },
      { x: 129, y: 55 },
      { x: 138, y: 52 },
      { x: 146, y: 50 },
      { x: 158, y: 47 },
      { x: 146, y: 58 },
      { x: 176, y: 55 },
      { x: 192, y: 55 },
      { x: 223, y: 59 },
      { x: 214, y: 50 },
      { x: 213, y: 48 },
      { x: 225, y: 50 },
      { x: 234, y: 53 },
      { x: 239, y: 55 },
      { x: 240, y: 51 },
      { x: 240, y: 27 },
      { x: 241, y: 26 },
      { x: 241, y: 24 },
      { x: 240, y: 22 },
      { x: 233, y: 22 },
      { x: 228, y: 22 },
      { x: 224, y: 22 },
      { x: 226, y: 25 },
      { x: 229, y: 26 },
      { x: 234, y: 30 },
      { x: 235, y: 32 },
      { x: 235, y: 33 },
      { x: 230, y: 41 },
      { x: 227, y: 44 },
      { x: 223, y: 44 },
      { x: 215, y: 41 },
      { x: 211, y: 40 },
      { x: 157, y: 40 },
      { x: 152, y: 41 },
      { x: 147, y: 43 },
      { x: 145, y: 44 },
      { x: 142, y: 44 },
      { x: 139, y: 42 },
      { x: 136, y: 37 },
      { x: 135, y: 34 },
      { x: 134, y: 31 },
      { x: 137, y: 28 },
      { x: 140, y: 26 },
      { x: 142, y: 25 },
      { x: 144, y: 22 },
    ],
  },
  {
    key: 26,
    code: 'FRONT_BUMPER',
    coordinates: [
      { x: 158, y: 88 },
      { x: 175, y: 83 },
      { x: 188, y: 83 },
      { x: 208, y: 88 },
      { x: 215, y: 92 },
      { x: 215, y: 95 },
      { x: 219, y: 98 },
      { x: 193, y: 90 },
      { x: 172, y: 90 },
      { x: 145, y: 99 },
      { x: 150, y: 96 },
      { x: 150, y: 92 },
    ],
  },
  {
    key: 27,
    code: 'FRONT_RIGHT_BUMPER',
    coordinates: [
      { x: 296, y: 84 },
      { x: 300, y: 83 },
      { x: 307, y: 81 },
      { x: 307, y: 82 },
      { x: 308, y: 85 },
      { x: 311, y: 86 },
      { x: 320, y: 91 },
      { x: 324, y: 92 },
      { x: 325, y: 91 },
      { x: 325, y: 88 },
      { x: 325, y: 82 },
      { x: 326, y: 80 },
      { x: 327, y: 78 },
      { x: 329, y: 78 },
      { x: 330, y: 81 },
      { x: 332, y: 85 },
      { x: 333, y: 90 },
      { x: 334, y: 111 },
      { x: 327, y: 111 },
      { x: 321, y: 111 },
      { x: 315, y: 112 },
      { x: 311, y: 113 },
      { x: 306, y: 116 },
      { x: 302, y: 120 },
      { x: 297, y: 112 },
      { x: 300, y: 95 },
      { x: 305, y: 83 },
      { x: 298, y: 87 },
      { x: 294, y: 91 },
      { x: 293, y: 89 },
      { x: 293, y: 88 },
      { x: 294, y: 85 },
    ],
  },
  {
    key: 28,
    code: 'FRONT_LEFT_BUMPER',
    coordinates: [
      { x: 60, y: 81 },
      { x: 61, y: 81 },
      { x: 63, y: 82 },
      { x: 66, y: 83 },
      { x: 68, y: 83 },
      { x: 70, y: 84 },
      { x: 72, y: 85 },
      { x: 73, y: 89 },
      { x: 71, y: 88 },
      { x: 66, y: 85 },
      { x: 63, y: 84 },
      { x: 66, y: 98 },
      { x: 69, y: 112 },
      { x: 64, y: 120 },
      { x: 60, y: 116 },
      { x: 55, y: 113 },
      { x: 48, y: 111 },
      { x: 44, y: 111 },
      { x: 39, y: 111 },
      { x: 32, y: 111 },
      { x: 34, y: 85 },
      { x: 36, y: 81 },
      { x: 38, y: 78 },
      { x: 40, y: 79 },
      { x: 41, y: 82 },
      { x: 42, y: 91 },
      { x: 45, y: 91 },
      { x: 49, y: 90 },
      { x: 53, y: 87 },
      { x: 58, y: 83 },
      { x: 59, y: 82 },
      { x: 60, y: 81 },
    ],
  },
  {
    key: 29,
    code: 'REAR_BUMPER',
    coordinates: [
      { x: 180, y: 452 },
      { x: 164, y: 452 },
      { x: 154, y: 452 },
      { x: 147, y: 449 },
      { x: 144, y: 446 },
      { x: 150, y: 440 },
      { x: 134, y: 440 },
      { x: 131, y: 446 },
      { x: 145, y: 446 },
      { x: 145, y: 448 },
      { x: 133, y: 453 },
      { x: 131, y: 450 },
      { x: 130, y: 446 },
      { x: 129, y: 443 },
      { x: 129, y: 432 },
      { x: 129, y: 421 },
      { x: 131, y: 416 },
      { x: 132, y: 418 },
      { x: 134, y: 419 },
      { x: 138, y: 419 },
      { x: 148, y: 418 },
      { x: 152, y: 427 },
      { x: 156, y: 430 },
      { x: 159, y: 430 },
      { x: 210, y: 429 },
      { x: 213, y: 428 },
      { x: 215, y: 427 },
      { x: 220, y: 418 },
      { x: 234, y: 419 },
      { x: 236, y: 417 },
      { x: 236, y: 414 },
      { x: 239, y: 420 },
      { x: 239, y: 424 },
      { x: 239, y: 432 },
      { x: 238, y: 446 },
      { x: 237, y: 448 },
      { x: 232, y: 441 },
      { x: 221, y: 441 },
      { x: 225, y: 446 },
      { x: 233, y: 446 },
      { x: 236, y: 446 },
      { x: 237, y: 449 },
      { x: 237, y: 451 },
      { x: 236, y: 452 },
      { x: 233, y: 452 },
      { x: 227, y: 449 },
      { x: 222, y: 447 },
      { x: 217, y: 449 },
      { x: 215, y: 451 },
      { x: 199, y: 452 },
    ],
  },
  {
    key: 30,
    code: 'REAR_RIGHT_BUMPER',
    coordinates: [
      { x: 329, y: 369 },
      { x: 332, y: 353 },
      { x: 327, y: 354 },
      { x: 320, y: 354 },
      { x: 314, y: 352 },
      { x: 307, y: 347 },
      { x: 305, y: 373 },
      { x: 304, y: 374 },
      { x: 293, y: 382 },
      { x: 292, y: 389 },
      { x: 297, y: 389 },
      { x: 299, y: 390 },
      { x: 302, y: 393 },
      { x: 316, y: 393 },
      { x: 319, y: 393 },
      { x: 326, y: 389 },
      { x: 328, y: 387 },
      { x: 328, y: 382 },
      { x: 328, y: 378 },
    ],
  },
  {
    key: 31,
    code: 'REAR_LEFT_BUMPER',
    coordinates: [
      { x: 74, y: 382 },
      { x: 74, y: 387 },
      { x: 74, y: 389 },
      { x: 69, y: 389 },
      { x: 67, y: 390 },
      { x: 62, y: 393 },
      { x: 50, y: 392 },
      { x: 48, y: 393 },
      { x: 46, y: 392 },
      { x: 39, y: 387 },
      { x: 38, y: 380 },
      { x: 37, y: 373 },
      { x: 37, y: 369 },
      { x: 36, y: 363 },
      { x: 34, y: 353 },
      { x: 40, y: 354 },
      { x: 47, y: 354 },
      { x: 53, y: 352 },
      { x: 59, y: 347 },
      { x: 60, y: 368 },
      { x: 61, y: 372 },
      { x: 62, y: 375 },
    ],
  },
  {
    key: 32,
    code: 'RIGHT_HEADLIGHT',
    coordinates: [
      { x: 213, y: 48 },
      { x: 222, y: 50 },
      { x: 234, y: 53 },
      { x: 237, y: 54 },
      { x: 239, y: 55 },
      { x: 238, y: 60 },
      { x: 237, y: 62 },
      { x: 233, y: 60 },
      { x: 223, y: 59 },
    ],
  },
  {
    key: 33,
    code: 'RIGHT_HEADLIGHT',
    coordinates: [
      { x: 289, y: 107 },
      { x: 293, y: 92 },
      { x: 298, y: 88 },
      { x: 303, y: 85 },
      { x: 300, y: 94 },
      { x: 298, y: 104 },
      { x: 296, y: 111 },
      { x: 295, y: 113 },
      { x: 295, y: 113 },
      { x: 292, y: 115 },
      { x: 294, y: 102 },
      { x: 290, y: 106 },
      { x: 288, y: 110 },
    ],
  },
  {
    key: 34,
    code: 'LEFT_HEADLIGHT',
    coordinates: [
      { x: 129, y: 55 },
      { x: 132, y: 54 },
      { x: 138, y: 52 },
      { x: 145, y: 50 },
      { x: 152, y: 49 },
      { x: 157, y: 48 },
      { x: 153, y: 52 },
      { x: 145, y: 58 },
      { x: 134, y: 61 },
      { x: 132, y: 62 },
      { x: 130, y: 59 },
      { x: 129, y: 55 },
    ],
  },
  {
    key: 35,
    code: 'LEFT_HEADLIGHT',
    coordinates: [
      { x: 77, y: 109 },
      { x: 74, y: 95 },
      { x: 72, y: 89 },
      { x: 67, y: 86 },
      { x: 63, y: 85 },
      { x: 67, y: 98 },
      { x: 69, y: 111 },
      { x: 73, y: 115 },
      { x: 74, y: 115 },
      { x: 74, y: 113 },
      { x: 74, y: 109 },
      { x: 73, y: 102 },
      { x: 74, y: 103 },
      { x: 78, y: 110 },
    ],
  },
  {
    key: 36,
    code: 'RIGHT_TAILLIGHT',
    coordinates: [
      { x: 204, y: 413 },
      { x: 207, y: 411 },
      { x: 234, y: 409 },
      { x: 236, y: 411 },
      { x: 236, y: 414 },
      { x: 236, y: 417 },
      { x: 234, y: 418 },
      { x: 231, y: 418 },
      { x: 209, y: 418 },
    ],
  },
  {
    key: 37,
    code: 'RIGHT_TAILLIGHT',
    coordinates: [
      { x: 287, y: 358 },
      { x: 287, y: 359 },
      { x: 291, y: 377 },
      { x: 292, y: 383 },
      { x: 292, y: 386 },
      { x: 291, y: 389 },
      { x: 283, y: 388 },
      { x: 282, y: 385 },
      { x: 283, y: 361 },
      { x: 284, y: 357 },
      { x: 286, y: 357 },
    ],
  },
  {
    key: 38,
    code: 'LEFT_TAILLIGHT',
    coordinates: [
      { x: 132, y: 418 },
      { x: 131, y: 414 },
      { x: 132, y: 411 },
      { x: 133, y: 409 },
      { x: 135, y: 409 },
      { x: 140, y: 409 },
      { x: 160, y: 410 },
      { x: 164, y: 413 },
      { x: 158, y: 418 },
      { x: 142, y: 418 },
      { x: 136, y: 418 },
      { x: 136, y: 418 },
    ],
  },
  {
    key: 39,
    code: 'LEFT_TAILLIGHT',
    coordinates: [
      { x: 79, y: 360 },
      { x: 80, y: 357 },
      { x: 81, y: 356 },
      { x: 81, y: 356 },
      { x: 83, y: 359 },
      { x: 84, y: 365 },
      { x: 84, y: 387 },
      { x: 82, y: 388 },
      { x: 74, y: 389 },
      { x: 74, y: 382 },
      { x: 76, y: 370 },
    ],
  },
  {
    key: 40,
    code: 'RIGHT_MIRROR',
    coordinates: [
      { x: 275, y: 183 },
      { x: 279, y: 181 },
      { x: 281, y: 181 },
      { x: 284, y: 182 },
      { x: 284, y: 186 },
      { x: 282, y: 192 },
      { x: 279, y: 192 },
      { x: 276, y: 192 },
      { x: 273, y: 191 },
      { x: 273, y: 188 },
      { x: 274, y: 186 },
    ],
  },
  {
    key: 41,
    code: 'LEFT_MIRROR',
    coordinates: [
      { x: 82, y: 183 },
      { x: 85, y: 181 },
      { x: 90, y: 182 },
      { x: 93, y: 185 },
      { x: 94, y: 189 },
      { x: 93, y: 192 },
      { x: 88, y: 193 },
      { x: 85, y: 193 },
      { x: 83, y: 187 },
    ],
  },
  {
    key: 42,
    code: 'FRONT_RIGHT_FOG_LIGHT',
    coordinates: [
      { x: 223, y: 28 },
      { x: 222, y: 32 },
      { x: 232, y: 34 },
      { x: 232, y: 30 },
      { x: 231, y: 29 },
    ],
  },
  {
    key: 43,
    code: 'FRONT_RIGHT_FOG_LIGHT',
    coordinates: [
      { x: 324, y: 81 },
      { x: 318, y: 81 },
      { x: 318, y: 90 },
      { x: 324, y: 91 },
    ],
  },
  {
    key: 44,
    code: 'FRONT_LEFT_FOG_LIGHT',
    coordinates: [
      { x: 136, y: 30 },
      { x: 146, y: 28 },
      { x: 147, y: 32 },
      { x: 142, y: 33 },
      { x: 136, y: 34 },
    ],
  },
  {
    key: 45,
    code: 'FRONT_LEFT_FOG_LIGHT',
    coordinates: [
      { x: 42, y: 81 },
      { x: 42, y: 90 },
      { x: 49, y: 88 },
      { x: 48, y: 81 },
    ],
  },
  {
    key: 46,
    code: 'REAR_RIGHT_FOG_LIGHT',
    coordinates: [
      { x: 222, y: 441 },
      { x: 232, y: 441 },
      { x: 235, y: 446 },
      { x: 226, y: 446 },
    ],
  },
  {
    key: 47,
    code: 'REAR_LEFT_FOG_LIGHT',
    coordinates: [
      { x: 131, y: 446 },
      { x: 134, y: 440 },
      { x: 149, y: 440 },
      { x: 143, y: 446 },
    ],
  },
  {
    key: 48,
    code: 'FRONT_RIGHT_DRIVE',
    coordinates: [
      { x: 307, y: 132 },
      { x: 311, y: 126 },
      { x: 314, y: 122 },
      { x: 320, y: 120 },
      { x: 326, y: 120 },
      { x: 330, y: 122 },
      { x: 334, y: 125 },
      { x: 337, y: 128 },
      { x: 339, y: 135 },
      { x: 338, y: 141 },
      { x: 336, y: 147 },
      { x: 332, y: 151 },
      { x: 326, y: 152 },
      { x: 319, y: 152 },
      { x: 314, y: 151 },
      { x: 311, y: 148 },
      { x: 307, y: 142 },
      { x: 307, y: 136 },
    ],
  },
  {
    key: 49,
    code: 'FRONT_LEFT_DRIVE',
    coordinates: [
      { x: 29, y: 131 },
      { x: 32, y: 126 },
      { x: 36, y: 123 },
      { x: 41, y: 121 },
      { x: 46, y: 121 },
      { x: 52, y: 123 },
      { x: 56, y: 126 },
      { x: 59, y: 128 },
      { x: 60, y: 134 },
      { x: 60, y: 142 },
      { x: 57, y: 147 },
      { x: 54, y: 150 },
      { x: 46, y: 153 },
      { x: 39, y: 152 },
      { x: 33, y: 149 },
      { x: 29, y: 144 },
      { x: 28, y: 137 },
    ],
  },
  {
    key: 50,
    code: 'REAR_RIGHT_DRIVE',
    coordinates: [
      { x: 306, y: 325 },
      { x: 308, y: 318 },
      { x: 313, y: 314 },
      { x: 320, y: 311 },
      { x: 326, y: 311 },
      { x: 330, y: 312 },
      { x: 334, y: 315 },
      { x: 338, y: 320 },
      { x: 339, y: 325 },
      { x: 339, y: 330 },
      { x: 337, y: 337 },
      { x: 333, y: 341 },
      { x: 327, y: 343 },
      { x: 319, y: 343 },
      { x: 313, y: 341 },
      { x: 310, y: 338 },
      { x: 307, y: 332 },
    ],
  },
  {
    key: 51,
    code: 'REAR_LEFT_DRIVE',
    coordinates: [
      { x: 28, y: 323 },
      { x: 31, y: 317 },
      { x: 35, y: 313 },
      { x: 39, y: 311 },
      { x: 46, y: 311 },
      { x: 51, y: 313 },
      { x: 55, y: 316 },
      { x: 58, y: 320 },
      { x: 60, y: 325 },
      { x: 60, y: 331 },
      { x: 58, y: 337 },
      { x: 53, y: 341 },
      { x: 47, y: 343 },
      { x: 40, y: 342 },
      { x: 34, y: 340 },
      { x: 31, y: 337 },
      { x: 28, y: 331 },
    ],
  },
  {
    key: 52,
    code: 'FRONT_RIGHT_RUBBER',
    coordinates: [
      { x: 320, y: 120 },
      { x: 313, y: 123 },
      { x: 309, y: 127 },
      { x: 304, y: 123 },
      { x: 307, y: 120 },
      { x: 312, y: 117 },
      { x: 318, y: 115 },
      { x: 326, y: 115 },
      { x: 334, y: 118 },
      { x: 339, y: 122 },
      { x: 342, y: 126 },
      { x: 344, y: 130 },
      { x: 344, y: 144 },
      { x: 341, y: 151 },
      { x: 337, y: 155 },
      { x: 331, y: 158 },
      { x: 326, y: 160 },
      { x: 316, y: 159 },
      { x: 312, y: 157 },
      { x: 307, y: 153 },
      { x: 302, y: 148 },
      { x: 300, y: 143 },
      { x: 299, y: 137 },
      { x: 301, y: 128 },
      { x: 304, y: 123 },
      { x: 309, y: 127 },
      { x: 307, y: 131 },
      { x: 306, y: 137 },
      { x: 307, y: 143 },
      { x: 310, y: 148 },
      { x: 315, y: 152 },
      { x: 323, y: 153 },
      { x: 330, y: 152 },
      { x: 335, y: 149 },
      { x: 338, y: 144 },
      { x: 339, y: 139 },
      { x: 338, y: 131 },
      { x: 334, y: 125 },
      { x: 332, y: 123 },
      { x: 329, y: 121 },
      { x: 324, y: 120 },
    ],
  },
  {
    key: 53,
    code: 'FRONT_LEFT_RUBBER',
    coordinates: [
      { x: 39, y: 121 },
      { x: 35, y: 123 },
      { x: 30, y: 128 },
      { x: 25, y: 123 },
      { x: 28, y: 120 },
      { x: 33, y: 117 },
      { x: 39, y: 115 },
      { x: 47, y: 115 },
      { x: 55, y: 117 },
      { x: 61, y: 122 },
      { x: 64, y: 128 },
      { x: 66, y: 133 },
      { x: 66, y: 140 },
      { x: 64, y: 148 },
      { x: 58, y: 155 },
      { x: 53, y: 158 },
      { x: 47, y: 160 },
      { x: 40, y: 160 },
      { x: 33, y: 158 },
      { x: 28, y: 154 },
      { x: 24, y: 148 },
      { x: 22, y: 143 },
      { x: 21, y: 137 },
      { x: 22, y: 131 },
      { x: 25, y: 123 },
      { x: 30, y: 128 },
      { x: 28, y: 133 },
      { x: 27, y: 137 },
      { x: 28, y: 144 },
      { x: 32, y: 149 },
      { x: 36, y: 152 },
      { x: 45, y: 154 },
      { x: 52, y: 152 },
      { x: 57, y: 148 },
      { x: 60, y: 142 },
      { x: 60, y: 140 },
      { x: 60, y: 135 },
      { x: 58, y: 128 },
      { x: 55, y: 124 },
      { x: 51, y: 122 },
      { x: 46, y: 120 },
    ],
  },
  {
    key: 54,
    code: 'REAR_RIGHT_RUBBER',
    coordinates: [
      { x: 319, y: 311 },
      { x: 313, y: 313 },
      { x: 308, y: 317 },
      { x: 305, y: 313 },
      { x: 307, y: 310 },
      { x: 313, y: 307 },
      { x: 319, y: 305 },
      { x: 327, y: 305 },
      { x: 335, y: 308 },
      { x: 341, y: 313 },
      { x: 343, y: 317 },
      { x: 344, y: 320 },
      { x: 344, y: 334 },
      { x: 341, y: 341 },
      { x: 337, y: 345 },
      { x: 332, y: 348 },
      { x: 326, y: 350 },
      { x: 321, y: 350 },
      { x: 313, y: 348 },
      { x: 307, y: 344 },
      { x: 303, y: 339 },
      { x: 301, y: 334 },
      { x: 300, y: 327 },
      { x: 301, y: 320 },
      { x: 305, y: 313 },
      { x: 308, y: 317 },
      { x: 306, y: 323 },
      { x: 306, y: 327 },
      { x: 307, y: 333 },
      { x: 309, y: 338 },
      { x: 315, y: 343 },
      { x: 322, y: 344 },
      { x: 331, y: 342 },
      { x: 335, y: 339 },
      { x: 338, y: 334 },
      { x: 339, y: 330 },
      { x: 339, y: 326 },
      { x: 338, y: 320 },
      { x: 333, y: 314 },
      { x: 330, y: 312 },
      { x: 325, y: 311 },
    ],
  },
  {
    key: 55,
    code: 'REAR_LEFT_RUBBER',
    coordinates: [
      { x: 41, y: 311 },
      { x: 34, y: 314 },
      { x: 30, y: 317 },
      { x: 26, y: 313 },
      { x: 28, y: 310 },
      { x: 33, y: 307 },
      { x: 39, y: 305 },
      { x: 47, y: 304 },
      { x: 56, y: 308 },
      { x: 61, y: 313 },
      { x: 64, y: 317 },
      { x: 65, y: 320 },
      { x: 66, y: 327 },
      { x: 65, y: 335 },
      { x: 62, y: 341 },
      { x: 57, y: 346 },
      { x: 50, y: 349 },
      { x: 41, y: 350 },
      { x: 33, y: 348 },
      { x: 28, y: 344 },
      { x: 24, y: 339 },
      { x: 22, y: 334 },
      { x: 21, y: 327 },
      { x: 22, y: 319 },
      { x: 26, y: 313 },
      { x: 30, y: 317 },
      { x: 27, y: 323 },
      { x: 27, y: 328 },
      { x: 29, y: 334 },
      { x: 32, y: 339 },
      { x: 36, y: 342 },
      { x: 45, y: 343 },
      { x: 51, y: 342 },
      { x: 57, y: 338 },
      { x: 59, y: 335 },
      { x: 60, y: 330 },
      { x: 60, y: 325 },
      { x: 58, y: 318 },
      { x: 54, y: 314 },
      { x: 51, y: 312 },
      { x: 46, y: 311 },
    ],
  },
  {
    key: 56,
    code: 'FRONT_RIGHT_PILLAR',
    coordinates: [
      { x: 253, y: 250 },
      { x: 253, y: 255 },
      { x: 271, y: 252 },
      { x: 281, y: 250 },
      { x: 281, y: 243 },
    ],
  },
  {
    key: 57,
    code: 'FRONT_LEFT_PILLAR',
    coordinates: [
      { x: 85, y: 242 },
      { x: 85, y: 250 },
      { x: 95, y: 252 },
      { x: 113, y: 255 },
      { x: 113, y: 250 },
    ],
  },
  {
    key: 58,
    code: 'REAR_RIGHT_PILLAR',
    coordinates: [
      { x: 253, y: 256 },
      { x: 253, y: 260 },
      { x: 280, y: 257 },
      { x: 280, y: 250 },
    ],
  },
  {
    key: 59,
    code: 'REAR_LEFT_PILLAR',
    coordinates: [
      { x: 85, y: 250 },
      { x: 85, y: 257 },
      { x: 113, y: 260 },
      { x: 113, y: 256 },
    ],
  },
  {
    key: 60,
    code: 'LEFT_PILLAR',
    coordinates: [
      { x: 85, y: 171 },
      { x: 86, y: 165 },
      { x: 102, y: 194 },
      { x: 111, y: 211 },
      { x: 113, y: 216 },
      { x: 116, y: 244 },
      { x: 116, y: 260 },
      { x: 115, y: 276 },
      { x: 114, y: 292 },
      { x: 111, y: 292 },
      { x: 113, y: 259 },
      { x: 113, y: 248 },
      { x: 112, y: 234 },
      { x: 110, y: 222 },
      { x: 105, y: 209 },
      { x: 97, y: 192 },
    ],
  },
  {
    key: 61,
    code: 'RIGHT_PILLAR',
    coordinates: [
      { x: 281, y: 169 },
      { x: 280, y: 163 },
      { x: 270, y: 181 },
      { x: 254, y: 212 },
      { x: 252, y: 221 },
      { x: 250, y: 248 },
      { x: 250, y: 268 },
      { x: 252, y: 294 },
      { x: 256, y: 293 },
      { x: 253, y: 274 },
      { x: 253, y: 253 },
      { x: 254, y: 240 },
      { x: 256, y: 222 },
      { x: 260, y: 210 },
      { x: 269, y: 191 },
    ],
  },
  {
    key: 62,
    code: 'REAR_LEFT_QUARTER_GLASS',
    coordinates: [
      { x: 108, y: 293 },
      { x: 102, y: 298 },
      { x: 97, y: 300 },
      { x: 88, y: 302 },
      { x: 89, y: 309 },
      { x: 92, y: 318 },
      { x: 95, y: 317 },
      { x: 101, y: 309 },
      { x: 104, y: 304 },
      { x: 106, y: 299 },
    ],
  },
  {
    key: 63,
    code: 'REAR_RIGHT_QUARTER_GLASS',
    coordinates: [
      { x: 258, y: 294 },
      { x: 261, y: 302 },
      { x: 265, y: 309 },
      { x: 271, y: 317 },
      { x: 273, y: 318 },
      { x: 276, y: 312 },
      { x: 278, y: 302 },
      { x: 271, y: 301 },
      { x: 264, y: 299 },
      { x: 261, y: 297 },
      { x: 259, y: 295 },
    ],
  },
  {
    key: 64,
    code: 'RADIATOR_GRILLE',
    coordinates: [
      { x: 145, y: 21 },
      { x: 141, y: 27 },
      { x: 136, y: 30 },
      { x: 147, y: 28 },
      { x: 147, y: 33 },
      { x: 142, y: 35 },
      { x: 136, y: 35 },
      { x: 139, y: 40 },
      { x: 143, y: 44 },
      { x: 148, y: 42 },
      { x: 157, y: 40 },
      { x: 212, y: 40 },
      { x: 217, y: 42 },
      { x: 225, y: 44 },
      { x: 229, y: 40 },
      { x: 234, y: 35 },
      { x: 222, y: 33 },
      { x: 223, y: 28 },
      { x: 232, y: 29 },
      { x: 223, y: 23 },
    ],
  },
  {
    key: 65,
    code: 'RADIATOR_GRILLE',
    coordinates: [
      { x: 49, y: 80 },
      { x: 50, y: 88 },
      { x: 55, y: 84 },
      { x: 59, y: 80 },
      { x: 54, y: 79 },
    ],
  },
  {
    key: 66,
    code: 'RADIATOR_GRILLE',
    coordinates: [
      { x: 307, y: 80 },
      { x: 311, y: 79 },
      { x: 317, y: 81 },
      { x: 317, y: 89 },
      { x: 309, y: 85 },
    ],
  },
]
