import { IonToast } from '@ionic/react'
import { useStore } from 'effector-react'
import { ReactNode } from 'react'
import { $notifications, snackbarClosed } from './model'

type Props = { children: ReactNode }

export function Provider({ children }: Props) {
  const notifications = useStore($notifications)

  return (
    <>
      {notifications.map(({ message, variant, key }) => (
        <IonToast
          key={key}
          isOpen
          message={message}
          duration={30000}
          position='top'
          swipeGesture='vertical'
          buttons={[
            {
              text: 'Закрыть',
            },
          ]}
          color={variant}
          translucent
          onDidDismiss={() => snackbarClosed(key)}
        />
      ))}

      <>{children}</>
    </>
  )
}
