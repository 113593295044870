import { z } from 'zod'
import { Inspection } from '~/shared/api'
import { DocumentsTypeEnum } from '~/shared/config/enums'

export const documentsFormSchema = Inspection.schema.pick({
  comment: true,
})

export type TypeFormSchema = z.infer<typeof documentsFormSchema>

export type DocumentsFormValues = TypeFormSchema & {
  documents: Record<DocumentsTypeEnum, boolean>
}
