import { Box, styled } from '@mui/material'
import { HEADER_HEIGHT } from '~/shared/ui/theme'

export const WrapperHeader = styled(Box)`
  height: ${HEADER_HEIGHT};
  display: flex;
  align-items: center;
`
export const WrapperFooter = styled(Box)`
  display: flex;
  justify-content: flex-end;
`
