import { ReactNode, useState } from 'react'
import {
  TransformWrapper,
  TransformComponent,
  ReactZoomPanPinchRef,
} from 'react-zoom-pan-pinch'

type ZoomProps = {
  children: ReactNode
}

export function Zoom({ children }: ZoomProps) {
  const [isPanningDisabled, togglePanningDisabled] = useState<boolean>(true)

  const handleOnZoomStart = ({ state: { scale } }: ReactZoomPanPinchRef) => {
    togglePanningDisabled(scale <= 1)
  }
  return (
    <TransformWrapper
      wheel={{
        disabled: isPanningDisabled,
      }}
      panning={{
        disabled: isPanningDisabled,
      }}
      doubleClick={{
        disabled: true,
      }}
      onZoom={handleOnZoomStart}
    >
      <TransformComponent>{children}</TransformComponent>
    </TransformWrapper>
  )
}
