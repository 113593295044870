import { IonRouterOutlet } from '@ionic/react'
import { ReactNode } from 'react'
import { ErrorBoundary } from '~/shared/ui/ErrorBoundary'

type Props = {
  children?: ReactNode
}

export function MainLayout({ children }: Props) {
  return (
    <ErrorBoundary>
      <IonRouterOutlet>{children || null}</IonRouterOutlet>
    </ErrorBoundary>
  )
}
