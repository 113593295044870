import { IonLoading } from '@ionic/react'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import DeleteIcon from '@mui/icons-material/Delete'
import { Button, Popover } from '@mui/material'
import { useGate, useStore } from 'effector-react'
import { MouseEvent, useEffect, useRef, useState } from 'react'
import { Prompt, useParams } from 'react-router-dom'
import { useCameraContext } from '~/widgets/CameraProvider/context'
import { Inspection, inspectionModel } from '~/features/Inspection'
import { inspectionStepModel } from '~/features/Inspection'
import {
  inspectionFormModel,
  RejectInspectionModal,
} from '~/entities/Inspection'
import { InspectionStepsEnum } from '~/shared/config/enums'
import { pushFx, replaceFx } from '~/shared/lib/history'
import { useModal } from '~/shared/ui/Modal'
import { PageContainer } from '~/shared/ui/PageContainer'
import { Timer } from '~/shared/ui/Timer'
import { LoadingBlock } from './LoadingBlock'

function InspectionPage() {
  const params = useParams<{ carId: string }>()
  const carId = params.carId as string
  const { isCameraOpen } = useCameraContext()

  const contentRef = useRef<HTMLIonContentElement>(null)

  useGate(inspectionStepModel.Gate)
  useGate(inspectionModel.Gate, { carId })

  const isCarLoading = useStore(inspectionModel.requestCarFx.pending)
  const car = useStore(inspectionModel.$car)
  const isInspectionCreatePending = useStore(
    inspectionModel.inspectionCreateFx.pending,
  )
  const isLastInspectionPending = useStore(
    inspectionFormModel.requestLastInspectionFx.pending,
  )

  const { isModalShow, openModal, closeModal } = useModal()

  const [anchorPopover, setAnchorPopover] = useState<HTMLButtonElement | null>(
    null,
  )
  const isOpenPopover = Boolean(anchorPopover)

  const currentStep = useStore(inspectionStepModel.$currentStep)

  const isFirstStep = currentStep === InspectionStepsEnum.SPECIFICATION_STEP

  const backFn = isFirstStep ? openModal : inspectionStepModel.goToBackStep
  const historyBackFn = () => {
    backFn()
    // Возвращаем false для того что бы Prompt не изменил url
    return false
  }

  const handlePopoverOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorPopover(event.currentTarget)
  }

  const handleRejectInspectionModalClose = () => {
    // Push так как после перезагрузки страницы Prompt не видит историю и подставляет предыдущий url
    pushFx(carId)
    closeModal()
  }

  const handlePopoverClose = () => {
    setAnchorPopover(null)
  }
  const handlePopoverReject = () => {
    openModal()
    handlePopoverClose()
  }

  const handleRejectInspectionModalOk = () => {
    replaceFx('/')
    closeModal()
  }
  const isLoading = !car || isCarLoading || isLastInspectionPending

  useEffect(() => {
    contentRef.current?.scrollToTop?.()
  }, [currentStep])

  return (
    <>
      <IonLoading
        isOpen={isInspectionCreatePending}
        message='Сохранение осмотра'
      />
      <PageContainer
        contentRef={contentRef}
        helmetTitle='Осмотр'
        canBack
        backFn={backFn}
        backTitle={isFirstStep ? 'Выйти' : 'Шаг назад'}
        extraBlock={
          !isLoading && (
            <Button variant='text' onClick={handlePopoverOpen}>
              <Timer errorAfterMinutes={20} />
              <ArrowDropDownIcon color='aliceBlue' />
            </Button>
          )
        }
      >
        <Prompt
          when={!isModalShow && !isCameraOpen && !isInspectionCreatePending}
          message={historyBackFn}
        />
        {isLoading ? <LoadingBlock /> : <Inspection carId={carId} />}

        <RejectInspectionModal
          isShow={isModalShow}
          onClose={handleRejectInspectionModalClose}
          onOk={handleRejectInspectionModalOk}
        />
        <Popover
          open={isOpenPopover}
          anchorEl={anchorPopover}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <Button
            variant='text'
            color='black'
            onClick={handlePopoverReject}
            startIcon={<DeleteIcon sx={{ opacity: '0.6' }} />}
          >
            Прервать осмотр
          </Button>
        </Popover>
      </PageContainer>
    </>
  )
}

export default InspectionPage
