import { LoadingButton } from '@mui/lab'
import { Box, Stack, Typography } from '@mui/material'
import { useGate, useStore } from 'effector-react'
import { Modal } from '../Modal'
import { $needUpdateApp, Gate, openAppStore, openAppStoreFx } from './model'

export function ReloadApp() {
  useGate(Gate)

  const needUpdateApp = useStore($needUpdateApp)
  const isLoading = useStore(openAppStoreFx.pending)

  return (
    <Modal isShow={needUpdateApp}>
      <Box>
        <Stack spacing={2}>
          <Typography fontSize='24px' lineHeight='26px'>
            Доступна новая версия.
          </Typography>
          <Typography fontSize='16px' lineHeight='18px'>
            Обновите приложение для дальнейшего использования!
          </Typography>

          <LoadingButton
            variant='limeFlooded'
            fullWidth
            onClick={() => openAppStore()}
            loading={isLoading}
          >
            Обновить
          </LoadingButton>
        </Stack>
      </Box>
    </Modal>
  )
}
