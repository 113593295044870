import MuiArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Button } from '@mui/material'
import { styled } from '@mui/material/styles'

export const HeaderBackButton = styled(Button)(() => ({
  borderRadius: '20px',
  width: '24px',
  height: '24px',
  minWidth: '24px',
  minHeight: '24px',
  margin: 0,
}))

export const ArrowBackIcon = styled(MuiArrowBackIcon)`
  width: 24px;
  height: 24px;
`
