import { createDomain, sample } from 'effector'
import { createGate } from 'effector-react'
import { inspectionFormModel } from '~/entities/Inspection'
import {
  AxiosErrorType,
  Car,
  Inspection,
  InspectionCreateValues,
  ThumbType,
} from '~/shared/api'
import { InspectionPhotoTypeEnum } from '~/shared/config/enums'
import { replaceFx } from '~/shared/lib/history'
import { mapMessageErrors } from '~/shared/lib/mapMessageErrors'
import { snackbarEnqueued } from '~/shared/lib/notifications'
import { isString } from '~/shared/lib/utils'

const domain = createDomain('features.inspection')

export const Gate = createGate<{ carId?: UniqueId | null }>()

const $carId = domain
  .createStore<UniqueId | null | undefined>(null)
  .on(Gate.state, (_, { carId }) => carId)
  .on(Gate.close, () => null)

export async function fetchCarWithRelations(id: UniqueId) {
  const carRes = await Car.with('latestRepairOrderMaintenance').find(id)

  return carRes.getData() as Car
}

export const requestCarFx = domain.createEffect<UniqueId, Car>({
  handler: fetchCarWithRelations,
})

export const $car = domain
  .createStore<Car | null>(null)
  .on(requestCarFx.doneData, (cache, car) => car)
  .on(Gate.close, () => null)

sample({
  clock: $carId,
  filter: isString,
  target: requestCarFx,
})

export const inspectionCreate = domain.createEvent<InspectionCreateValues>()
export const inspectionCreateFx = domain.createEffect<
  InspectionCreateValues,
  void,
  AxiosErrorType
>({
  async handler(values) {
    await Inspection.create(values)
  },
})

export const goToInspectionsFx = domain.createEffect({
  handler() {
    replaceFx('/')
  },
})

sample({
  clock: inspectionCreate,
  target: inspectionCreateFx,
})

sample({
  clock: inspectionCreateFx.doneData,
  fn() {
    return {
      message: 'Осмотр завершен',
      variant: 'success' as const,
    }
  },
  target: [snackbarEnqueued, goToInspectionsFx],
})

sample({
  clock: inspectionCreateFx.failData,
  fn(e) {
    return {
      message: mapMessageErrors(e),
      variant: 'danger' as const,
    }
  },
  target: [snackbarEnqueued],
})

// LastInspection
sample({
  clock: $carId,
  filter: isString,
  target: inspectionFormModel.requestLastInspectionFx,
})

sample({
  clock: Gate.close,
  target: inspectionFormModel.lastInspectionClear,
})

// Save damages map  screen
export const saveDamagesMapScreenFx = domain.createEffect<
  { screen: Blob; carId: UniqueId },
  ThumbType,
  AxiosErrorType
>({
  async handler({ screen, carId }) {
    const sign = new File([screen as Blob], 'damagesMap.jpg', {
      type: 'image/jpeg',
    })
    return await Inspection.savePhoto(
      sign,
      carId,
      InspectionPhotoTypeEnum.DAMAGES_MAP,
    )
  },
})
