import { UseFormReturn } from 'react-hook-form'
import {
  CarUnderHoodInspectionForm,
  CarUnderHoodInspectionFormValues,
  Header,
} from '~/entities/Inspection'
import { goToNextStep } from '../stepModel'

type CarUnderHoodInspectionStepProps = {
  form: UseFormReturn<CarUnderHoodInspectionFormValues>
}

export function CarUnderHoodInspectionStep({
  form,
}: CarUnderHoodInspectionStepProps) {
  const onSuccess = () => {
    goToNextStep()
  }

  return (
    <>
      <Header title='Осмотр под капотом' />

      <CarUnderHoodInspectionForm onSuccess={onSuccess} form={form} />
    </>
  )
}
