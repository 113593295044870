import { Unstable_Grid2 as Grid } from '@mui/material'
import React, { Fragment } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { CheckboxElement } from 'react-hook-form-mui'
import { CarBodyInspectionFormValues } from '~/entities/Inspection'
import { FormHeader } from '~/entities/Inspection/ui/FormHeader'
import {
  diskTypeEnumOptions,
  rubberTypeEnumOptions,
  wheelCodeEnumOptions,
} from '~/shared/config/constants'
import { WheelCodeEnum } from '~/shared/config/enums'
import { DrawerSelect, useDefaultFieldProps } from '~/shared/ui/form'

type WheelsProps = {
  form: UseFormReturn<CarBodyInspectionFormValues>
}

const generateRubberTypeField = (key: WheelCodeEnum): never =>
  `wheels.${key}.rubberType` as never
const generateDiskTypeField = (key: WheelCodeEnum): never =>
  `wheels.${key}.diskType` as never

export function Wheels({ form }: WheelsProps) {
  const { checkboxProps } = useDefaultFieldProps()
  const { watch, setValue, clearErrors } = form

  const isDiscrepanciesRubber = watch('isDiscrepanciesRubber')
  const isDiscrepanciesDisk = watch('isDiscrepanciesDisk')
  const canOneWheelChange = isDiscrepanciesRubber || isDiscrepanciesDisk

  watch((data, { name, type }) => {
    if (type !== 'change') return

    if (name === 'rubberType') {
      wheelCodeEnumOptions.forEach((wheel) => {
        setValue(generateRubberTypeField(wheel.id), data.rubberType as never)
      })
    }
    if (name === 'diskType') {
      wheelCodeEnumOptions.forEach((wheel) => {
        setValue(generateDiskTypeField(wheel.id), data.diskType as never)
      })
    }
    if (name === 'isDiscrepanciesRubber') {
      clearErrors('rubberType')

      if (isDiscrepanciesRubber) return
      wheelCodeEnumOptions.forEach((wheel) => {
        setValue(generateRubberTypeField(wheel.id), data.rubberType as never)
      })
    }
    if (name === 'isDiscrepanciesDisk') {
      clearErrors('diskType')

      if (isDiscrepanciesDisk) return
      wheelCodeEnumOptions.forEach((wheel) => {
        setValue(generateDiskTypeField(wheel.id), data.diskType as never)
      })
    }
  })

  return (
    <>
      <Grid xs={12}>
        <DrawerSelect
          label='Тип резины'
          name='rubberType'
          title='Тип резины'
          defaultOptions={rubberTypeEnumOptions}
          validation={{
            required: !isDiscrepanciesRubber && 'Обязательное поле',
          }}
        />
      </Grid>
      <Grid xs={12}>
        <DrawerSelect
          label='Тип дисков'
          name='diskType'
          title='Тип дисков'
          defaultOptions={diskTypeEnumOptions}
          validation={{ required: !isDiscrepanciesDisk && 'Обязательное поле' }}
        />
      </Grid>
      <Grid xs={12} sx={{ ml: '8px' }}>
        <CheckboxElement
          label='Есть несоответствия в резине'
          name='isDiscrepanciesRubber'
          {...checkboxProps}
        />
      </Grid>
      <Grid xs={12} sx={{ ml: '8px' }}>
        <CheckboxElement
          label='Есть несоответствия в дисках'
          name='isDiscrepanciesDisk'
          {...checkboxProps}
        />
      </Grid>

      {canOneWheelChange &&
        wheelCodeEnumOptions.map((wheel) => (
          <Fragment key={wheel.id}>
            <Grid xs={12} sx={{ mt: '16px' }}>
              <FormHeader title={wheel.label} />
            </Grid>
            {isDiscrepanciesRubber && (
              <Grid xs={12}>
                <DrawerSelect
                  label='Тип резины'
                  name={`wheels.${wheel.id}.rubberType`}
                  title='Тип резины'
                  defaultOptions={rubberTypeEnumOptions}
                  validation={{ required: 'Обязательное поле' }}
                />
              </Grid>
            )}
            {isDiscrepanciesDisk && (
              <Grid xs={12}>
                <DrawerSelect
                  label='Тип дисков'
                  name={`wheels.${wheel.id}.diskType`}
                  title='Тип дисков'
                  defaultOptions={diskTypeEnumOptions}
                  validation={{ required: 'Обязательное поле' }}
                />
              </Grid>
            )}
          </Fragment>
        ))}
    </>
  )
}
