import { Defect } from '~/entities/Inspection'
import { InspectionDamage } from '~/shared/api'

export const getOptionsByType = <T, K>(
  carEquipments: { getType: () => string; getOption: () => K }[],
  type: T,
) => {
  return carEquipments
    .filter((carEquipment) => carEquipment?.getType() === type)
    .map((carEquipment) => carEquipment.getOption())
}

export const mapInspectionDamageToDefect = (
  inspectionDamage: InspectionDamage[],
): Defect[] => {
  return inspectionDamage?.map((damages) => ({
    ...damages.getAttributes(),
    code: damages?.getDetail()?.getCode(),
    id: damages.getApiId() as UniqueId,
    isRepairDamage: false,
    carDamageExtent: damages?.getExtent()?.getOption(),
    carDetail: damages.getDetail()?.getOption(),
  }))
}
