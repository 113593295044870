import CheckIcon from '@mui/icons-material/Check'
import { Button, Typography, Unstable_Grid2 as Grid } from '@mui/material'
import React from 'react'
import { useForm, useFormContext } from 'react-hook-form'
import { CheckboxElement, FormContainer } from 'react-hook-form-mui'
import { useDefaultFieldProps } from '~/shared/ui/form'
import { Modal, useModal } from '~/shared/ui/Modal'

type ModalButtonCheckboxesPros = {
  title: string
  buttonTitle: string
  description: string
  name: string
  options: { id: string; label: string }[]
}

export function ModalButtonCheckboxes({
  title,
  buttonTitle,
  description,
  name,
  options,
}: ModalButtonCheckboxesPros) {
  const { checkboxProps } = useDefaultFieldProps()
  const { setValue, getValues, watch } = useFormContext()
  const getDefaultValuesForm = () => getValues(name) || {}

  const form = useForm({ defaultValues: getDefaultValuesForm() })
  const { isModalShow, openModal, closeModal } = useModal()

  const values = watch(name) || {}
  const isSelectedValue = Object.entries(values).some(([, value]) => value)

  const handleOk = () => {
    const formValues = Object.fromEntries(
      Object.entries(form.getValues()).filter(
        ([, value]) => value !== undefined,
      ),
    )

    closeModal()
    setValue(name, formValues)
  }

  const handleCancel = () => {
    closeModal()
    form.reset(getDefaultValuesForm())
  }

  return (
    <>
      <Button
        onClick={openModal}
        variant='whiteBorder'
        endIcon={
          isSelectedValue && <CheckIcon sx={{ fontSize: '24px !important' }} />
        }
      >
        {buttonTitle}
      </Button>
      <Modal
        isShow={isModalShow}
        onCancel={handleCancel}
        onOk={handleOk}
        ok='Добавить'
      >
        <Typography fontSize={20} fontWeight={500} mb='4px'>
          {title}
        </Typography>
        <Typography fontSize={12} mb='16px'>
          {description}
        </Typography>
        <FormContainer formContext={form}>
          {options.map((option) => (
            <Grid xs={12} key={option.id} sx={{ ml: '8px' }}>
              <CheckboxElement
                label={option.label}
                name={option.id}
                {...checkboxProps}
              />
            </Grid>
          ))}
        </FormContainer>
      </Modal>
    </>
  )
}
