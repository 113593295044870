import { CarDetailInteriorCodeEnum } from '~/shared/config/enums'
import { Polygon } from '~/shared/ui/Polygon/types'

export const CAR_INTERIOR_POLYGONS: Polygon<
  CarDetailInteriorCodeEnum | string
>[] = [
  {
    key: 1,
    code: 'DRIVER_SEAT',
    coordinates: [
      { x: 96, y: 212 },
      { x: 94, y: 211 },
      { x: 91, y: 210 },
      { x: 89, y: 206 },
      { x: 89, y: 203 },
      { x: 89, y: 199 },
      { x: 89, y: 143 },
      { x: 90, y: 139 },
      { x: 91, y: 137 },
      { x: 93, y: 135 },
      { x: 95, y: 134 },
      { x: 99, y: 134 },
      { x: 153, y: 134 },
      { x: 157, y: 135 },
      { x: 159, y: 137 },
      { x: 160, y: 139 },
      { x: 161, y: 141 },
      { x: 161, y: 144 },
      { x: 161, y: 200 },
      { x: 161, y: 204 },
      { x: 160, y: 207 },
      { x: 159, y: 209 },
      { x: 157, y: 211 },
      { x: 154, y: 212 },
      { x: 150, y: 216 },
      { x: 148, y: 218 },
      { x: 145, y: 219 },
      { x: 143, y: 219 },
      { x: 109, y: 219 },
      { x: 105, y: 219 },
      { x: 102, y: 218 },
      { x: 99, y: 215 },
      { x: 97, y: 213 },
    ],
  },
  {
    key: 2,
    code: 'PASSENGER_SEAT',
    coordinates: [
      { x: 214, y: 212 },
      { x: 211, y: 211 },
      { x: 208, y: 208 },
      { x: 207, y: 206 },
      { x: 207, y: 203 },
      { x: 207, y: 199 },
      { x: 206, y: 143 },
      { x: 207, y: 139 },
      { x: 209, y: 136 },
      { x: 210, y: 135 },
      { x: 212, y: 134 },
      { x: 216, y: 134 },
      { x: 272, y: 134 },
      { x: 275, y: 135 },
      { x: 277, y: 136 },
      { x: 279, y: 140 },
      { x: 279, y: 141 },
      { x: 279, y: 143 },
      { x: 279, y: 199 },
      { x: 279, y: 204 },
      { x: 279, y: 206 },
      { x: 277, y: 209 },
      { x: 274, y: 211 },
      { x: 272, y: 212 },
      { x: 267, y: 216 },
      { x: 265, y: 218 },
      { x: 263, y: 219 },
      { x: 260, y: 219 },
      { x: 225, y: 219 },
      { x: 222, y: 219 },
      { x: 219, y: 217 },
      { x: 216, y: 214 },
      { x: 215, y: 213 },
    ],
  },
  {
    key: 3,
    code: 'ARMREST',
    coordinates: [
      { x: 164, y: 163 },
      { x: 204, y: 163 },
      { x: 205, y: 217 },
      { x: 164, y: 217 },
    ],
  },
  {
    key: 4,
    code: 'FRONT_INTERIOR_CARPET',
    coordinates: [
      { x: 212, y: 85 },
      { x: 211, y: 87 },
      { x: 209, y: 88 },
      { x: 209, y: 128 },
      { x: 210, y: 130 },
      { x: 212, y: 130 },
      { x: 215, y: 131 },
      { x: 272, y: 131 },
      { x: 274, y: 130 },
      { x: 276, y: 130 },
      { x: 277, y: 128 },
      { x: 277, y: 123 },
      { x: 276, y: 86 },
    ],
  },
  {
    key: 5,
    code: 'FRONT_INTERIOR_CARPET',
    coordinates: [
      { x: 159, y: 88 },
      { x: 157, y: 85 },
      { x: 153, y: 85 },
      { x: 156, y: 88 },
      { x: 156, y: 92 },
      { x: 155, y: 95 },
      { x: 152, y: 99 },
      { x: 147, y: 102 },
      { x: 141, y: 104 },
      { x: 137, y: 105 },
      { x: 131, y: 106 },
      { x: 124, y: 106 },
      { x: 118, y: 105 },
      { x: 113, y: 104 },
      { x: 109, y: 102 },
      { x: 105, y: 101 },
      { x: 102, y: 97 },
      { x: 100, y: 94 },
      { x: 100, y: 90 },
      { x: 101, y: 88 },
      { x: 102, y: 85 },
      { x: 92, y: 85 },
      { x: 92, y: 127 },
      { x: 92, y: 129 },
      { x: 94, y: 130 },
      { x: 96, y: 130 },
      { x: 98, y: 130 },
      { x: 155, y: 130 },
      { x: 158, y: 130 },
      { x: 159, y: 129 },
      { x: 159, y: 126 },
      { x: 159, y: 122 },
    ],
  },
  {
    key: 6,
    code: 'FRONT_INTERIOR_CARPET',
    coordinates: [
      { x: 107, y: 96 },
      { x: 113, y: 92 },
      { x: 115, y: 92 },
      { x: 117, y: 93 },
      { x: 120, y: 94 },
      { x: 122, y: 95 },
      { x: 123, y: 101 },
      { x: 122, y: 102 },
      { x: 118, y: 101 },
      { x: 114, y: 100 },
      { x: 111, y: 99 },
      { x: 108, y: 97 },
    ],
  },
  {
    key: 7,
    code: 'FRONT_INTERIOR_CARPET',
    coordinates: [
      { x: 133, y: 95 },
      { x: 137, y: 94 },
      { x: 139, y: 92 },
      { x: 141, y: 92 },
      { x: 144, y: 93 },
      { x: 148, y: 96 },
      { x: 146, y: 98 },
      { x: 144, y: 100 },
      { x: 140, y: 101 },
      { x: 138, y: 102 },
      { x: 133, y: 102 },
      { x: 132, y: 100 },
      { x: 133, y: 97 },
      { x: 134, y: 95 },
    ],
  },
  {
    key: 8,
    code: 'BACK_INTERIOR_CARPET',
    coordinates: [
      { x: 93, y: 211 },
      { x: 92, y: 214 },
      { x: 92, y: 216 },
      { x: 92, y: 261 },
      { x: 94, y: 263 },
      { x: 96, y: 263 },
      { x: 100, y: 263 },
      { x: 103, y: 263 },
      { x: 104, y: 261 },
      { x: 107, y: 258 },
      { x: 108, y: 257 },
      { x: 113, y: 257 },
      { x: 257, y: 257 },
      { x: 259, y: 257 },
      { x: 262, y: 259 },
      { x: 265, y: 262 },
      { x: 265, y: 264 },
      { x: 272, y: 264 },
      { x: 275, y: 263 },
      { x: 276, y: 261 },
      { x: 276, y: 259 },
      { x: 276, y: 255 },
      { x: 276, y: 214 },
      { x: 275, y: 212 },
      { x: 273, y: 212 },
      { x: 266, y: 217 },
      { x: 265, y: 218 },
      { x: 263, y: 220 },
      { x: 222, y: 220 },
      { x: 220, y: 219 },
      { x: 218, y: 217 },
      { x: 214, y: 213 },
      { x: 211, y: 212 },
      { x: 209, y: 214 },
      { x: 209, y: 218 },
      { x: 159, y: 218 },
      { x: 158, y: 212 },
      { x: 155, y: 212 },
      { x: 153, y: 213 },
      { x: 152, y: 215 },
      { x: 149, y: 218 },
      { x: 147, y: 219 },
      { x: 145, y: 220 },
      { x: 106, y: 220 },
      { x: 106, y: 220 },
      { x: 102, y: 219 },
      { x: 98, y: 214 },
      { x: 96, y: 212 },
      { x: 96, y: 212 },
    ],
  },
  {
    key: 9,
    code: 'BACK_SEAT',
    coordinates: [
      { x: 104, y: 263 },
      { x: 106, y: 260 },
      { x: 108, y: 258 },
      { x: 111, y: 257 },
      { x: 117, y: 257 },
      { x: 257, y: 257 },
      { x: 260, y: 258 },
      { x: 262, y: 259 },
      { x: 265, y: 262 },
      { x: 265, y: 265 },
      { x: 265, y: 292 },
      { x: 265, y: 316 },
      { x: 265, y: 316 },
      { x: 264, y: 325 },
      { x: 263, y: 335 },
      { x: 262, y: 339 },
      { x: 260, y: 341 },
      { x: 258, y: 342 },
      { x: 256, y: 343 },
      { x: 251, y: 343 },
      { x: 247, y: 343 },
      { x: 245, y: 346 },
      { x: 244, y: 346 },
      { x: 222, y: 346 },
      { x: 220, y: 345 },
      { x: 219, y: 344 },
      { x: 219, y: 343 },
      { x: 212, y: 343 },
      { x: 206, y: 342 },
      { x: 203, y: 341 },
      { x: 201, y: 338 },
      { x: 201, y: 334 },
      { x: 200, y: 328 },
      { x: 199, y: 337 },
      { x: 197, y: 340 },
      { x: 194, y: 341 },
      { x: 174, y: 341 },
      { x: 172, y: 340 },
      { x: 170, y: 335 },
      { x: 170, y: 335 },
      { x: 170, y: 332 },
      { x: 169, y: 326 },
      { x: 168, y: 335 },
      { x: 167, y: 339 },
      { x: 165, y: 341 },
      { x: 162, y: 343 },
      { x: 150, y: 344 },
      { x: 148, y: 346 },
      { x: 145, y: 346 },
      { x: 137, y: 346 },
      { x: 126, y: 346 },
      { x: 124, y: 345 },
      { x: 122, y: 343 },
      { x: 116, y: 343 },
      { x: 111, y: 342 },
      { x: 108, y: 341 },
      { x: 106, y: 338 },
      { x: 105, y: 336 },
      { x: 104, y: 330 },
      { x: 103, y: 316 },
      { x: 104, y: 266 },
    ],
  },
  {
    key: 10,
    code: 'LEFT_DEFLECTORS',
    coordinates: [
      { x: 79, y: 76 },
      { x: 100, y: 76 },
      { x: 102, y: 82 },
      { x: 81, y: 82 },
    ],
  },
  {
    key: 11,
    code: 'RIGHT_DEFLECTORS',
    coordinates: [
      { x: 268, y: 76 },
      { x: 288, y: 76 },
      { x: 286, y: 82 },
      { x: 265, y: 82 },
    ],
  },
  {
    key: 12,
    code: 'CENTRAL_DEFLECTOR',
    coordinates: [
      { x: 159, y: 60 },
      { x: 208, y: 60 },
      { x: 208, y: 74 },
      { x: 159, y: 74 },
    ],
  },
  {
    key: 13,
    code: 'COMFORT_UNIT',
    coordinates: [
      { x: 74, y: 102 },
      { x: 88, y: 103 },
      { x: 87, y: 151 },
      { x: 73, y: 150 },
    ],
  },
  {
    key: 14,
    code: 'COMFORT_UNIT',
    coordinates: [
      { x: 280, y: 103 },
      { x: 293, y: 102 },
      { x: 293, y: 139 },
      { x: 280, y: 140 },
    ],
  },
  {
    key: 15,
    code: 'COMFORT_UNIT',
    coordinates: [
      { x: 292, y: 236 },
      { x: 283, y: 239 },
      { x: 280, y: 275 },
      { x: 291, y: 277 },
    ],
  },
  {
    key: 16,
    code: 'COMFORT_UNIT',
    coordinates: [
      { x: 75, y: 236 },
      { x: 85, y: 238 },
      { x: 87, y: 275 },
      { x: 76, y: 277 },
    ],
  },
  {
    key: 17,
    code: 'CENTRAL_CONSOLE',
    coordinates: [
      { x: 159, y: 74 },
      { x: 208, y: 74 },
      { x: 208, y: 79 },
      { x: 205, y: 90 },
      { x: 205, y: 137 },
      { x: 163, y: 137 },
      { x: 163, y: 89 },
      { x: 159, y: 81 },
    ],
  },
  {
    key: 18,
    code: 'TRUNK_SHELF',
    coordinates: [
      { x: 89, y: 305 },
      { x: 103, y: 315 },
      { x: 105, y: 337 },
      { x: 107, y: 341 },
      { x: 112, y: 343 },
      { x: 117, y: 344 },
      { x: 121, y: 344 },
      { x: 124, y: 346 },
      { x: 130, y: 347 },
      { x: 138, y: 347 },
      { x: 147, y: 347 },
      { x: 150, y: 345 },
      { x: 150, y: 344 },
      { x: 159, y: 344 },
      { x: 164, y: 343 },
      { x: 168, y: 339 },
      { x: 169, y: 329 },
      { x: 170, y: 337 },
      { x: 171, y: 340 },
      { x: 172, y: 341 },
      { x: 197, y: 341 },
      { x: 198, y: 340 },
      { x: 199, y: 338 },
      { x: 200, y: 335 },
      { x: 200, y: 330 },
      { x: 201, y: 339 },
      { x: 202, y: 341 },
      { x: 205, y: 342 },
      { x: 208, y: 343 },
      { x: 218, y: 344 },
      { x: 220, y: 346 },
      { x: 223, y: 347 },
      { x: 229, y: 347 },
      { x: 244, y: 347 },
      { x: 247, y: 345 },
      { x: 247, y: 344 },
      { x: 256, y: 344 },
      { x: 261, y: 341 },
      { x: 263, y: 337 },
      { x: 265, y: 316 },
      { x: 279, y: 306 },
      { x: 279, y: 314 },
      { x: 280, y: 333 },
      { x: 280, y: 344 },
      { x: 279, y: 354 },
      { x: 279, y: 365 },
      { x: 278, y: 369 },
      { x: 275, y: 373 },
      { x: 272, y: 374 },
      { x: 266, y: 374 },
      { x: 226, y: 374 },
      { x: 98, y: 374 },
      { x: 93, y: 373 },
      { x: 91, y: 370 },
      { x: 90, y: 368 },
      { x: 90, y: 365 },
      { x: 90, y: 355 },
      { x: 89, y: 347 },
      { x: 88, y: 334 },
    ],
  },
  {
    key: 19,
    code: 'DASHBOARD',
    coordinates: [
      { x: 109, y: 81 },
      { x: 104, y: 83 },
      { x: 101, y: 86 },
      { x: 102, y: 82 },
      { x: 101, y: 76 },
      { x: 78, y: 76 },
      { x: 81, y: 83 },
      { x: 103, y: 83 },
      { x: 102, y: 86 },
      { x: 88, y: 86 },
      { x: 84, y: 86 },
      { x: 81, y: 86 },
      { x: 79, y: 84 },
      { x: 76, y: 80 },
      { x: 75, y: 75 },
      { x: 75, y: 57 },
      { x: 81, y: 57 },
      { x: 82, y: 56 },
      { x: 83, y: 55 },
      { x: 84, y: 54 },
      { x: 85, y: 51 },
      { x: 88, y: 48 },
      { x: 89, y: 47 },
      { x: 91, y: 43 },
      { x: 91, y: 38 },
      { x: 93, y: 31 },
      { x: 110, y: 27 },
      { x: 138, y: 25 },
      { x: 170, y: 24 },
      { x: 202, y: 24 },
      { x: 247, y: 26 },
      { x: 265, y: 28 },
      { x: 279, y: 32 },
      { x: 280, y: 36 },
      { x: 279, y: 40 },
      { x: 280, y: 42 },
      { x: 282, y: 46 },
      { x: 286, y: 48 },
      { x: 286, y: 52 },
      { x: 288, y: 54 },
      { x: 291, y: 56 },
      { x: 293, y: 56 },
      { x: 293, y: 75 },
      { x: 268, y: 75 },
      { x: 264, y: 83 },
      { x: 286, y: 83 },
      { x: 289, y: 75 },
      { x: 293, y: 75 },
      { x: 291, y: 81 },
      { x: 288, y: 84 },
      { x: 286, y: 86 },
      { x: 279, y: 86 },
      { x: 212, y: 85 },
      { x: 211, y: 88 },
      { x: 209, y: 89 },
      { x: 207, y: 90 },
      { x: 205, y: 90 },
      { x: 208, y: 81 },
      { x: 209, y: 77 },
      { x: 208, y: 75 },
      { x: 209, y: 59 },
      { x: 158, y: 59 },
      { x: 159, y: 82 },
      { x: 163, y: 90 },
      { x: 159, y: 89 },
      { x: 159, y: 87 },
      { x: 157, y: 85 },
      { x: 152, y: 85 },
      { x: 150, y: 83 },
      { x: 147, y: 82 },
      { x: 144, y: 80 },
      { x: 139, y: 79 },
      { x: 135, y: 78 },
      { x: 130, y: 78 },
      { x: 124, y: 78 },
      { x: 117, y: 79 },
      { x: 112, y: 80 },
    ],
  },
]
