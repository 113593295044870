import { Box, CircularProgress } from '@mui/material'

type ProgressHeadingProps = {
  height?: number
  size?: number
}

export function ProgressBlock({ height = 300, size }: ProgressHeadingProps) {
  return (
    <Box
      sx={{
        height: `${height}px`,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress color='prussianBlue' size={size} />
    </Box>
  )
}
