import { createDomain, sample } from 'effector'
import { createGate } from 'effector-react'
import { UseFormReturn } from 'react-hook-form'
import { Defect } from '~/entities/Inspection'
import {
  AxiosErrorType,
  CarDamageExtent,
  CarDamageExtentOption,
  CarDetail,
  CarDetailOption,
  CarEquipment,
  CarEquipmentOption,
  Inspection,
  InspectionDamage,
  InspectionDocumentAttributes,
  InspectionEquipmentAttributes,
  InspectionFetchOption,
  InspectionReason,
  InspectionService,
  InspectionServiceAttributes,
  InspectionWheelsStateAttributes,
  ThumbType,
} from '~/shared/api'
import {
  CarDetailTypeEnum,
  CarEquipmentTypeEnum,
  InspectionPhotoTypeEnum,
  InspectionReasonCodeEnum,
} from '~/shared/config/enums'
import { mapMessageErrors } from '~/shared/lib/mapMessageErrors'
import { snackbarEnqueued } from '~/shared/lib/notifications'
import { isString } from '~/shared/lib/utils'
import { getOptionsByType, mapInspectionDamageToDefect } from './mappers'

const domain = createDomain('entities.inspection')

export const InspectionFormGate = createGate<{ carId: UniqueId | null }>()

export const $createdAt = domain
  .createStore<Date | null>(null)
  .on(InspectionFormGate.state, () => new Date())
  .on(InspectionFormGate.close, () => null)

export const $carId = domain
  .createStore<UniqueId | null | undefined>(null)
  .on(InspectionFormGate.state, (_, { carId }) => carId)
  .on(InspectionFormGate.close, () => null)

export const requestInspectionReasonOptionsFx = domain.createEffect({
  handler: InspectionReason.fetchOptions,
})

sample({
  clock: InspectionFormGate.open,
  target: requestInspectionReasonOptionsFx,
})

export const $inspectionReasonOptions = domain
  .createStore<InspectionFetchOption[]>([])
  .on(requestInspectionReasonOptionsFx.doneData, (_, options) => options)
  .on(InspectionFormGate.close, () => [])

// CarEquipments
export async function fetchCarEquipments() {
  const carEquipments = await CarEquipment.limit(200).get(1)
  return carEquipments.getData() as CarEquipment[]
}

export const requestCarEquipmentsFx = domain.createEffect({
  handler: fetchCarEquipments,
})

sample({
  clock: InspectionFormGate.open,
  target: requestCarEquipmentsFx,
})

export const $carEquipments = domain
  .createStore<CarEquipment[]>([])
  .on(requestCarEquipmentsFx.doneData, (_, carEquipments) => carEquipments)
  .on(InspectionFormGate.close, () => [])

export const $carEquipmentsExteriorOptions = domain
  .createStore<CarEquipmentOption[]>([])
  .on($carEquipments, (_, carEquipments) =>
    getOptionsByType(carEquipments, CarEquipmentTypeEnum.EXTERIOR),
  )
  .on(InspectionFormGate.close, () => [])

export const $carEquipmentsInteriorOptions = domain
  .createStore<CarEquipmentOption[]>([])
  .on($carEquipments, (_, carEquipments) =>
    getOptionsByType(carEquipments, CarEquipmentTypeEnum.INTERIOR),
  )
  .on(InspectionFormGate.close, () => [])

export const $carEquipmentsTrunkOptions = domain
  .createStore<CarEquipmentOption[]>([])
  .on($carEquipments, (_, carEquipments) =>
    getOptionsByType(carEquipments, CarEquipmentTypeEnum.TRUNK),
  )
  .on(InspectionFormGate.close, () => [])

// CarDamageExtents
export async function fetchCarDamageExtents() {
  const carDamageExtents = await CarDamageExtent.limit(200).get(1)
  return carDamageExtents.getData() as CarDamageExtent[]
}

export const requestCarDamageExtentsFx = domain.createEffect({
  handler: fetchCarDamageExtents,
})

sample({
  clock: InspectionFormGate.open,
  target: requestCarDamageExtentsFx,
})

export const $carDamageExtentsOptions = domain
  .createStore<CarDamageExtentOption[]>([])
  .on(requestCarDamageExtentsFx.doneData, (_, carDamageExtents) =>
    carDamageExtents.map((carDamageExtent) => carDamageExtent.getOption()),
  )
  .on(InspectionFormGate.close, () => [])

// CarDetail
export async function fetchCarDetails() {
  const carDetails = await CarDetail.limit(200).get(1)
  return carDetails.getData() as CarDetail[]
}

export const requestCarDetailsFx = domain.createEffect({
  handler: fetchCarDetails,
})

sample({
  clock: InspectionFormGate.open,
  target: requestCarDetailsFx,
})

export const $carDetailsBodyOptions = domain
  .createStore<CarDetailOption[]>([])
  .on(requestCarDetailsFx.doneData, (_, carDetails) => [
    ...getOptionsByType(carDetails, CarDetailTypeEnum.EXTERIOR),
    ...getOptionsByType(carDetails, CarDetailTypeEnum.TIRE),
  ])
  .on(InspectionFormGate.close, () => [])

export const $carDetailsInteriorOptions = domain
  .createStore<CarDetailOption[]>([])
  .on(requestCarDetailsFx.doneData, (_, carDetails) =>
    getOptionsByType(carDetails, CarDetailTypeEnum.INTERIOR),
  )
  .on(InspectionFormGate.close, () => [])

export const $carDetailsEnvironmentOptions = domain
  .createStore<CarDetailOption[]>([])
  .on(requestCarDetailsFx.doneData, (_, carDetails) =>
    getOptionsByType(carDetails, CarDetailTypeEnum.ENVIRONMENT),
  )
  .on(InspectionFormGate.close, () => [])

// File
export const savePhoto = domain.createEvent<{
  file: File
  type: InspectionPhotoTypeEnum
  /* eslint-disable @typescript-eslint/no-explicit-any */
  form: UseFormReturn<any>
  fieldName: string
}>()

export const saveFileFx = domain.createEffect<
  {
    file: File
    carId: UniqueId
    type: InspectionPhotoTypeEnum
    form: UseFormReturn<Record<string, ThumbType[]>>
    fieldName: string
  },
  void,
  AxiosErrorType
>({
  async handler({ file, carId, type, form, fieldName }) {
    const thumb = await Inspection.savePhoto(file, carId, type)
    const prevPhoto = form.getValues(fieldName) || []
    form.setValue(fieldName, [...prevPhoto, thumb])
  },
})

sample({
  clock: savePhoto,
  source: $carId,
  filter: isString,
  fn(carId, values) {
    return { carId, ...values }
  },
  target: saveFileFx,
})

sample({
  clock: saveFileFx.failData,
  filter: (e) => e?.response?.status === 413,
  fn() {
    return {
      message: 'Превышен максимальный размер фотографии 10 Мб.',
      variant: 'danger' as const,
    }
  },
  target: [snackbarEnqueued],
})

sample({
  clock: saveFileFx.failData,
  filter: (e) => e?.response?.status !== 413,
  fn(e) {
    return {
      message: mapMessageErrors(e),
      variant: 'danger' as const,
    }
  },
  target: [snackbarEnqueued],
})

export const $savingPhotosCount = domain
  .createStore<Record<InspectionPhotoTypeEnum, number>>({
    [InspectionPhotoTypeEnum.CAR]: 0,
    [InspectionPhotoTypeEnum.TARIFFS]: 0,
    [InspectionPhotoTypeEnum.LAST_TO]: 0,
    [InspectionPhotoTypeEnum.MILEAGE]: 0,
    [InspectionPhotoTypeEnum.DAMAGE]: 0,
    [InspectionPhotoTypeEnum.DRIVER_SIGNATURE]: 0,
    [InspectionPhotoTypeEnum.DAMAGES_MAP]: 0,
  })
  .on(savePhoto, (state, { type }) => ({
    ...state,
    [type]: state[type] + 1,
  }))
  .on([saveFileFx.done, saveFileFx.fail], (state, { params: { type } }) => ({
    ...state,
    [type]: state[type] - 1,
  }))

// InspectionService
export async function fetchLastServices(carId: UniqueId) {
  const service = await InspectionService.getLastServices(carId)
  return service.getData()
}

export const requestLastServicesFx = domain.createEffect({
  handler: fetchLastServices,
})

sample({
  clock: InspectionFormGate.open,
  source: $carId,
  filter: isString,
  target: requestLastServicesFx,
})

export const $lastServices = domain
  .createStore<InspectionServiceAttributes[]>([])
  .on(requestLastServicesFx.doneData, (_, lastServices) => {
    return lastServices.data.map(
      (service: { attributes: InspectionServiceAttributes }) =>
        service.attributes,
    )
  })
  .on(InspectionFormGate.close, () => [])

// InspectionDamage
export async function fetchDamagesByTypes(
  carId: UniqueId,
  type: CarDetailTypeEnum,
) {
  const inspectionDamage = await InspectionDamage.where('isRepaired', '0')
    .option('filter[detail][type]', type)
    .option('filter[inspection][car][id]', carId)
    .with('detail')
    .with('extent')
    .with('type')
    .limit(100)
    .get(1)
  return inspectionDamage.getData() as InspectionDamage[]
}

export const requestDamagesExteriorFx = domain.createEffect<
  {
    carId: UniqueId
    type: CarDetailTypeEnum
  },
  InspectionDamage[]
>({
  handler({ carId, type }) {
    return fetchDamagesByTypes(carId, type)
  },
})

sample({
  clock: InspectionFormGate.open,
  source: $carId,
  filter: isString,
  fn: (carId) => ({ carId, type: CarDetailTypeEnum.EXTERIOR }),
  target: requestDamagesExteriorFx,
})

export const $unrepairedDamagesExterior = domain
  .createStore<Defect[]>([])
  .on(requestDamagesExteriorFx.doneData, (_, unrepairedDamages) =>
    mapInspectionDamageToDefect(unrepairedDamages),
  )
  .on(InspectionFormGate.close, () => [])

export const requestDamagesTireFx = domain.createEffect<
  {
    carId: UniqueId
    type: CarDetailTypeEnum
  },
  InspectionDamage[]
>({
  handler({ carId, type }) {
    return fetchDamagesByTypes(carId, type)
  },
})

sample({
  clock: InspectionFormGate.open,
  source: $carId,
  filter: isString,
  fn: (carId) => ({ carId, type: CarDetailTypeEnum.TIRE }),
  target: requestDamagesTireFx,
})

export const $unrepairedDamagesTire = domain
  .createStore<Defect[]>([])
  .on(requestDamagesTireFx.doneData, (_, unrepairedDamages) =>
    mapInspectionDamageToDefect(unrepairedDamages),
  )
  .on(InspectionFormGate.close, () => [])

export const requestDamagesInteriorFx = domain.createEffect<
  {
    carId: UniqueId
    type: CarDetailTypeEnum
  },
  InspectionDamage[]
>({
  handler({ carId, type }) {
    return fetchDamagesByTypes(carId, type)
  },
})

sample({
  clock: InspectionFormGate.open,
  source: $carId,
  filter: isString,
  fn: (carId) => ({ carId, type: CarDetailTypeEnum.INTERIOR }),
  target: requestDamagesInteriorFx,
})

export const $unrepairedDamagesInterior = domain
  .createStore<Defect[]>([])
  .on(requestDamagesInteriorFx.doneData, (_, unrepairedDamages) =>
    mapInspectionDamageToDefect(unrepairedDamages),
  )
  .on(InspectionFormGate.close, () => [])

export const requestDamagesEnvironmentFx = domain.createEffect<
  {
    carId: UniqueId
    type: CarDetailTypeEnum
  },
  InspectionDamage[]
>({
  handler({ carId, type }) {
    return fetchDamagesByTypes(carId, type)
  },
})

sample({
  clock: InspectionFormGate.open,
  source: $carId,
  filter: isString,
  fn: (carId) => ({ carId, type: CarDetailTypeEnum.ENVIRONMENT }),
  target: requestDamagesEnvironmentFx,
})

export const $unrepairedDamagesEnvironment = domain
  .createStore<Defect[]>([])
  .on(requestDamagesEnvironmentFx.doneData, (_, unrepairedDamages) =>
    mapInspectionDamageToDefect(unrepairedDamages),
  )
  .on(InspectionFormGate.close, () => [])

// LastInspection
export async function fetchLastInspection(carId: UniqueId) {
  const inspection = await Inspection.limit(1)
    .option('filter[car][id]', carId)
    .with('equipments')
    .with('documents')
    .with('wheelsStates')
    .orderBy('-createdAt')
    .get(1)

  return (inspection.getData()?.[0] || null) as Inspection | null
}

export const requestLastInspectionFx = domain.createEffect({
  handler: fetchLastInspection,
})

export const lastInspectionClear = domain.createEvent()

export const $lastInspection = domain
  .createStore<Inspection | null>(null)
  .on(requestLastInspectionFx.doneData, (_, lastInspection) => lastInspection)
  .on(lastInspectionClear, () => null)

// LastCarEquipments
export const $lastEquipments = domain
  .createStore<InspectionEquipmentAttributes[]>([])
  .on(
    $lastInspection,
    (_, inspection) =>
      inspection
        ?.getEquipments()
        ?.map((equipment) => equipment.getAttributes()) || [],
  )
  .on(lastInspectionClear, () => [])

export const $lastEquipmentsExterior = domain
  .createStore<InspectionEquipmentAttributes[]>([])
  .on($lastEquipments, (_, inspection) =>
    inspection.filter(
      (equipment) => equipment.type === CarEquipmentTypeEnum.EXTERIOR,
    ),
  )
  .on(lastInspectionClear, () => [])

export const $lastEquipmentsInterior = domain
  .createStore<InspectionEquipmentAttributes[]>([])
  .on($lastEquipments, (_, inspection) =>
    inspection.filter(
      (equipment) => equipment.type === CarEquipmentTypeEnum.INTERIOR,
    ),
  )
  .on(lastInspectionClear, () => [])

export const $lastEquipmentsTrunk = domain
  .createStore<InspectionEquipmentAttributes[]>([])
  .on($lastEquipments, (_, inspection) =>
    inspection.filter(
      (equipment) => equipment.type === CarEquipmentTypeEnum.TRUNK,
    ),
  )
  .on(lastInspectionClear, () => [])

// LastDocuments
export const $lastDocuments = domain
  .createStore<InspectionDocumentAttributes[]>([])
  .on(
    $lastInspection,
    (_, inspection) =>
      inspection
        ?.getDocuments()
        ?.map((documents) => documents.getAttributes()) || [],
  )
  .on(lastInspectionClear, () => [])

// LastWheels
export const $lastWheels = domain
  .createStore<InspectionWheelsStateAttributes[]>([])
  .on(
    $lastInspection,
    (_, inspection) =>
      inspection
        ?.getWheelsStates()
        ?.map((documents) => documents.getAttributes()) || [],
  )
  .on(lastInspectionClear, () => [])

// LastInspectionByReason
export async function fetchLastInspectionByReason({
  carId,
  reasonId,
}: {
  carId: UniqueId
  reasonId: UniqueId
}) {
  const inspection = await Inspection.limit(1)
    .option('filter[car][id]', carId)
    .option('filter[reason][id]', reasonId)
    .orderBy('-createdAt')
    .get(1)

  return (inspection.getData()?.[0] || null) as Inspection | null
}

export const requestLastInitialInspectionFx = domain.createEffect({
  handler: fetchLastInspectionByReason,
})

sample({
  clock: $inspectionReasonOptions,
  source: $carId,
  filter: (carId, inspectionReasonOptions) =>
    isString(carId) && Boolean(inspectionReasonOptions?.length),
  fn: (carId, inspectionReasonOptions) => {
    const [reason] = (inspectionReasonOptions || []).filter(
      (reason) => reason.code === InspectionReasonCodeEnum.INITIAL,
    )
    return {
      carId: carId as UniqueId,
      reasonId: reason.id as UniqueId,
    }
  },
  target: requestLastInitialInspectionFx,
})

export const $lastInitialInspection = domain
  .createStore<Inspection | null>(null)
  .on(
    requestLastInitialInspectionFx.doneData,
    (_, lastInitialInspection) => lastInitialInspection,
  )
  .on(InspectionFormGate.close, () => null)

export const requestLastRentalInspectionFx = domain.createEffect({
  handler: fetchLastInspectionByReason,
})

sample({
  clock: $inspectionReasonOptions,
  source: $carId,
  filter: (carId, inspectionReasonOptions) =>
    isString(carId) && Boolean(inspectionReasonOptions?.length),
  fn: (carId, inspectionReasonOptions) => {
    const [reason] = (inspectionReasonOptions || []).filter(
      (reason) => reason.code === InspectionReasonCodeEnum.RENTAL,
    )
    return {
      carId: carId as UniqueId,
      reasonId: reason.id as UniqueId,
    }
  },
  target: requestLastRentalInspectionFx,
})

export const $lastRentalInspection = domain
  .createStore<Inspection | null>(null)
  .on(
    requestLastRentalInspectionFx.doneData,
    (_, lastRentalInspection) => lastRentalInspection,
  )
  .on(InspectionFormGate.close, () => null)

export const requestLastConfiscationInspectionFx = domain.createEffect({
  handler: fetchLastInspectionByReason,
})

sample({
  clock: $inspectionReasonOptions,
  source: $carId,
  filter: (carId, inspectionReasonOptions) =>
    isString(carId) && Boolean(inspectionReasonOptions?.length),
  fn: (carId, inspectionReasonOptions) => {
    const [reason] = (inspectionReasonOptions || []).filter(
      (reason) => reason.code === InspectionReasonCodeEnum.CONFISCATION,
    )
    return {
      carId: carId as UniqueId,
      reasonId: reason.id as UniqueId,
    }
  },
  target: requestLastConfiscationInspectionFx,
})

export const $lastConfiscationInspection = domain
  .createStore<Inspection | null>(null)
  .on(
    requestLastConfiscationInspectionFx.doneData,
    (_, lastConfiscationInspection) => lastConfiscationInspection,
  )
  .on(InspectionFormGate.close, () => null)
