import { useStore } from 'effector-react'
import { useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import {
  DocumentsForm,
  DocumentsFormValues,
  getDocumentShortage,
  Header,
  NotCompleteSetModal,
} from '~/entities/Inspection'
import { $lastDocuments } from '~/entities/Inspection/model'
import { documentsCodeEnumOptions } from '~/shared/config/constants'
import { useModal } from '~/shared/ui/Modal'
import { goToNextStep } from '../stepModel'

type DocumentsStepProps = {
  form: UseFormReturn<DocumentsFormValues>
  isGoToNexStep: boolean
  onSuccess: () => void
  isSubmitting: boolean
}

export function DocumentsStep({
  form,
  isGoToNexStep,
  onSuccess,
  isSubmitting,
}: DocumentsStepProps) {
  const { isModalShow, openModal, closeModal } = useModal()
  const [unselectedDocumentsTypes, setUnselectedDocumentsTypesTypes] = useState<
    string[]
  >([])

  const submitTitle = isGoToNexStep ? 'Передать на подпись' : 'Завершить осмотр'
  const lastDocuments = useStore($lastDocuments)

  const handleNextStep = () => {
    closeModal()
    isGoToNexStep ? goToNextStep() : onSuccess()
  }

  const handleSuccess = () => {
    const foundUnselectedDocuments = getDocumentShortage(
      lastDocuments,
      form.getValues('documents'),
    )
    if (foundUnselectedDocuments?.length) {
      setUnselectedDocumentsTypesTypes(
        foundUnselectedDocuments.map(({ type }) => type),
      )
      openModal()
      return
    }

    handleNextStep()
  }

  return (
    <>
      <Header title='Документы' />

      <DocumentsForm
        onSuccess={handleSuccess}
        form={form}
        submitTitle={submitTitle}
        isSubmitting={isSubmitting}
      />

      <NotCompleteSetModal
        isShow={isModalShow}
        onCancel={closeModal}
        onOk={handleNextStep}
        values={documentsCodeEnumOptions
          .filter(({ id }) => unselectedDocumentsTypes.includes(id))
          .map(({ label }) => label)}
      />
    </>
  )
}
