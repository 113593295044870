import { Box, styled } from '@mui/material'
import { myPalette } from '~/shared/ui/theme'

export const CarData = styled(Box)`
  padding: 16px;
  background: ${myPalette.white.main};
  border-radius: 16px;
  box-shadow: 0 0 5px 0 rgba(0, 21, 70, 0.1);
  margin-bottom: 30px;
`
