import {
  IonContent as IonicIonContent,
  IonToolbar as IonicIonToolbar,
} from '@ionic/react'
import { styled } from '@mui/material'
import { myPalette } from '~/shared/ui/theme/myPalette'

export const IonToolbarHeader = styled(IonicIonToolbar)`
  --background: ${myPalette.prussianBlue.main};
  --border-width: 0 !important;
  --padding-end: 0;
  --padding-start: 0;
  --padding-bottom: 0;
  --padding-top: 0px;
  --box-shadow: none;
`

export const IonToolbarFooter = styled(IonicIonToolbar)`
  --background: ${myPalette.aliceBlue.main};
  --border-width: 0 !important;
  --padding-end: 0;
  --padding-start: 0;
  --padding-bottom: 0;
  --padding-top: 0px;
  --box-shadow: none;
`

export const IonContent = styled(IonicIonContent)`
  --background: ${myPalette.aliceBlue.main};
  --overscroll-behavior: none;
  --webkit-overscroll-behavior: none;
  --padding-top: 24px;
  --padding-bottom: 24px;
  --padding-start: 16px;
  --padding-end: 16px;
`
