import { LoadingButton } from '@mui/lab'
import { Button } from '@mui/material'
import React, { FunctionComponent, ReactNode, useState } from 'react'
import { logger } from '~/shared/lib/logger'
import { Dialog, DialogActions, DialogContent } from './styled'

type ModalProps = {
  children: ReactNode
  isShow: boolean
  ok?: string
  cancel?: string
  onOk?: () => Promise<void> | void
  onCancel?: () => void
  canBackdropClose?: boolean
  reverseButton?: boolean
}
export const Modal: FunctionComponent<ModalProps> = ({
  isShow,
  children,
  ok,
  cancel = 'Отмена',
  onOk,
  onCancel,
  canBackdropClose = true,
  reverseButton,
}) => {
  const [loading, toggleLoading] = useState(false)

  const handleOnClose = () => {
    if (loading) return
    onCancel?.()
  }

  const handleOnOk = async () => {
    try {
      toggleLoading(true)
      await onOk?.()
    } catch (e) {
      logger.error('Modal send error:', e)
    } finally {
      toggleLoading(false)
    }
  }

  return (
    <Dialog
      open={isShow}
      onClose={canBackdropClose ? handleOnClose : undefined}
    >
      <DialogContent>{children}</DialogContent>
      {(onOk || onCancel) && (
        <DialogActions $reverse={reverseButton}>
          {onCancel && (
            <Button
              variant='text'
              size='large'
              color={reverseButton ? 'primary' : 'crimson'}
              onClick={handleOnClose}
              disabled={loading}
              data-testid='modal-cancel-button'
            >
              {cancel}
            </Button>
          )}

          {onOk && (
            <LoadingButton
              variant='text'
              size='large'
              loading={loading}
              onClick={handleOnOk}
              color={reverseButton ? 'crimson' : 'primary'}
              data-testid='modal-ok-button'
            >
              {ok}
            </LoadingButton>
          )}
        </DialogActions>
      )}
    </Dialog>
  )
}
