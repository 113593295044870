import LogoutIcon from '@mui/icons-material/Logout'
import { Typography } from '@mui/material'
import { useCallback } from 'react'
import { viewerModel } from '~/entities/Viewer'
import { Modal, useModal } from '~/shared/ui/Modal'

export function Logout() {
  const { isModalShow, openModal, closeModal } = useModal()

  const handleSignOut = useCallback(async () => {
    await viewerModel.logoutFx()
    closeModal()
  }, [closeModal])

  return (
    <>
      <LogoutIcon
        color='primaryLight'
        sx={{ cursor: 'pointer', fontSize: '29px' }}
        onClick={openModal}
      />
      <Modal
        isShow={isModalShow}
        onCancel={closeModal}
        onOk={handleSignOut}
        cancel='Нет'
        ok='Да'
        canBackdropClose={false}
        reverseButton
      >
        <Typography fontSize='20px' fontWeight={500}>
          Выйти из учетной записи?
        </Typography>
      </Modal>
    </>
  )
}
