import * as z from 'zod'

import { ToOneRelation } from '~/shared/api/core'
import { RepairOrder } from '~/shared/api/repairOrder'
import { dateSchema } from '~/shared/lib/schemas'
import { ApiModel } from './core'

const attributesSchema = z.object({
  model: z.string(),
  plateNumber: z.string(),
  driverFullName: z.string().optional().nullable(),
})

const schema = z
  .object({
    createdAt: dateSchema.optional(),
    updatedAt: dateSchema.optional(),
  })
  .merge(attributesSchema)

export type CarAttributes = z.infer<typeof attributesSchema>

export class Car extends ApiModel<typeof schema, CarAttributes> {
  static jsonApiType = 'cars'

  static schema = schema

  getPlateNumber(): string {
    return this.getAttribute('plateNumber')
  }
  getDriverFullName() {
    return this.getAttribute('driverFullName')
  }

  latestRepairOrderMaintenance(): ToOneRelation<RepairOrder, this> {
    return this.hasOne(RepairOrder)
  }
  getLatestRepairOrderMaintenance(): RepairOrder {
    return this.getRelation('latestRepairOrderMaintenance')
  }
}
