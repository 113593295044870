import { Box, styled } from '@mui/material'
import { myPalette } from '~/shared/ui/theme'

export const Wrapper = styled(Box)`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
`

export const CanvasWrapper = styled(Box)`
  width: 100%;
  height: 100%;
  background: ${myPalette.black.main};
  display: flex;
  justify-content: center;
  align-items: center;
`
