import * as z from 'zod'

import { DocumentsTypeEnum } from '~/shared/config/enums'
import { dateSchema } from '~/shared/lib/schemas'
import { ApiModel } from './core'

const attributesSchema = z.object({
  type: z.nativeEnum(DocumentsTypeEnum),
  value: z.boolean(),
})

const schema = z
  .object({
    createdAt: dateSchema,
    updatedAt: dateSchema,
  })
  .merge(attributesSchema)

export type InspectionDocumentAttributes = z.infer<typeof schema>

export class InspectionDocument extends ApiModel<
  typeof schema,
  InspectionDocumentAttributes
> {
  static jsonApiType = 'inspection-documents'

  static schema = schema
}
