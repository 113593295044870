import * as z from 'zod'

import { dateSchema } from '~/shared/lib/schemas'
import { CarDamageExtent } from './carDamageExtent'
import { CarDetail } from './carDetail'
import { ApiModel, ToOneRelation } from './core'

const attributesSchema = z.object({
  left: z.number(),
  top: z.number(),
  photos: z.array(
    z.object({
      uuid: z.string(),
      previewUrl: z.string(),
      originalUrl: z.string(),
    }),
  ),
})

const schema = z
  .object({
    createdAt: dateSchema.optional(),
    updatedAt: dateSchema.optional(),
  })
  .merge(attributesSchema)

export type InspectionDamageAttributes = z.infer<typeof attributesSchema>

export class InspectionDamage extends ApiModel<
  typeof schema,
  InspectionDamageAttributes
> {
  static jsonApiType = 'inspection-damages'

  static schema = schema

  detail(): ToOneRelation<CarDetail, this> {
    return this.hasOne(CarDetail)
  }
  getDetail(): CarDetail {
    return this.getRelation('detail')
  }

  extent(): ToOneRelation<CarDamageExtent, this> {
    return this.hasOne(CarDamageExtent)
  }
  getExtent(): CarDamageExtent {
    return this.getRelation('extent')
  }
}
