import { useStore } from 'effector-react'
import { requestCarFx } from '~/features/Inspection/model'
import {
  Defect,
  DefectDrawer,
  DefectRepairModal,
  inspectionFormModel,
  useDefect,
} from '~/entities/Inspection'
import { ProgressOverlay } from '~/shared/ui/Loaders'
import { PolygonMarkers } from '~/shared/ui/Polygon'
import carEnvironmentSvg from '~/shared/ui/Polygon/Images/carEnvironment.svg'
import { CAR_ENVIRONMENT_POLYGONS } from './constants'

type EnvironmentMarkersProps = {
  defects: Defect[]
  onDefectsSet: (defects: Defect[]) => void
  canDefectRepair: boolean
}

export function EnvironmentMarkers({
  defects,
  onDefectsSet,
  canDefectRepair,
}: EnvironmentMarkersProps) {
  const isCarDetailsPending = useStore(
    inspectionFormModel.requestCarDetailsFx.pending,
  )
  const isCarDefectsPending = useStore(
    inspectionFormModel.requestDamagesEnvironmentFx.pending,
  )
  const isCarLoading = useStore(requestCarFx.pending)
  const carDetailsEnvironmentOptions = useStore(
    inspectionFormModel.$carDetailsEnvironmentOptions,
  )

  const {
    handleMarkerSelect,
    handleMarkerAdd,
    handleDefectDrawerClose,
    handleDrawerSuccess,
    isDefectDrawerShow,
    selectedDefect,
    markers,
    isDefectRepairModalShow,
    handleDefectModalClose,
    handleDefectRepair,
    handleDefectRepairCancel,
    newMarker,
    handleMarkerDelete,
  } = useDefect(defects, onDefectsSet)

  return (
    <>
      <ProgressOverlay
        loading={!isCarLoading && (isCarDetailsPending || isCarDefectsPending)}
      >
        <PolygonMarkers
          polygons={CAR_ENVIRONMENT_POLYGONS}
          onMarkerSelect={handleMarkerSelect}
          onMarkerAdd={handleMarkerAdd}
          markers={markers}
          img={carEnvironmentSvg}
        />
      </ProgressOverlay>

      <DefectDrawer
        selectedDefect={selectedDefect}
        isShow={isDefectDrawerShow}
        onClose={handleDefectDrawerClose}
        onSuccess={handleDrawerSuccess}
        carDetailsOptions={carDetailsEnvironmentOptions}
        newMarker={newMarker}
        onDeleteMarker={handleMarkerDelete}
      />

      <DefectRepairModal
        selectedDefect={selectedDefect}
        isShow={isDefectRepairModalShow}
        onCancel={handleDefectModalClose}
        onRepairOk={handleDefectRepair}
        onRepairCancel={handleDefectRepairCancel}
        canRepair={canDefectRepair}
      />
    </>
  )
}
