import { PaletteColorOptions } from '@mui/material'
import { ruRU as coreRuRU } from '@mui/material/locale'
import { createTheme } from '@mui/material/styles'
import { myPalette } from './myPalette'
import {
  typography,
  MuiAccordion,
  MuiButton,
  MuiForm,
  MuiList,
  MuiTypography,
} from './overrides'

declare module '@mui/material/styles' {
  interface Theme {
    myPalette: typeof myPalette
  }

  interface PaletteOptions {
    limeGreen?: PaletteColorOptions
    aliceBlue?: PaletteColorOptions
    primaryLight?: PaletteColorOptions
    dimGray?: PaletteColorOptions
  }
}

declare module '@mui/material/colors' {
  interface PaletteOptions {
    limeGreen?: PaletteColorOptions
    aliceBlue?: PaletteColorOptions
    primaryLight?: PaletteColorOptions
    dimGray?: PaletteColorOptions
  }
}

declare module '@mui/material' {
  // Colors
  interface SvgIconPropsColorOverrides {
    limeGreen: true
    aliceBlue: true
    primaryLight: true
    red: true
  }
  interface TypographyPropsVariantOverrides {
    primaryLight: true
    dimGray: true
    red: true
    aliceBlue: true
  }
  interface ButtonPropsColorOverrides {
    limeGreen: true
    aliceBlue: true
    primaryLight: true
    crimson: true
    primary: true
    black: true
  }
  interface CheckboxPropsColorOverrides {
    limeGreen: true
  }
  interface SwitchPropsColorOverrides {
    prussianBlue: true
  }
  interface CircularProgressPropsColorOverrides {
    prussianBlue: true
  }

  // Variants
  interface ButtonPropsVariantOverrides {
    limeFlooded: true
    whiteBorder: true
    warningBorder: true
  }
}
export const BODY_MAX_WIDTH = '540px'
export const HEADER_HEIGHT = '64px'

export const theme = createTheme(
  {
    ...typography,
    components: {
      ...MuiAccordion,
      ...MuiButton,
      ...MuiForm,
      ...MuiTypography,
      ...MuiList,
    },
    palette: myPalette,
  },
  coreRuRU,
)
