import { userOptionsStore } from '~/shared/api'

export const usePermissions = () => {
  const permissions = userOptionsStore.permissions || []

  const checkPermissions = (permission: string) =>
    permissions.includes(permission)

  const canInspectionCreate = checkPermissions('create inspections')

  return {
    canInspectionCreate,
  }
}
