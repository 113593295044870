import { z } from 'zod'
import { Inspection, ThumbType } from '~/shared/api'

export const carCabinInspectionFormSchema = Inspection.schema.pick({
  hasTobaccoSmell: true,
  isInteriorDirty: true,
  isDryCleaningNeed: true,
})

export type TypeFormSchema = z.infer<typeof carCabinInspectionFormSchema>

export type CarCabinInspectionFormValues = TypeFormSchema & {
  tariffsPhotos?: ThumbType[]
  equipmentsInterior: Record<string, boolean>
}
