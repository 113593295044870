import { LoadingButton } from '@mui/lab'
import {
  Box,
  Skeleton,
  Typography,
  Unstable_Grid2 as Grid,
} from '@mui/material'
import { useStore } from 'effector-react'
import React, { useEffect, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import {
  CheckboxElement,
  FormContainer,
  SwitchElement,
} from 'react-hook-form-mui'
import { inspectionFormModel } from '~/entities/Inspection'
import { $savingPhotosCount, savePhoto } from '~/entities/Inspection/model'
import {
  InspectionPhotoTypeEnum,
  InspectionReasonCodeEnum,
} from '~/shared/config/enums'
import { snackbarEnqueued } from '~/shared/lib/notifications'
import { useDefaultFieldProps, Camera } from '~/shared/ui/form'
import { FormHeader } from '../FormHeader'
import { CarCabinInspectionFormValues } from './types'

const peculiarities = [
  { id: 'hasTobaccoSmell', label: 'Запах табака' },
  { id: 'isInteriorDirty', label: 'Грязный салон' },
  { id: 'isDryCleaningNeed', label: 'Требуется химчистка' },
]

type CarBodyInspectionFormProps = {
  onSuccess: () => void
  form: UseFormReturn<CarCabinInspectionFormValues>
  reasonCode: InspectionReasonCodeEnum
}

export function CarCabinInspectionForm({
  onSuccess,
  form,
  reasonCode,
}: CarBodyInspectionFormProps) {
  const savingPhotoCount =
    useStore($savingPhotosCount)[InspectionPhotoTypeEnum.TARIFFS]
  const isPhotoPending = savingPhotoCount > 0

  const { checkboxProps } = useDefaultFieldProps()

  const isTariffsPhotosRequired =
    reasonCode === InspectionReasonCodeEnum.PLAN ||
    reasonCode === InspectionReasonCodeEnum.RENTAL

  const carEquipmentsInteriorOptions = useStore(
    inspectionFormModel.$carEquipmentsInteriorOptions,
  )
  const isCarEquipmentsPending = useStore(
    inspectionFormModel.requestCarEquipmentsFx.pending,
  )

  const [tariffsPhotosErrorMessage, setTariffsPhotosErrorMessage] =
    useState<string>()

  const tariffsPhotos = form.watch('tariffsPhotos')

  useEffect(() => {
    if (tariffsPhotos?.length) {
      setTariffsPhotosErrorMessage(undefined)
    }
  }, [tariffsPhotos])

  const handleOnSuccess = () => {
    if (!tariffsPhotos?.length && isTariffsPhotosRequired) {
      setTariffsPhotosErrorMessage('Загрузите фото тарифной сетки')
      snackbarEnqueued({
        message: 'Для перехода на следующий шаг загрузите фото тарифной сетки',
        variant: 'warning',
      })
      return
    }
    onSuccess()
  }

  return (
    <Box>
      <FormContainer formContext={form} onSuccess={handleOnSuccess}>
        <Grid container rowSpacing={1}>
          <Grid xs={12} sx={{ p: '14px 0' }}>
            <Camera
              buttonTitle='Фото тарифной сетки'
              name='tariffsPhotos'
              saveFileFn={(file) =>
                savePhoto({
                  file,
                  type: InspectionPhotoTypeEnum.TARIFFS,
                  form: form,
                  fieldName: 'tariffsPhotos',
                })
              }
              isLoading={isPhotoPending}
            />
            {tariffsPhotosErrorMessage && (
              <Typography fontSize={12} color='error'>
                {tariffsPhotosErrorMessage}
              </Typography>
            )}
          </Grid>

          <Grid xs={12}>
            <FormHeader title='Комплектность' />
          </Grid>
          {isCarEquipmentsPending && (
            <Skeleton
              variant='rectangular'
              width='100%'
              height={150}
              sx={{ borderRadius: '10px' }}
            />
          )}
          {!isCarEquipmentsPending &&
            carEquipmentsInteriorOptions.map((param) => (
              <Grid xs={12} key={param.id} sx={{ ml: '8px' }}>
                <CheckboxElement
                  label={param.label}
                  name={`equipmentsInterior.${param.id}`}
                  {...checkboxProps}
                />
              </Grid>
            ))}

          <Grid xs={12}>
            <FormHeader title='Особенности' />
          </Grid>
          {peculiarities.map((param) => (
            <Grid xs={12} key={param.id} sx={{ ml: '8px' }}>
              <SwitchElement label={param.label} name={param.id} />
            </Grid>
          ))}

          <Grid xs={12} sx={{ mt: '16px' }}>
            <LoadingButton fullWidth type='submit' variant='limeFlooded'>
              Осмотреть багажник
            </LoadingButton>
          </Grid>
        </Grid>
      </FormContainer>
    </Box>
  )
}
