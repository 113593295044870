import { Button } from '@mui/material'
import { ButtonTypeMap } from '@mui/material/Button/Button'
import { Theme } from '@mui/material/styles'
import { SxProps } from '@mui/system'
import React, { FunctionComponent } from 'react'
import { backFx } from '~/shared/lib/history'
import { HeaderBackButton, ArrowBackIcon } from './styled'

type ButtonBackProps = Partial<ButtonTypeMap['props']> & {
  isIcon?: boolean
  sx?: SxProps<Theme>
  backFn?: () => void
  title?: string
}

export const ButtonBack: FunctionComponent<ButtonBackProps> = ({
  isIcon,
  sx,
  backFn,
  title = 'Назад',
  ...props
}) => {
  const onBack = backFn ? backFn : backFx

  if (isIcon) {
    return (
      <HeaderBackButton onClick={() => onBack()} sx={sx}>
        <ArrowBackIcon color='primaryLight' />
      </HeaderBackButton>
    )
  }

  return (
    <Button
      color='primaryLight'
      onClick={() => onBack()}
      startIcon={<ArrowBackIcon />}
      sx={{
        fontSize: '20px !important',
        textTransform: 'none',
        padding: 0,
        ...sx,
      }}
      {...props}
      data-testid='back-button'
    >
      {title}
    </Button>
  )
}
