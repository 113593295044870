import { CarDetailEnvironmentCodeEnum } from '~/shared/config/enums'
import { Polygon } from '~/shared/ui/Polygon/types'

export const CAR_ENVIRONMENT_POLYGONS: Polygon<
  CarDetailEnvironmentCodeEnum | string
>[] = [
  {
    key: 1,
    code: 'SALON_MIRROR',
    coordinates: [
      { x: 160, y: 160 },
      { x: 162, y: 169 },
      { x: 171, y: 168 },
      { x: 181, y: 168 },
      { x: 200, y: 169 },
      { x: 201, y: 160 },
      { x: 188, y: 159 },
      { x: 180, y: 159 },
      { x: 171, y: 159 },
    ],
  },
  {
    key: 2,
    code: 'CEIL_UPHOLSTERY',
    coordinates: [
      { x: 160, y: 160 },
      { x: 130, y: 164 },
      { x: 133, y: 314 },
      { x: 148, y: 316 },
      { x: 162, y: 317 },
      { x: 179, y: 318 },
      { x: 201, y: 317 },
      { x: 215, y: 316 },
      { x: 228, y: 314 },
      { x: 231, y: 164 },
      { x: 201, y: 160 },
      { x: 201, y: 170 },
      { x: 185, y: 169 },
      { x: 171, y: 169 },
      { x: 162, y: 170 },
    ],
  },
  {
    key: 3,
    code: 'DOOR_UPHOLSTERY',
    coordinates: [
      { x: 275, y: 98 },
      { x: 284, y: 95 },
      { x: 289, y: 94 },
      { x: 312, y: 94 },
      { x: 316, y: 94 },
      { x: 321, y: 95 },
      { x: 343, y: 101 },
      { x: 342, y: 213 },
      { x: 300, y: 210 },
      { x: 275, y: 213 },
      { x: 278, y: 129 },
    ],
  },
  {
    key: 4,
    code: 'DOOR_UPHOLSTERY',
    coordinates: [
      { x: 275, y: 214 },
      { x: 300, y: 211 },
      { x: 342, y: 213 },
      { x: 342, y: 264 },
      { x: 341, y: 271 },
      { x: 339, y: 276 },
      { x: 337, y: 278 },
      { x: 330, y: 281 },
      { x: 322, y: 284 },
      { x: 317, y: 286 },
      { x: 312, y: 290 },
      { x: 307, y: 295 },
      { x: 304, y: 299 },
      { x: 300, y: 303 },
      { x: 296, y: 307 },
      { x: 289, y: 313 },
      { x: 283, y: 315 },
      { x: 279, y: 316 },
      { x: 273, y: 315 },
      { x: 269, y: 314 },
      { x: 265, y: 312 },
      { x: 268, y: 304 },
      { x: 270, y: 294 },
      { x: 272, y: 287 },
      { x: 272, y: 280 },
      { x: 272, y: 271 },
    ],
  },
  {
    key: 5,
    code: 'DOOR_UPHOLSTERY',
    coordinates: [
      { x: 17, y: 101 },
      { x: 37, y: 96 },
      { x: 47, y: 94 },
      { x: 68, y: 94 },
      { x: 74, y: 94 },
      { x: 81, y: 96 },
      { x: 85, y: 97 },
      { x: 82, y: 124 },
      { x: 82, y: 129 },
      { x: 82, y: 140 },
      { x: 85, y: 213 },
      { x: 60, y: 210 },
      { x: 18, y: 213 },
    ],
  },
  {
    key: 6,
    code: 'DOOR_UPHOLSTERY',
    coordinates: [
      { x: 18, y: 214 },
      { x: 60, y: 211 },
      { x: 85, y: 214 },
      { x: 88, y: 278 },
      { x: 89, y: 285 },
      { x: 90, y: 292 },
      { x: 91, y: 299 },
      { x: 93, y: 304 },
      { x: 96, y: 311 },
      { x: 93, y: 314 },
      { x: 86, y: 316 },
      { x: 81, y: 316 },
      { x: 74, y: 314 },
      { x: 67, y: 310 },
      { x: 61, y: 303 },
      { x: 55, y: 296 },
      { x: 50, y: 292 },
      { x: 44, y: 287 },
      { x: 38, y: 284 },
      { x: 31, y: 281 },
      { x: 25, y: 279 },
      { x: 21, y: 277 },
      { x: 19, y: 271 },
      { x: 19, y: 264 },
      { x: 19, y: 260 },
    ],
  },
  {
    key: 7,
    code: 'FRONT_RIGHT_PILLAR_UPHOLSTERY',
    coordinates: [
      { x: 252, y: 100 },
      { x: 249, y: 99 },
      { x: 236, y: 151 },
      { x: 234, y: 158 },
      { x: 231, y: 165 },
      { x: 231, y: 195 },
      { x: 239, y: 164 },
      { x: 252, y: 113 },
    ],
  },
  {
    key: 8,
    code: 'FRONT_LEFT_PILLAR_UPHOLSTERY',
    coordinates: [
      { x: 109, y: 102 },
      { x: 112, y: 100 },
      { x: 116, y: 116 },
      { x: 124, y: 147 },
      { x: 127, y: 157 },
      { x: 130, y: 165 },
      { x: 131, y: 193 },
      { x: 109, y: 113 },
    ],
  },
  {
    key: 9,
    code: 'LEFT_PILLAR_UPHOLSTERY',
    coordinates: [
      { x: 85, y: 202 },
      { x: 125, y: 214 },
      { x: 125, y: 228 },
      { x: 86, y: 224 },
    ],
  },
  {
    key: 10,
    code: 'RIGHT_PILLAR_UPHOLSTERY',
    coordinates: [
      { x: 235, y: 214 },
      { x: 275, y: 202 },
      { x: 274, y: 224 },
      { x: 235, y: 227 },
    ],
  },
  {
    key: 11,
    code: 'REAR_RIGHT_PILLAR_UPHOLSTERY',
    coordinates: [
      { x: 229, y: 277 },
      { x: 250, y: 338 },
      { x: 245, y: 362 },
      { x: 236, y: 371 },
      { x: 238, y: 352 },
      { x: 238, y: 336 },
      { x: 237, y: 323 },
      { x: 235, y: 313 },
      { x: 235, y: 313 },
      { x: 232, y: 312 },
      { x: 231, y: 313 },
      { x: 228, y: 314 },
    ],
  },
  {
    key: 12,
    code: 'REAR_LEFT_PILLAR_UPHOLSTERY',
    coordinates: [
      { x: 125, y: 370 },
      { x: 124, y: 362 },
      { x: 123, y: 352 },
      { x: 123, y: 342 },
      { x: 123, y: 329 },
      { x: 124, y: 322 },
      { x: 126, y: 314 },
      { x: 127, y: 313 },
      { x: 129, y: 312 },
      { x: 131, y: 313 },
      { x: 133, y: 314 },
      { x: 132, y: 275 },
      { x: 112, y: 337 },
      { x: 116, y: 362 },
    ],
  },
]
