import { saveAs } from 'file-saver'
import html2canvas from 'html2canvas'
import { domToBlob } from 'modern-screenshot'

const canvasToBlob = async (canvas: HTMLCanvasElement) =>
  new Promise<Blob | null>((resolve) => {
    canvas.toBlob(resolve)
  })

export const saveElementImage = async (
  element: HTMLElement,
  filename = 'Pic.png',
) => {
  const canvas = await html2canvas(element)
  const blob = await canvasToBlob(canvas)
  if (blob) saveAs(blob, filename)
}

export const getElementBlob = async (
  element: HTMLElement,
): Promise<Blob | null> => {
  return domToBlob(element, {
    quality: 1,
    scale: 2,
  })
}
