import { createContext, useContext } from 'react'

export type CameraContextType = {
  isCameraOpen: boolean
  cameraOpen: (
    fn: (file: File) => void,
    canPaint?: boolean,
    helpers?: string[],
  ) => void
  cameraClose: () => void
}

export const CameraContext = createContext<CameraContextType>(
  {} as CameraContextType,
)

export const useCameraContext = () => useContext(CameraContext)
