import { uniq } from 'lodash'
import {
  CarBodyInspectionFormValues,
  CarCabinInspectionFormValues,
  CarTrunkInspectionFormValues,
  CarUnderHoodInspectionFormValues,
  Defect,
  DocumentsFormValues,
  SpecificationsFormValues,
} from '~/entities/Inspection'
import {
  InspectionEquipmentApi,
  InspectionServiceApi,
  ThumbType,
  InspectionDefectApi,
  InspectionDocumentApi,
  InspectionWheelsStateAttributes,
} from '~/shared/api'
import {
  diskTypeEnumOptions,
  rubberTypeEnumOptions,
  wheelCodeEnumOptions,
} from '~/shared/config/constants'
import {
  DiskTypeEnum,
  DocumentsTypeEnum,
  InspectionServiceTypeEnum,
  RubberTypeEnum,
  WheelCodeEnum,
} from '~/shared/config/enums'
import { getValueByKey, sanitizeNumber } from '~/shared/lib/utils'

export const mapSpecificationFormValuesToDataApi = (
  formValues: SpecificationsFormValues,
) => {
  const {
    reasonOption,
    isExteriorClean,
    isCarRefueled,
    withDriverParticipation,
    isPlanned,
    incidentResponsible,
    mileage,
  } = formValues
  return {
    reasonId: reasonOption.id,
    isExteriorClean: Boolean(isExteriorClean),
    isCarRefueled: Boolean(isCarRefueled),
    withDriverParticipation: Boolean(withDriverParticipation),
    isPlanned: Boolean(isPlanned),
    mileage: sanitizeNumber(mileage),
    ...(incidentResponsible ? { incidentResponsible } : {}),
  }
}

export const mapPhotosToApi = (
  formValues: SpecificationsFormValues &
    CarCabinInspectionFormValues &
    CarUnderHoodInspectionFormValues,
  somePhotos: (ThumbType | undefined)[],
): string[] => {
  return [
    getValueByKey(formValues, 'carPhotos') || [],
    getValueByKey(formValues, 'mileagePhotos') || [],
    getValueByKey(formValues, 'tariffsPhotos') || [],
    getValueByKey(formValues, 'lastTOPhotos') || [],
    somePhotos,
  ]
    .flatMap((photos) => photos)
    .filter((photo): photo is ThumbType => Boolean(photo?.uuid))
    .map((photo) => photo.uuid)
}

export const mapDefectsToApi = (defects: Defect[]): InspectionDefectApi[] => {
  return defects
    .filter(({ id }) => !id)
    .map((defect) => ({
      detailId: defect.carDetail?.id as string,
      extentId: defect.carDamageExtent?.id as string,
      top: defect.top as number,
      left: defect.left as number,
      photos: defect.photos?.map((photo) => photo.uuid) || [],
    }))
}

export const mapEquipmentsToApi = (
  formValues: CarBodyInspectionFormValues &
    CarCabinInspectionFormValues &
    CarTrunkInspectionFormValues,
) => {
  const { equipmentsExterior, equipmentsInterior, equipmentsTrunk } = formValues

  return Object.entries({
    ...equipmentsExterior,
    ...equipmentsInterior,
    ...equipmentsTrunk,
  }).reduce(
    (data: InspectionEquipmentApi, [key, value]) => ({
      ...data,
      [key]: Boolean(value),
    }),
    {},
  )
}

export const mapWheelsToApi = (formValues: CarBodyInspectionFormValues) => {
  return Object.entries(formValues.wheels).map(([field, value]) => ({
    code: field as WheelCodeEnum,
    rubberType: value.rubberType.id,
    diskType: value.diskType.id,
  }))
}

export const mapCarCabinInspectionFormValuesToDataApi = (
  formValues: CarCabinInspectionFormValues,
) => {
  const { hasTobaccoSmell, isInteriorDirty, isDryCleaningNeed } = formValues
  return {
    hasTobaccoSmell: hasTobaccoSmell || false,
    isInteriorDirty: isInteriorDirty || false,
    isDryCleaningNeed: isDryCleaningNeed || false,
  }
}

export const mapServicesToApi = (
  formValues: CarUnderHoodInspectionFormValues,
) => {
  return Object.entries(formValues).reduce(
    (data: InspectionServiceApi[], [field]) => {
      if (
        field === InspectionServiceTypeEnum.MOTOR_OIL ||
        field === InspectionServiceTypeEnum.ANTIFREEZE
      ) {
        return [
          ...data,
          {
            type: field,
            data: [getValueByKey(formValues, field)],
          },
        ]
      }
      if (
        field === InspectionServiceTypeEnum.WIPERS ||
        field === InspectionServiceTypeEnum.LAMPS
      ) {
        const selectedValues = Object.entries(getValueByKey(formValues, field))
          .filter(([, value]) => value)
          .map(([key]) => key)

        return selectedValues?.length
          ? [
              ...data,
              {
                type: field,
                data: selectedValues,
              },
            ]
          : data
      }

      return data
    },
    [],
  )
}

export const mapDocumentsFormToDataApi = (formValues: DocumentsFormValues) => {
  const { comment, documents } = formValues
  return {
    comment,
    documents: Object.entries(documents).reduce(
      (data, [key, value]) => ({
        ...data,
        [key as DocumentsTypeEnum]: Boolean(value),
      }),
      {},
    ) as InspectionDocumentApi,
  }
}

export const getCarBodyInspectionDefaultValues = (
  lastWheels: InspectionWheelsStateAttributes[],
): Partial<CarBodyInspectionFormValues> => {
  const isLastWheels = lastWheels?.length

  const defaultRubberType = rubberTypeEnumOptions.find(
    ({ id }) => id === RubberTypeEnum.SUMMER,
  ) as never
  const defaultDiskType = diskTypeEnumOptions.find(
    ({ id }) => id === DiskTypeEnum.STAMPED,
  ) as never

  const defaultWheels = wheelCodeEnumOptions.reduce((values, wheelOption) => {
    const foundWheel = lastWheels.find(
      ({ wheelCode }) => wheelCode === wheelOption.id,
    )
    const rubberOption =
      rubberTypeEnumOptions.find(({ id }) => id === foundWheel?.rubberType) ||
      defaultRubberType

    const diskOption =
      diskTypeEnumOptions.find(({ id }) => id === foundWheel?.diskType) ||
      defaultDiskType

    return {
      ...values,
      [wheelOption.id]: {
        rubberType: rubberOption,
        diskType: diskOption,
      },
    }
  }, {}) as CarBodyInspectionFormValues['wheels']

  const lastRubberType = lastWheels?.map(({ rubberType }) => rubberType)
  const isDiscrepanciesRubber = Boolean(
    isLastWheels && uniq(lastRubberType)?.length > 1,
  )

  const lastDiskType = lastWheels?.map(({ diskType }) => diskType)
  const isDiscrepanciesDisk = Boolean(
    isLastWheels && uniq(lastDiskType)?.length > 1,
  )

  return {
    wheels: defaultWheels,
    isDiscrepanciesRubber: isDiscrepanciesRubber,
    isDiscrepanciesDisk: isDiscrepanciesDisk,
    ...(isDiscrepanciesRubber
      ? undefined
      : {
          rubberType: isLastWheels
            ? rubberTypeEnumOptions.find(
                ({ id }) => id === lastWheels[0].rubberType,
              )
            : defaultRubberType,
        }),
    ...(isDiscrepanciesDisk
      ? undefined
      : {
          diskType: isLastWheels
            ? diskTypeEnumOptions.find(
                ({ id }) => id === lastWheels[0].diskType,
              )
            : defaultDiskType,
        }),
  }
}
