import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import createAuthRefreshInterceptor from 'axios-auth-refresh'

import { AuthStore, UserOptionsStoreType } from './store'

export class Http {
  readonly baseUrl: string
  readonly authStore: AuthStore
  axiosInstance: AxiosInstance
  private _onUnauthorizeCallbacks: Array<() => void> = []
  readonly userOptionsStore: UserOptionsStoreType

  constructor(
    baseUrl = '/',
    authStore: AuthStore,
    userOptionsStore: UserOptionsStoreType,
  ) {
    this.baseUrl = baseUrl
    this.authStore = authStore
    this.userOptionsStore = userOptionsStore
    this.axiosInstance = this.initInstance()
  }

  request<T = unknown, R = AxiosResponse<T>>(
    config: AxiosRequestConfig,
  ): Promise<R> {
    return this.axiosInstance.request<T, R>(config)
  }

  get<T = unknown, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    return this.axiosInstance.get<T, R>(url, config)
  }

  post<T = unknown, R = AxiosResponse<T>>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    return this.axiosInstance.post<T, R>(url, data, config)
  }

  put<T = unknown, R = AxiosResponse<T>>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    return this.axiosInstance.put<T, R>(url, data, config)
  }

  patch<T = unknown, R = AxiosResponse<T>>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    return this.axiosInstance.patch<T, R>(url, data, config)
  }

  delete<T = unknown, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    return this.axiosInstance.delete<T, R>(url, config)
  }

  initInstance() {
    const instance = axios.create({
      baseURL: this.baseUrl,
      headers: {
        Accept: 'application/vnd.api+json',
        'Content-type': 'application/vnd.api+json',
      },
    })

    instance.interceptors.request.use(this.injectHeaders, (error) =>
      Promise.reject(error),
    )

    createAuthRefreshInterceptor(instance, this.unAuthorize)

    this.axiosInstance = instance
    return instance
  }

  private injectHeaders = (config: AxiosRequestConfig): AxiosRequestConfig => {
    try {
      // check if Authorization header can be added
      if (
        // has valid stored token
        this.authStore.isTokenValid &&
        // auth header is not explicitly set
        typeof config.headers?.Authorization === 'undefined'
      ) {
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${this.authStore.token}`,
        }
      }
      return config
    } catch (error) {
      if (error instanceof Error) {
        throw new Error(error.message)
      } else {
        throw error
      }
    }
  }

  private unAuthorize = async () => {
    this.triggerUnauthorize()
  }

  /*
   * Register a callback function that will be called on unauthorize.
   *
   * Returns a removal function that you could call to "unsubscribe" from the changes.
   * */
  onUnauthorize(callback: () => void): () => void {
    this._onUnauthorizeCallbacks.push(callback)

    return () => {
      for (let i = this._onUnauthorizeCallbacks.length - 1; i >= 0; i--) {
        if (this._onUnauthorizeCallbacks[i] == callback) {
          delete this._onUnauthorizeCallbacks[i] // removes the function reference
          this._onUnauthorizeCallbacks.splice(i, 1) // reindex the array
          return
        }
      }
    }
  }

  protected triggerUnauthorize(): void {
    this.authStore.clearToken()
    for (const callback of this._onUnauthorizeCallbacks) {
      callback?.()
    }
  }
}
