import AddIcon from '@mui/icons-material/Add'
import CheckIcon from '@mui/icons-material/Check'
import RemoveIcon from '@mui/icons-material/Remove'
import { Button, Typography } from '@mui/material'
import { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { Modal, useModal } from '~/shared/ui/Modal'
import { Amount, IconButton, InputWrapper } from './styled'

type ModalButtonRangePros = {
  title: string
  buttonTitle: string
  description: string
  step: number
  suffix?: string
  name: string
}

export function ModalButtonRange({
  title,
  buttonTitle,
  description,
  suffix,
  step,
  name,
}: ModalButtonRangePros) {
  const { isModalShow, openModal, closeModal } = useModal()
  const { setValue, getValues } = useFormContext()

  const getDefaultValueForm = () => getValues(name) || 0

  const [amount, setAmount] = useState<number>(getDefaultValueForm())
  const [isAmount, toggleIsAmount] = useState<boolean>(Boolean(amount))

  const minusAmount = () => {
    setAmount((prevAmount) => {
      const value = prevAmount - step
      return value < 0 ? 0 : value
    })
  }

  const plusAmount = () => {
    setAmount((prevAmount) => prevAmount + step)
  }

  const handleOk = () => {
    closeModal()
    setValue(name, amount)
    toggleIsAmount(Boolean(amount))
  }

  const handleCancel = () => {
    closeModal()
    setAmount(getDefaultValueForm())
  }

  return (
    <>
      <Button
        onClick={openModal}
        variant='whiteBorder'
        endIcon={isAmount && <CheckIcon sx={{ fontSize: '24px !important' }} />}
      >
        {buttonTitle}
      </Button>
      <Modal
        isShow={isModalShow}
        onCancel={handleCancel}
        onOk={handleOk}
        ok='Добавить'
      >
        <Typography fontSize={20} fontWeight={500} mb='4px'>
          {title}
        </Typography>
        <Typography fontSize={12} mb='16px' color='dimGray.main'>
          {description}
        </Typography>

        <InputWrapper>
          <IconButton disabled={!amount} onClick={minusAmount}>
            <RemoveIcon color='primaryLight' />
          </IconButton>

          <Amount>
            {amount}
            {suffix}
          </Amount>

          <IconButton onClick={plusAmount}>
            <AddIcon color='primaryLight' />
          </IconButton>
        </InputWrapper>
      </Modal>
    </>
  )
}
