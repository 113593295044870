import { Box, CircularProgress, styled } from '@mui/material'
import { myPalette } from '~/shared/ui/theme'

export const Button = styled(Box)`
  border: 1px solid ${myPalette.black.main};
  background: ${myPalette.white.main};
  border-radius: 6px;
  padding: 8px 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-size: 18px;
  cursor: pointer;
  font-weight: 500;
  position: relative;
`

export const Progress = styled(CircularProgress)`
  position: absolute;
  left: 16px;
`
