import { zodResolver } from '@hookform/resolvers/zod'
import { useStore, useStoreMap } from 'effector-react'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { getCarBodyInspectionDefaultValues } from '~/features/Inspection/mappers'
import {
  CarBodyInspectionFormValues,
  CarCabinInspectionFormValues,
  CarTrunkInspectionFormValues,
  CarUnderHoodInspectionFormValues,
  Defect,
  DocumentsFormValues,
  SpecificationsFormValues,
  specificationsFormSchema,
  documentsFormSchema,
  inspectionFormModel,
} from '~/entities/Inspection'
import { $lastInspection } from '~/entities/Inspection/model'

export const useInspectionForm = () => {
  const lastInspectionMileage = useStoreMap($lastInspection, (inspection) =>
    inspection?.getMileage(),
  )
  const unrepairedDamagesExterior = useStore(
    inspectionFormModel.$unrepairedDamagesExterior,
  )
  const unrepairedDamagesTire = useStore(
    inspectionFormModel.$unrepairedDamagesTire,
  )
  const unrepairedDamagesInterior = useStore(
    inspectionFormModel.$unrepairedDamagesInterior,
  )
  const unrepairedDamagesEnvironment = useStore(
    inspectionFormModel.$unrepairedDamagesEnvironment,
  )
  const lastWheels = useStore(inspectionFormModel.$lastWheels)

  const specificationsStepForm = useForm<SpecificationsFormValues>({
    defaultValues: {
      isExteriorClean: true,
      ...(lastInspectionMileage ? { mileage: lastInspectionMileage } : {}),
    },
    resolver: zodResolver(specificationsFormSchema),
  })

  const carBodyInspectionStepForm = useForm<CarBodyInspectionFormValues>({
    defaultValues: getCarBodyInspectionDefaultValues(lastWheels),
  })

  const carCabinInspectionStepForm = useForm<CarCabinInspectionFormValues>()

  const carTrunkInspectionStepForm = useForm<CarTrunkInspectionFormValues>()

  const carUnderHoodInspectionStepForm =
    useForm<CarUnderHoodInspectionFormValues>()

  const documentsStepForm = useForm<DocumentsFormValues>({
    resolver: zodResolver(documentsFormSchema),
  })

  const [defectsCarBody, setDefectsCarBody] = useState<Defect[]>([])
  const [defectsCarInterior, setDefectsCarInterior] = useState<Defect[]>([])
  const [defectsCarEnvironment, setDefectsCarEnvironment] = useState<Defect[]>(
    [],
  )

  useEffect(() => {
    setDefectsCarBody((prevState) => [
      ...prevState,
      ...unrepairedDamagesExterior,
    ])
  }, [unrepairedDamagesExterior])

  useEffect(() => {
    setDefectsCarBody((prevState) => [...prevState, ...unrepairedDamagesTire])
  }, [unrepairedDamagesTire])

  useEffect(() => {
    setDefectsCarInterior(unrepairedDamagesInterior)
  }, [unrepairedDamagesInterior])

  useEffect(() => {
    setDefectsCarEnvironment(unrepairedDamagesEnvironment)
  }, [unrepairedDamagesEnvironment])

  return {
    specificationsStepForm,
    carBodyInspectionStepForm,
    carCabinInspectionStepForm,
    carTrunkInspectionStepForm,
    carUnderHoodInspectionStepForm,
    documentsStepForm,
    defectsCarBody,
    setDefectsCarBody,
    defectsCarInterior,
    setDefectsCarInterior,
    defectsCarEnvironment,
    setDefectsCarEnvironment,
  }
}
