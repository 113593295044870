import { LoadingButton } from '@mui/lab'
import { Box, Unstable_Grid2 as Grid } from '@mui/material'
import React from 'react'
import { UseFormReturn } from 'react-hook-form'
import {
  CheckboxElement,
  FormContainer,
  TextFieldElement,
} from 'react-hook-form-mui'
import { documentsCodeEnumOptions } from '~/shared/config/constants'
import { useDefaultFieldProps } from '~/shared/ui/form'
import { myPalette } from '~/shared/ui/theme'
import { FormHeader } from '../FormHeader'
import { DocumentsFormValues } from './types'

type DocumentsFormProps = {
  onSuccess: () => void
  form: UseFormReturn<DocumentsFormValues>
  submitTitle: string
  isSubmitting: boolean
}

export function DocumentsForm({
  onSuccess,
  form,
  submitTitle,
  isSubmitting,
}: DocumentsFormProps) {
  const { textFieldProps, checkboxProps } = useDefaultFieldProps()

  return (
    <Box>
      <FormContainer formContext={form} onSuccess={onSuccess}>
        <Grid container rowSpacing={1}>
          {documentsCodeEnumOptions.map((param) => (
            <Grid xs={12} key={param.id} sx={{ ml: '8px' }}>
              <CheckboxElement
                label={param.label}
                name={`documents.${param.id}`}
                {...checkboxProps}
              />
            </Grid>
          ))}

          <Grid xs={12}>
            <FormHeader title='Комментарий' />
          </Grid>
          <Grid xs={12}>
            <TextFieldElement
              name='comment'
              multiline
              rows={6}
              sx={{
                background: myPalette.white.main,
              }}
              {...textFieldProps}
            />
          </Grid>

          <Grid xs={12} sx={{ mt: '16px' }}>
            <LoadingButton
              fullWidth
              type='submit'
              variant='limeFlooded'
              loading={isSubmitting}
            >
              {submitTitle}
            </LoadingButton>
          </Grid>
        </Grid>
      </FormContainer>
    </Box>
  )
}
