import {
  parse as fnsParse,
  parseJSON,
  format,
  isValid,
  isBefore,
  addMonths,
  formatDuration as fnsFormatDuration,
  intervalToDuration,
  Interval,
} from 'date-fns'

import { utcToZonedTime } from 'date-fns-tz'
import { datetime } from '~/shared/config'

export { addMonths, format as formatDate, format }

export const fromString = (input: string) =>
  fnsParse(input, datetime.apiDateFormat, new Date())

export const fromDatetimeString = (input: string) => parseJSON(input)

export const isValidDateString = (input: string) => isValid(fromString(input))

export const formatForApi = (date: Date) =>
  format(utcToZonedTime(date, 'UTC'), datetime.apiDateFormat)

export const formatDatetimeForUI = (input: string) =>
  format(fromDatetimeString(input), datetime.uiDateTimeFormat)

export const formatDateForUI = (input: string) =>
  format(fromDatetimeString(input), datetime.uiDateFormat)

export const formatTimeForUI = (input: string) =>
  format(fromDatetimeString(input), datetime.uiTimeFormat)

export const isBeforeNow = (date: Date | number) => isBefore(date, new Date())

export const formatDuration = (
  duration: Duration,
  options?: {
    format?: string[]
    zero?: boolean
    delimiter?: string
  },
) => fnsFormatDuration(duration, options)

export const formatIntervalAsDuration = (
  interval: Interval,
  options?: { format?: string[]; zero?: boolean; delimiter?: string },
) => {
  const duration = intervalToDuration(interval)
  return fnsFormatDuration(duration, options)
}

export const formatIntervalToNowAsDuration = (
  date: Date,
  options?: { format?: string[]; zero?: boolean; delimiter?: string },
) => {
  const now = new Date()
  return formatIntervalAsDuration({ start: date, end: now }, options)
}

export const getDuration = (duration: number) => {
  const hours = Math.floor(duration / 3600)
  const minutes = Math.floor((duration % 3600) / 60)
  const seconds = duration % 60

  const formattedHours = hours ? `${String(hours).padStart(2, '0')}:` : ''
  const formattedMinutes = String(minutes).padStart(2, '0')
  const formattedSeconds = String(seconds).padStart(2, '0')

  return `${formattedHours}${formattedMinutes}:${formattedSeconds}`
}
