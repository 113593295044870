import { zodResolver } from '@hookform/resolvers/zod'
import { LoadingButton } from '@mui/lab'
import { Stack } from '@mui/material'
import { useStore } from 'effector-react'
import React from 'react'
import {
  FormContainer,
  TextFieldElement,
  PasswordElement,
} from 'react-hook-form-mui'

import { schema, FormValues } from './lib'
import { formSubmitted, getUserMeFx, loginFx } from './model'

export function Form() {
  const isPendingLogin = useStore(loginFx.pending)
  const isPendingMe = useStore(getUserMeFx.pending)
  const commonFieldProps = {
    size: 'medium' as const,
    disabled: isPendingLogin || isPendingMe,
  }
  return (
    <FormContainer<FormValues>
      resolver={zodResolver(schema)}
      onSuccess={formSubmitted}
    >
      <Stack spacing={3}>
        <TextFieldElement
          variant='filled'
          label='E-mail'
          name='email'
          type='email'
          {...commonFieldProps}
        />
        <PasswordElement
          variant='filled'
          label='Пароль'
          name='password'
          type='password'
          {...commonFieldProps}
        />
        <LoadingButton
          type='submit'
          variant='limeFlooded'
          loading={isPendingLogin || isPendingMe}
        >
          Далее
        </LoadingButton>
      </Stack>
    </FormContainer>
  )
}
