import { Box, styled } from '@mui/material'

export const Wrapper = styled(Box)`
  position: relative;
  height: 100%;

  .slick-slider {
    height: 100%;

    .slick-list {
      height: 100%;
    }

    .slick-track {
      display: flex;
      height: 100%;

      .slick-slide {
        overflow: hidden;
        margin: 0 4px;

        & > div {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;

          img {
            object-fit: contain;
            height: 100%;
            width: 100%;
          }
        }
      }
    }

    .slick-dots {
      display: flex !important;
      justify-content: center;
      margin: 4px 0 0;
      padding: 6px;
      backdrop-filter: blur(15px);
      width: 100%;
      position: absolute;
      bottom: 16px;

      li {
        width: 30px;
        height: 30px;
        margin: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        overflow: hidden;

        &.slick-active {
          transform: scale(1.1);
          transition: 0.3s transform;
        }

        ::marker {
          display: none;
          content: '';
        }

        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
`
