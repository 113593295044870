import { Alert, AlertTitle, Button, Typography } from '@mui/material'
import { PropsWithChildren } from 'react'
import * as React from 'react'
import {
  ErrorBoundary as ReactErrorBoundary,
  ErrorBoundaryPropsWithComponent,
  FallbackProps,
} from 'react-error-boundary'

import { logger } from '~/shared/lib/logger'

type Props = PropsWithChildren<
  Omit<ErrorBoundaryPropsWithComponent, 'FallbackComponent'>
> & {
  FallbackComponent?: React.ComponentType<FallbackProps>
}

export function ErrorBoundary(props: Props) {
  const {
    children,
    onError = errorHandler,
    FallbackComponent = ErrorFallback,
    ...restProps
  } = props
  return (
    <ReactErrorBoundary
      FallbackComponent={FallbackComponent}
      onError={onError}
      {...restProps}
    >
      {children}
    </ReactErrorBoundary>
  )
}

function ErrorFallback({
  resetErrorBoundary = window.location.reload,
}: FallbackProps) {
  return (
    <Alert role='alert' severity='error' variant='standard'>
      <AlertTitle>На странице произошла ошибка</AlertTitle>
      <Typography mb={1}>
        Если после обновления страницы ошибка не проходит, обратитесь к
        администратору
      </Typography>
      <Button
        variant='contained'
        size='small'
        color='warning'
        onClick={resetErrorBoundary}
      >
        Обновить
      </Button>
    </Alert>
  )
}

function errorHandler(error: Error) {
  logger.error(error)
}
