import { Skeleton, Typography } from '@mui/material'
import { useStore, useStoreMap } from 'effector-react'
import { useMemo } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { inspectionModel } from '~/features/Inspection'
import {
  Header,
  SpecificationsForm,
  SpecificationsFormValues,
} from '~/entities/Inspection'
import { formatDateForUI } from '~/shared/lib/date'
import { goToNextStep } from '../stepModel'
import { CarData } from './styled'

type SpecificationsStepProps = {
  form: UseFormReturn<SpecificationsFormValues>
}

export function SpecificationsStep({ form }: SpecificationsStepProps) {
  const isCarLoading = useStore(inspectionModel.requestCarFx.pending)
  const latestRepairOrderMaintenance = useStoreMap(
    inspectionModel.$car,
    (car) => car?.getLatestRepairOrderMaintenance()?.getAttributes(),
  )
  const plateNumber = useStoreMap(inspectionModel.$car, (car) =>
    car?.getPlateNumber(),
  )

  const driverFullName = useStoreMap(inspectionModel.$car, (car) =>
    car?.getDriverFullName(),
  )

  const onSuccess = () => {
    goToNextStep()
  }

  const rentalContract = driverFullName ? (
    <Typography fontSize={16}>{driverFullName}</Typography>
  ) : (
    <Typography fontSize={16} color='error'>
      Отсутствует договор аренды с водителем
    </Typography>
  )

  const extraMileage = useMemo(() => {
    if (!latestRepairOrderMaintenance) return null

    return (
      <Typography fontSize={16}>
        Последнее ТО: {formatDateForUI(latestRepairOrderMaintenance.createdAt)};{' '}
        {latestRepairOrderMaintenance?.mileage.toLocaleString()} КМ
      </Typography>
    )
  }, [latestRepairOrderMaintenance])

  return (
    <>
      <Header title='Общие характеристики' />

      {isCarLoading ? (
        <Skeleton
          variant='rectangular'
          width='100%'
          height={100}
          sx={{ borderRadius: '16px', marginBottom: '30px' }}
        />
      ) : (
        <CarData>
          <Typography fontSize={18} fontWeight={600} mb='12px'>
            {plateNumber}
          </Typography>
          {rentalContract}
        </CarData>
      )}

      <SpecificationsForm
        onSuccess={onSuccess}
        form={form}
        extraMileage={extraMileage}
      />
    </>
  )
}
