export const helpersCarPhotosCamera = [
  'Вид спереди',
  'Вид слева',
  'Вид справа',
  'Вид сзади',
  'Под капотом',
  'Багажник',
  'Салон спереди',
  'Салон сзади',
  'Приборная панель',
]
