import { Box, Button, Typography, Unstable_Grid2 as Grid } from '@mui/material'
import { useStore } from 'effector-react'
import { ReactNode, useEffect, useMemo, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import {
  FormContainer,
  RadioButtonGroup,
  SwitchElement,
} from 'react-hook-form-mui'
import {
  $inspectionReasonOptions,
  $lastInspection,
  $savingPhotosCount,
  requestInspectionReasonOptionsFx,
  savePhoto,
} from '~/entities/Inspection/model'
import { InspectionFetchOption } from '~/shared/api'
import { incidentResponsibleEnumOptions } from '~/shared/config/constants'
import {
  IncidentResponsibleEnum,
  InspectionPhotoTypeEnum,
  InspectionReasonCodeEnum,
} from '~/shared/config/enums'
import { snackbarEnqueued } from '~/shared/lib/notifications'
import { sanitizeNumber } from '~/shared/lib/utils'
import {
  DrawerSelect,
  NumericFormatElement,
  useDefaultFieldProps,
  Camera,
} from '~/shared/ui/form'
import { FormHeader } from '../FormHeader'
import { helpersCarPhotosCamera } from './constants'
import { SpecificationsFormValues } from './types'

type SpecificationsFormProps = {
  onSuccess: () => void
  form: UseFormReturn<SpecificationsFormValues>
  extraMileage: ReactNode
}

export function SpecificationsForm({
  onSuccess,
  form,
  extraMileage,
}: SpecificationsFormProps) {
  const { textFieldProps } = useDefaultFieldProps()

  const reasonOptionsLoading = useStore(
    requestInspectionReasonOptionsFx.pending,
  )
  const reasonOptions = useStore($inspectionReasonOptions)

  const savingCarPhotoCount =
    useStore($savingPhotosCount)[InspectionPhotoTypeEnum.CAR]
  const isCarPhotoPending = savingCarPhotoCount > 0

  const savingMileagePhotoCount =
    useStore($savingPhotosCount)[InspectionPhotoTypeEnum.MILEAGE]
  const isMileagePhotoPending = savingMileagePhotoCount > 0

  const lastInspection = useStore($lastInspection)
  const lastInspectionMileage = useMemo(
    () => lastInspection?.getMileage() || 0,
    [lastInspection],
  )

  const [mileageError, setMileageError] = useState<string>()

  const { watch, setValue } = form

  const reasonOptionCode = watch('reasonOption')?.code

  const canIncidentResponsibleChoose =
    reasonOptionCode === InspectionReasonCodeEnum.AFTER_ACCIDENT

  const showWithDriverParticipationParams =
    reasonOptionCode !== InspectionReasonCodeEnum.INITIAL &&
    reasonOptionCode !== InspectionReasonCodeEnum.CONFISCATION

  const showIsPlannedParams =
    reasonOptionCode === InspectionReasonCodeEnum.PLAN ||
    reasonOptionCode === InspectionReasonCodeEnum.REFUND

  const handleMileageCheck = (mileage?: number) => {
    const sanitizeMileage = sanitizeNumber(mileage || 0)
    const lastMillage = lastInspectionMileage.toLocaleString()

    if (!sanitizeMileage) {
      setMileageError('Обязательное поле')
    } else if (!lastInspectionMileage) {
      setMileageError('')
    } else if (lastInspectionMileage > sanitizeMileage) {
      setMileageError(
        `Пробег не может быть меньше чем ${lastMillage} км в предыдущем осмотре!`,
      )
    } else if (lastInspectionMileage + 50000 < sanitizeMileage) {
      setMileageError(
        `Пробег не может быть больше чем на 50 тыс км чем пробег ${lastMillage} км с последнего осмотра!`,
      )
    } else {
      setMileageError('')
    }
  }

  useEffect(() => {
    // Для автомобилей без осмотров по дефолту INITIAL
    if (!lastInspection && reasonOptions?.length) {
      setValue(
        'reasonOption',
        reasonOptions.find(
          (option) => option.code === InspectionReasonCodeEnum.INITIAL,
        ) as InspectionFetchOption,
      )
    }
    // eslint-disable-next-line
  }, [reasonOptions])

  watch(async (data, { name, type }) => {
    if (type !== 'change') return

    if (name === 'reasonOption') {
      setValue(
        'incidentResponsible',
        data[name]?.code === InspectionReasonCodeEnum.AFTER_ACCIDENT
          ? IncidentResponsibleEnum.NOT_DRIVER
          : undefined,
      )
      setValue('withDriverParticipation', false)
      setValue('isPlanned', false)
    }
    if (name === 'mileage') {
      handleMileageCheck(data[name])
    }
  })

  const mappedParams = useMemo(() => {
    return [
      { name: 'isExteriorClean', label: 'Чистая машина' },
      { name: 'isCarRefueled', label: 'Авто заправлено' },
      showWithDriverParticipationParams
        ? {
            name: 'withDriverParticipation',
            label: 'Водитель участвует в осмотре',
          }
        : null,
      showIsPlannedParams
        ? { name: 'isPlanned', label: 'Осмотр по записи' }
        : null,
    ].filter(Boolean) as { name: string; label: string }[]
  }, [showIsPlannedParams, showWithDriverParticipationParams])

  const handleOnSuccess = () => {
    if (mileageError) {
      snackbarEnqueued({
        message: 'Для перехода на следующий шаг укажите пробег правильно!',
        variant: 'warning',
      })
      return
    }
    onSuccess()
  }

  return (
    <Box>
      <FormContainer formContext={form} onSuccess={handleOnSuccess}>
        <Grid container rowSpacing={1}>
          <Grid xs={12}>
            <NumericFormatElement
              label='Введите пробег'
              name='mileage'
              variant='filled'
              decimalScale={0}
              inputProps={{
                inputProps: {
                  inputMode: 'numeric',
                },
              }}
              {...textFieldProps}
            />
            {mileageError && (
              <Typography
                fontSize={12}
                mt='4px'
                lineHeight='12px'
                color='error'
              >
                {mileageError}
              </Typography>
            )}
          </Grid>
          <Grid>{extraMileage}</Grid>

          <Grid xs={12} sx={{ p: '14px 0' }}>
            <Camera
              buttonTitle='Фото пробега'
              name='mileagePhotos'
              saveFileFn={(file) =>
                savePhoto({
                  file,
                  type: InspectionPhotoTypeEnum.MILEAGE,
                  form,
                  fieldName: 'mileagePhotos',
                })
              }
              isLoading={isMileagePhotoPending}
            />
          </Grid>

          <Grid xs={12}>
            <DrawerSelect
              label='Причина осмотра'
              name='reasonOption'
              defaultOptions={reasonOptions}
              loading={reasonOptionsLoading}
              disabled={!lastInspection}
              title='Причина осмотра'
              dataTestId='inspection-reason-drawer'
            />
          </Grid>

          <Grid xs={12} sx={{ p: '14px 0' }}>
            <Camera
              buttonTitle='Добавить фото авто'
              name='carPhotos'
              helpers={helpersCarPhotosCamera}
              saveFileFn={(file) =>
                savePhoto({
                  file,
                  type: InspectionPhotoTypeEnum.CAR,
                  form,
                  fieldName: 'carPhotos',
                })
              }
              isLoading={isCarPhotoPending}
            />
            <Typography fontSize={12} m='4px 0 0' color='dimGray.main'>
              Добавьте минимум 4 фото (со всех сторон)
            </Typography>
          </Grid>

          {canIncidentResponsibleChoose && (
            <>
              <Grid xs={12}>
                <FormHeader title='Виновник происшествия' />
              </Grid>
              <Grid xs={12} sx={{ ml: '8px' }}>
                <RadioButtonGroup
                  name='incidentResponsible'
                  options={incidentResponsibleEnumOptions}
                />
              </Grid>
            </>
          )}

          {mappedParams.map((param) => (
            <Grid xs={12} key={param.name} sx={{ ml: '8px' }}>
              <SwitchElement label={param.label} name={param.name} />
            </Grid>
          ))}

          <Grid xs={12} sx={{ mt: '16px' }}>
            <Button fullWidth type='submit' variant='limeFlooded'>
              Далее
            </Button>
          </Grid>
        </Grid>
      </FormContainer>
    </Box>
  )
}
