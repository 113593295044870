import { LoadingButton } from '@mui/lab'
import { Box, Modal, Stack, Typography } from '@mui/material'
import { useState } from 'react'
import { useRegisterSW } from 'virtual:pwa-register/react'
import { replaceFx } from '~/shared/lib/history'

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  padding: '12px',
}

export function ReloadSw() {
  const [isLoading, toggleIsLoading] = useState<boolean>(false)

  const {
    needRefresh: [needRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    async onRegisteredSW(
      _: string,
      registration: ServiceWorkerRegistration | undefined,
    ) {
      if (!registration) return
      setInterval(async () => {
        await registration.update()
      }, 10000)
    },
  })

  const handleReload = async () => {
    toggleIsLoading(true)
    setTimeout(async () => {
      await updateServiceWorker(true).finally(() => {
        toggleIsLoading(false)
        replaceFx('/')
      })
    }, 2000)
  }

  return (
    <Modal open={needRefresh}>
      <Box sx={style}>
        <Stack spacing={2}>
          <Typography fontSize='24px' lineHeight='26px'>
            Доступна новая версия.
          </Typography>
          <Typography fontSize='16px' lineHeight='18px'>
            Обновите приложение для дальнейшего использования!
          </Typography>

          <LoadingButton
            variant='outlined'
            color='warning'
            fullWidth
            onClick={handleReload}
            loading={isLoading}
          >
            Обновить
          </LoadingButton>
        </Stack>
      </Box>
    </Modal>
  )
}
