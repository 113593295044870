import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { Typography } from '@mui/material'
import { useGate } from 'effector-react'
import { useEffect, useMemo } from 'react'
import { useStopwatch } from 'react-timer-hook'
import { setTotalSeconds, Gate } from './model'
import { Wrapper } from './styled'

type TimerProps = {
  errorAfterMinutes?: number
}

export function Timer({ errorAfterMinutes }: TimerProps) {
  useGate(Gate)
  const { seconds, minutes, hours, totalSeconds } = useStopwatch({
    autoStart: true,
  })

  const uiHours = hours ? `${hours}:` : ''
  const uiMinutes = minutes < 10 ? `0${minutes}` : minutes
  const uiSeconds = seconds < 10 ? `0${seconds}` : seconds

  const isError = useMemo(() => {
    if (!errorAfterMinutes) return false
    return hours > 0 || minutes > errorAfterMinutes
  }, [errorAfterMinutes, hours, minutes])

  useEffect(() => {
    setTotalSeconds(totalSeconds)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalSeconds])

  return (
    <Wrapper>
      <AccessTimeIcon
        color={isError ? 'red' : 'primaryLight'}
        sx={{ mr: '8px' }}
      />
      <Typography
        color={isError ? 'red.main' : 'primaryLight.main'}
        fontSize={22}
        fontWeight={600}
      >
        {uiHours}
        {uiMinutes}:{uiSeconds}
      </Typography>
    </Wrapper>
  )
}
