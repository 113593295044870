import { Camera as CapacitorCamera } from '@capacitor/camera'
import { PermissionStatus } from '@capacitor/camera/dist/esm/definitions'
import { SplashScreen } from '@capacitor/splash-screen'

export async function initMobileApp() {
  setTimeout(SplashScreen.hide, 1000)

  requestPermissions()
}

const requestPermissions = async () => {
  const permission: PermissionStatus = await CapacitorCamera.checkPermissions()

  if (
    permission.camera === 'denied' ||
    permission.camera === 'prompt' ||
    permission.camera === 'prompt-with-rationale'
  ) {
    CapacitorCamera.requestPermissions({ permissions: ['camera'] })
  }
}
