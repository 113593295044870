import { IonHeader, IonPage } from '@ionic/react'
import { Typography } from '@mui/material'
import { ReactNode, RefObject } from 'react'
import { Helmet } from 'react-helmet-async'
import { myPalette } from '../theme'
import { Header } from './Header'
import { IonToolbarHeader, IonContent } from './styled'

type Props = {
  children: ReactNode
  helmetTitle: string
  title?: string
  contentTitle?: string
  canBack?: boolean
  backTitle?: string
  backFn?: () => void
  canSearch?: boolean
  canLogout?: boolean
  extraBlock?: ReactNode
  contentRef?: RefObject<HTMLIonContentElement> | null
}

export function PageContainer({
  children,
  helmetTitle,
  title,
  contentTitle,
  canBack,
  backTitle,
  backFn,
  canSearch,
  canLogout,
  extraBlock,
  contentRef,
}: Props) {
  return (
    <IonPage
      style={{
        background: myPalette.aliceBlue.main,
      }}
    >
      <Helmet>
        <title>{helmetTitle}</title>
      </Helmet>
      <IonHeader>
        <IonToolbarHeader>
          <Header
            title={title}
            canBack={canBack}
            canSearch={canSearch}
            backFn={backFn}
            backTitle={backTitle}
            canLogout={canLogout}
            extraBlock={extraBlock}
          />
        </IonToolbarHeader>
      </IonHeader>

      <IonContent ref={contentRef} fullscreen>
        {contentTitle && (
          <Typography sx={{ paddingBottom: '24px' }} fontSize='24px'>
            {contentTitle}
          </Typography>
        )}

        {children}
      </IonContent>
    </IonPage>
  )
}
