import { createEffect, createEvent, sample } from 'effector'
import { createGate } from 'effector-react'
import { storageSet, storageGet } from '~/shared/lib/storage'

const TOTAL_SECONDS_STORAGE_KEY = 'inspection_total_seconds'

export const Gate = createGate()

export const setTotalSeconds = createEvent<number>()

const setTotalSecondsStorageFx = createEffect<number, void>({
  handler(seconds) {
    storageSet(TOTAL_SECONDS_STORAGE_KEY, seconds)
  },
})

export const getTotalSecondsStorageFx = createEffect<void, number>({
  handler() {
    return storageGet<number>(TOTAL_SECONDS_STORAGE_KEY) || 0
  },
})

sample({
  clock: Gate.close,
  fn: () => 0,
  target: setTotalSecondsStorageFx,
})

sample({
  clock: setTotalSeconds,
  target: setTotalSecondsStorageFx,
})
