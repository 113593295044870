import { Typography } from '@mui/material'
import React from 'react'

type FormHeaderProps = {
  title: string
}
export function FormHeader({ title }: FormHeaderProps) {
  return (
    <Typography fontSize='18px' fontWeight={500}>
      {title}
    </Typography>
  )
}
