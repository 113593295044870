import { storageGet, storageRemove, storageSet } from '~/shared/lib/storage'

import { BaseAuthStore } from './BaseAuthStore'

type FallbackToken = string | null
/**
 * The default auth store for browsers with auto fallback
 * to runtime/memory if local storage is undefined (eg. in node env).
 */
export class LocalAuthStore extends BaseAuthStore {
  private readonly storageKeyToken: string
  private readonly storageKeyCurrentUserId: string

  private fallbackToken: FallbackToken = null
  private fallbackCurrentUserId: FallbackToken = null

  constructor(storageKeyToken: string, storageKeyCurrentUserId: string) {
    super()

    this.storageKeyToken = storageKeyToken
    this.storageKeyCurrentUserId = storageKeyCurrentUserId
  }

  get token(): string {
    const storageByKey = storageGet<string>(this.storageKeyToken)
    return storageByKey || this.fallbackToken || ''
  }

  get currentUserId(): string {
    const storageByKey = storageGet<string>(this.storageKeyCurrentUserId)
    return storageByKey || this.fallbackCurrentUserId || ''
  }

  saveToken(token: string) {
    const { isMemoryUpdate } = storageSet<string>(this.storageKeyToken, token)

    if (isMemoryUpdate) {
      this.fallbackToken = token
    }

    super.saveToken(token)
  }

  saveCurrentUserId(currentUserId: string) {
    const { isMemoryUpdate } = storageSet<string>(
      this.storageKeyCurrentUserId,
      currentUserId,
    )

    if (isMemoryUpdate) {
      this.fallbackCurrentUserId = currentUserId
    }

    super.saveCurrentUserId(currentUserId)
  }

  clearToken() {
    storageRemove(this.storageKeyToken)
    this.fallbackToken = null
    super.clearToken()
  }

  clearCurrentUserId() {
    storageRemove(this.storageKeyCurrentUserId)
    this.fallbackCurrentUserId = null
    super.clearCurrentUserId()
  }
}
