import AddIcon from '@mui/icons-material/Add'
import CheckIcon from '@mui/icons-material/Check'
import { Typography } from '@mui/material'
import React, { ReactNode } from 'react'
import { Button, Progress } from './styled'

type ButtonFieldProps = {
  children?: ReactNode
  errorMessage?: string
  onClick?: () => void
  isLoading?: boolean
  isCheck?: boolean
}

export function ButtonField({
  children,
  errorMessage,
  onClick,
  isLoading,
  isCheck,
}: ButtonFieldProps) {
  return (
    <>
      <Button onClick={onClick}>
        {isLoading && <Progress size={16} color='inherit' />}
        {children}
        {isCheck ? (
          <CheckIcon color='success' sx={{ ml: '8px', mr: '8px' }} />
        ) : (
          <AddIcon sx={{ ml: '8px' }} />
        )}
      </Button>

      {errorMessage && (
        <Typography fontSize={12} m='4px 0 0' color='error'>
          {errorMessage}
        </Typography>
      )}
    </>
  )
}
