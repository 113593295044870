import { LoadingButton } from '@mui/lab'
import { Box, Skeleton, Unstable_Grid2 as Grid } from '@mui/material'
import { useStore } from 'effector-react'
import React from 'react'
import { UseFormReturn } from 'react-hook-form'
import { CheckboxElement, FormContainer } from 'react-hook-form-mui'
import { inspectionFormModel } from '~/entities/Inspection'
import { useDefaultFieldProps } from '~/shared/ui/form'
import { CarTrunkInspectionFormValues } from './types'

type CarTrunkInspectionFormProps = {
  onSuccess: () => void
  form: UseFormReturn<CarTrunkInspectionFormValues>
}

export function CarTrunkInspectionForm({
  onSuccess,
  form,
}: CarTrunkInspectionFormProps) {
  const { checkboxProps } = useDefaultFieldProps()

  const carEquipmentsTrunkOptions = useStore(
    inspectionFormModel.$carEquipmentsTrunkOptions,
  )
  const isCarEquipmentsPending = useStore(
    inspectionFormModel.requestCarEquipmentsFx.pending,
  )

  return (
    <Box>
      <FormContainer formContext={form} onSuccess={onSuccess}>
        <Grid container rowSpacing={1}>
          {isCarEquipmentsPending && (
            <Skeleton
              variant='rectangular'
              width='100%'
              height={150}
              sx={{ borderRadius: '10px' }}
            />
          )}
          {!isCarEquipmentsPending &&
            carEquipmentsTrunkOptions.map((param) => (
              <Grid xs={12} key={param.id} sx={{ ml: '8px' }}>
                <CheckboxElement
                  label={param.label}
                  name={`equipmentsTrunk.${param.id}`}
                  {...checkboxProps}
                />
              </Grid>
            ))}

          <Grid xs={12} sx={{ mt: '16px' }}>
            <LoadingButton fullWidth type='submit' variant='limeFlooded'>
              Осмотреть под капотом
            </LoadingButton>
          </Grid>
        </Grid>
      </FormContainer>
    </Box>
  )
}
