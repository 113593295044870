import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Typography } from '@mui/material'
import { CarAttributes } from '~/shared/api'
import { Wrapper, ContentWrapper, Button, Description } from './styled'

type CardProps = {
  onStart: () => void
  car: CarAttributes
}

export function Card({ onStart, car }: CardProps) {
  return (
    <Wrapper onClick={onStart}>
      <ContentWrapper>
        <Typography fontSize='24px'>{car.plateNumber}</Typography>
        <Description>
          <Typography fontSize='14px'>Прохождение ПО</Typography>
          <Typography fontSize='14px'>23:15</Typography>
        </Description>
      </ContentWrapper>

      <Button
        variant='limeFlooded'
        size='small'
        sx={{ textTransform: 'none' }}
        data-testid='start-inspection-button'
      >
        <ArrowForwardIcon />
        Начать
      </Button>
    </Wrapper>
  )
}
