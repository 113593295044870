import { Box } from '@mui/material'
import { ReactNode } from 'react'

type Props = {
  children?: ReactNode
}

export function MinimalLayout({ children }: Props) {
  return <Box>{children || null}</Box>
}
