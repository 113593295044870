import { Stack, Typography } from '@mui/material'
import { ReactNode } from 'react'
import { Logout } from '~/widgets/Logout'
import { ButtonBack } from '~/shared/ui/ButtonBack'
import { myPalette } from '~/shared/ui/theme'
import { Search } from '../Search'
import { Wrapper } from './styled'

type HeaderProps = {
  title?: string
  canSearch?: boolean
  canBack?: boolean
  backFn?: () => void
  backTitle?: string
  canLogout?: boolean
  extraBlock?: ReactNode
}

export function Header({
  canSearch,
  canBack,
  title,
  backFn,
  backTitle,
  canLogout,
  extraBlock = null,
}: HeaderProps) {
  return (
    <Wrapper>
      <Stack
        alignItems='center'
        direction='row'
        spacing={1}
        sx={{ width: '100%' }}
      >
        {canSearch && <Search />}

        {canBack && <ButtonBack backFn={backFn} title={backTitle} />}

        {title && (
          <Typography
            fontSize='18px'
            sx={{ color: myPalette.primaryLight.main, lineHeight: '18px' }}
          >
            {title}
          </Typography>
        )}
      </Stack>
      {canLogout && <Logout />}
      {extraBlock}
    </Wrapper>
  )
}
