import * as z from 'zod'

import {
  DiskTypeEnum,
  InspectionServiceTypeEnum,
  RubberTypeEnum,
  WheelCodeEnum,
} from '~/shared/config/enums'
import { dateSchema } from '~/shared/lib/schemas'
import { ApiModel } from './core'

const attributesSchema = z.object({
  type: z.nativeEnum(InspectionServiceTypeEnum),
  diskType: z.nativeEnum(DiskTypeEnum),
  rubberType: z.nativeEnum(RubberTypeEnum),
  wheelCode: z.nativeEnum(WheelCodeEnum),
})

const schema = z
  .object({
    createdAt: dateSchema,
    updatedAt: dateSchema,
  })
  .merge(attributesSchema)

export type InspectionWheelsStateAttributes = z.infer<typeof schema>

export class InspectionWheelsState extends ApiModel<
  typeof schema,
  InspectionWheelsStateAttributes
> {
  static jsonApiType = 'inspection-wheels-states'

  static schema = schema
}
