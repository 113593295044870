import { debounce } from 'lodash'
import { ReactNode, useState } from 'react'
import {
  TransformWrapper,
  TransformComponent,
  ReactZoomPanPinchRef,
} from 'react-zoom-pan-pinch'
import { Wrapper } from './styled'

type ZoomProps = {
  children: ReactNode
  onZoom: (scale: number) => void
}

export function Zoom({ children, onZoom }: ZoomProps) {
  const debouncedZoom = debounce(onZoom, 100)
  const [isPanningDisabled, togglePanningDisabled] = useState<boolean>(true)

  const handleOnZoomStart = ({ state: { scale } }: ReactZoomPanPinchRef) => {
    togglePanningDisabled(scale <= 1)

    debouncedZoom?.(scale >= 1 ? scale : 1)
  }
  return (
    <Wrapper>
      <TransformWrapper
        wheel={{
          disabled: isPanningDisabled,
        }}
        panning={{
          disabled: isPanningDisabled,
        }}
        doubleClick={{
          disabled: true,
        }}
        onZoom={handleOnZoomStart}
      >
        <TransformComponent>{children}</TransformComponent>
      </TransformWrapper>
    </Wrapper>
  )
}
