import React from 'react'
import ReactDOM from 'react-dom/client'

import { App } from '~/app'
import { isMobileApp } from '~/shared/config/constants'
import { initMobileApp } from '~/shared/lib/InitMobileApp'
import { initSentry } from '~/shared/lib/sentry'

initSentry()

if (isMobileApp) {
  initMobileApp()
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)
