import { Box, Skeleton, Unstable_Grid2 as Grid, Button } from '@mui/material'
import { useStore } from 'effector-react'
import React from 'react'
import { UseFormReturn } from 'react-hook-form'
import { CheckboxElement, FormContainer } from 'react-hook-form-mui'
import { inspectionFormModel } from '~/entities/Inspection'
import { useDefaultFieldProps } from '~/shared/ui/form'
import { FormHeader } from '../FormHeader'
import { CarBodyInspectionFormValues } from './types'
import { Wheels } from './Wheels'

type CarBodyInspectionFormProps = {
  onSuccess: () => void
  form: UseFormReturn<CarBodyInspectionFormValues>
}

export function CarBodyInspectionForm({
  onSuccess,
  form,
}: CarBodyInspectionFormProps) {
  const { checkboxProps } = useDefaultFieldProps()

  const carEquipmentsExteriorOptions = useStore(
    inspectionFormModel.$carEquipmentsExteriorOptions,
  )
  const isCarEquipmentsPending = useStore(
    inspectionFormModel.requestCarEquipmentsFx.pending,
  )

  return (
    <Box>
      <FormContainer formContext={form} onSuccess={onSuccess}>
        <Grid container rowSpacing={1}>
          <Grid xs={12}>
            <FormHeader title='Комплектность' />
          </Grid>
          {isCarEquipmentsPending && (
            <Skeleton
              variant='rectangular'
              width='100%'
              height={150}
              sx={{ borderRadius: '10px' }}
            />
          )}
          {!isCarEquipmentsPending &&
            carEquipmentsExteriorOptions.map((param) => (
              <Grid xs={12} key={param.id} sx={{ ml: '8px' }}>
                <CheckboxElement
                  label={param.label}
                  name={`equipmentsExterior.${param.id}`}
                  {...checkboxProps}
                />
              </Grid>
            ))}

          <Grid xs={12} sx={{ mt: '16px' }}>
            <FormHeader title='Тип резины и дисков' />
          </Grid>

          <Wheels form={form} />

          <Grid xs={12} sx={{ mt: '16px' }}>
            <Button fullWidth type='submit' variant='limeFlooded'>
              Осмотреть салон
            </Button>
          </Grid>
        </Grid>
      </FormContainer>
    </Box>
  )
}
