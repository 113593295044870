import { useMemo, useState } from 'react'
import { CarDamageExtendsCodeEnum } from '~/shared/config/enums'
import { snackbarEnqueued } from '~/shared/lib/notifications'
import { useDrawer } from '~/shared/ui/Drawer'
import { useModal } from '~/shared/ui/Modal'
import { PolygonMarker } from '~/shared/ui/Polygon/types'
import { Defect, DefectDrawerFormValues } from '../ui/DefectDrawer/types'

const getLevel = (carDamageExtent?: Defect['carDamageExtent']) => {
  switch (carDamageExtent?.code) {
    case CarDamageExtendsCodeEnum.LIGHT:
      return 'Л'
    case CarDamageExtendsCodeEnum.MEDIUM:
      return 'С'
    case CarDamageExtendsCodeEnum.HEAVY:
      return 'Т'
    default:
      return undefined
  }
}

const mapDefectsToMarkers = (defects: Defect[]): PolygonMarker[] => {
  return defects.map(
    ({ top, left, carDamageExtent, code, isRepairDamage, id }) => ({
      top,
      left,
      code,
      level: getLevel(carDamageExtent),
      isSuccess: isRepairDamage,
      isOld: Boolean(id),
    }),
  )
}

export const useDefect = (
  defects: Defect[],
  setDefects?: (defects: Defect[]) => void,
) => {
  const {
    isDrawerShow: isDefectDrawerShow,
    openDrawer: defectDrawerOpen,
    closeDrawer: defectDrawerClose,
  } = useDrawer()
  const {
    isModalShow: isDefectRepairModalShow,
    openModal,
    closeModal: defectRepairModalClose,
  } = useModal()

  const markers = useMemo(() => mapDefectsToMarkers(defects), [defects])

  const [newMarker, setNewMarker] = useState<PolygonMarker>()

  const [selectedDefect, setSelectedDefect] = useState<Defect>()

  const handleMarkerSelect = (marker: PolygonMarker) => {
    const foundDefect = defects.find(
      (defect) => defect.top === marker.top && defect.left === marker.left,
    )
    if (!foundDefect) {
      snackbarEnqueued({ message: 'Дефект не найден', variant: 'danger' })
      return
    }
    setSelectedDefect(foundDefect)
    foundDefect?.id ? openModal() : defectDrawerOpen()
  }

  const handleMarkerAdd = (marker: PolygonMarker) => {
    defectDrawerOpen()
    setNewMarker(marker)
  }

  const handleDefectChange = (formValues: DefectDrawerFormValues) => {
    if (!selectedDefect) {
      snackbarEnqueued({ message: 'Дефект не найден', variant: 'danger' })
      return
    }
    const mappedDefects = defects.map((defect) =>
      defect.top === selectedDefect.top && defect.left === selectedDefect.left
        ? { ...defect, ...formValues }
        : defect,
    )
    setDefects?.(mappedDefects)
  }

  const handleDefectAdd = (formValues: DefectDrawerFormValues) => {
    if (!newMarker) {
      snackbarEnqueued({ message: 'Маркер не найден', variant: 'danger' })
      return
    }

    setDefects?.([...defects, { ...formValues, ...newMarker }])
  }

  const handleDrawerSuccess = (formValues: DefectDrawerFormValues) => {
    const fn = selectedDefect ? handleDefectChange : handleDefectAdd

    fn(formValues)
  }

  const handleSelectedDefectReset = () => setSelectedDefect(undefined)

  const handleDefectDrawerClose = () => {
    setNewMarker(undefined)
    handleSelectedDefectReset()
    defectDrawerClose()
  }

  const handleMarkerDelete = () => {
    setDefects?.(
      defects.filter(
        (defect) =>
          defect.top !== selectedDefect?.top &&
          defect.left !== selectedDefect?.left,
      ),
    )
    handleSelectedDefectReset()
    defectDrawerClose()
  }

  const handleDefectModalClose = () => {
    setNewMarker(undefined)
    handleSelectedDefectReset()
    defectRepairModalClose()
  }

  const handleDefectRepair = () => {
    const mappedDefect = defects.map((defect) =>
      defect.id === selectedDefect?.id
        ? { ...defect, isRepairDamage: true }
        : defect,
    )
    setDefects?.(mappedDefect)
    handleSelectedDefectReset()
    defectRepairModalClose()
  }

  const handleDefectRepairCancel = () => {
    const mappedDefect = defects.map((defect) =>
      defect.id === selectedDefect?.id
        ? { ...defect, isRepairDamage: false }
        : defect,
    )
    setDefects?.(mappedDefect)
    handleSelectedDefectReset()
    defectRepairModalClose()
  }

  return {
    handleMarkerSelect,
    handleMarkerAdd,
    handleDefectDrawerClose,
    handleDrawerSuccess,
    isDefectDrawerShow,
    selectedDefect,
    markers,
    defects,
    isDefectRepairModalShow,
    handleDefectModalClose,
    handleDefectRepair,
    handleDefectRepairCancel,
    newMarker,
    handleMarkerDelete,
  }
}
