import { Components } from '@mui/material/styles/components'
import { Theme } from '@mui/material/styles/createTheme'
import { myPalette } from '~/shared/ui/theme/myPalette'

export const MuiButton: Components<Omit<Theme, 'components'>> = {
  MuiButton: {
    defaultProps: {
      disableElevation: true,
    },
    variants: [
      {
        props: { variant: 'limeFlooded' },
        style: {
          backgroundColor: `${myPalette.limeGreen.main} !important`,
          borderRadius: '4px',
          padding: '13px 24px',
          color: myPalette.black.main,
          fontSize: '15px',
          fontWeight: 500,

          '&:disabled': {
            backgroundColor: `${myPalette.dark.jetBlack} !important`,
          },

          '&.MuiButton-sizeSmall': {
            padding: '6px 16px !important',
          },
        },
      },
      {
        props: { variant: 'whiteBorder' },
        style: {
          backgroundColor: 'transparent !important',
          padding: '12px 20px',
          color: myPalette.black.main,
          border: `1px solid ${myPalette.black.main}`,
          borderRadius: '6px',
          fontSize: '15px',
          fontWeight: 500,

          '&.MuiButton-sizeSmall': {
            padding: '6px 16px !important',
          },
        },
      },
      {
        props: { variant: 'warningBorder' },
        style: {
          backgroundColor: 'transparent !important',
          padding: '12px 20px',
          color: myPalette.orange.main,
          border: `1px solid ${myPalette.orange.main}`,
          borderRadius: '6px',
          fontSize: '15px',
          fontWeight: 500,

          '&.MuiButton-sizeSmall': {
            padding: '6px 16px !important',
          },
        },
      },
    ],
  },
}
