import { Typography } from '@mui/material'
import { Signature, Header } from '~/entities/Inspection'
import { ThumbType } from '~/shared/api'

type SignatureStepProps = {
  isSubmitting: boolean
  onSuccess: (sign: ThumbType) => void
}

export function SignatureStep({ isSubmitting, onSuccess }: SignatureStepProps) {
  return (
    <>
      <Header title='Подпись водителя' />
      <Typography fontSize={16} color='dimGray.main' mb='24px'>
        Я ознакомлен с результатами осмотра и подтверждаю выявленные повреждения
      </Typography>

      <Signature onSuccess={onSuccess} isSubmitting={isSubmitting} />
    </>
  )
}
