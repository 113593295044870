import { LoadingButton } from '@mui/lab'
import { Box, Stack, Typography } from '@mui/material'
import { Link as MuiLink } from '@mui/material'
import { useStore } from 'effector-react'
import { Link } from 'react-router-dom'
import { AuthForms } from '~/widgets/Auth'
import { getUserMeFx, loginFx } from '~/features/auth/login/model'
import { viewerModel } from '~/entities/Viewer'

function LoginPage() {
  const authenticated = viewerModel.useAuthenticated()
  const isLoginPending = useStore(loginFx.pending)
  const isLogoutPending = useStore(viewerModel.logoutFx.pending)
  const isMePending = useStore(getUserMeFx.pending)

  return (
    <Box>
      {authenticated && !isLoginPending && !isMePending ? (
        <Stack spacing={2} sx={{ textAlign: 'center' }}>
          <Typography variant='body1'>Вы уже авторизованы</Typography>
          <MuiLink
            component={Link}
            to='/'
            style={{ marginTop: '35px', marginBottom: '20px' }}
          >
            На главную
          </MuiLink>
          <LoadingButton
            loading={isLogoutPending}
            variant='contained'
            size='large'
            onClick={() => viewerModel.signOut()}
          >
            Выйти
          </LoadingButton>
        </Stack>
      ) : (
        <AuthForms />
      )}
    </Box>
  )
}

export default LoginPage
