import { useStore } from 'effector-react'
import { useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import {
  CarTrunkInspectionForm,
  CarTrunkInspectionFormValues,
  getEquipmentShortage,
  Header,
  inspectionFormModel,
  NotCompleteSetModal,
} from '~/entities/Inspection'
import { useModal } from '~/shared/ui/Modal'
import { goToNextStep } from '../stepModel'

type CarTrunkInspectionStepProps = {
  form: UseFormReturn<CarTrunkInspectionFormValues>
}
export function CarTrunkInspectionStep({ form }: CarTrunkInspectionStepProps) {
  const { isModalShow, openModal, closeModal } = useModal()

  const lastEquipments = useStore(inspectionFormModel.$lastEquipmentsTrunk)

  const [unselectedEquipmentsTitles, setUnselectedEquipmentsTitles] = useState<
    string[]
  >([])

  const onSuccess = () => {
    const foundUnselectedEquipments = getEquipmentShortage(
      lastEquipments,
      form.getValues('equipmentsTrunk'),
    )

    if (foundUnselectedEquipments?.length) {
      setUnselectedEquipmentsTitles(
        foundUnselectedEquipments.map(({ title }) => title),
      )
      openModal()
      return
    }

    goToNextStep()
  }

  const handleModalOk = () => {
    closeModal()
    goToNextStep()
  }

  return (
    <>
      <Header title='Осмотр багажника' />

      <CarTrunkInspectionForm onSuccess={onSuccess} form={form} />

      <NotCompleteSetModal
        isShow={isModalShow}
        onCancel={closeModal}
        onOk={handleModalOk}
        values={unselectedEquipmentsTitles}
      />
    </>
  )
}
