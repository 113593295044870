import { IonFooter } from '@ionic/react'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { IconButton } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import ReactPainter from 'react-painter'
import { myPalette } from '~/shared/ui/theme'
import { IonToolbar, ToolbarWrapper } from '../styled'
import { CanvasWrapper, Wrapper } from './styled'

type PainterModalProps = {
  imageFile: File
  onOk: (blob: File) => void
  onDelete: () => void
  canPaint?: boolean
}

export function Painter({
  imageFile,
  onDelete,
  onOk,
  canPaint,
}: PainterModalProps) {
  const [widthEnvironment, setWidthEnvironment] = useState<number>()
  const canvasRef = useRef<ReactPainter>(null)

  const handleOk = async () => {
    const canvas = canvasRef?.current?.canvasRef
    const dataUrl = canvas?.toDataURL('image/jpeg', 1)
    const blob = await fetch(dataUrl as string).then((res) => res.blob())
    const file = new File([blob as Blob], 'Photo', {
      type: 'image/jpeg',
    })
    onOk(file)
  }

  useEffect(() => {
    const element = document.getElementById('painter-block')
    setWidthEnvironment(element?.offsetWidth || 300)
  }, [imageFile])

  if (!imageFile) return null

  return (
    <>
      <Wrapper id='painter-block'>
        {widthEnvironment && (
          <ReactPainter
            ref={canvasRef}
            initialColor={myPalette.red.main}
            image={imageFile}
            width={widthEnvironment}
            render={({ canvas }) => (
              <CanvasWrapper
                sx={{
                  ...(canPaint ? {} : { pointerEvents: 'none' }),
                }}
              >
                {canvas}
              </CanvasWrapper>
            )}
          />
        )}
      </Wrapper>
      <IonFooter>
        <IonToolbar>
          <ToolbarWrapper>
            <IconButton
              onClick={onDelete}
              sx={{ backgroundColor: myPalette.aliceBlue.main }}
              size='large'
            >
              <DeleteOutlineIcon color='red' fontSize='large' />
            </IconButton>

            <IconButton
              onClick={handleOk}
              sx={{ backgroundColor: myPalette.aliceBlue.main }}
              size='large'
            >
              <CheckCircleOutlineIcon color='success' fontSize='large' />
            </IconButton>
          </ToolbarWrapper>
        </IonToolbar>
      </IonFooter>
    </>
  )
}
