import { Button } from '@mui/material'
import { Box } from '@mui/system'
import React, { forwardRef } from 'react'
import { FieldValues } from 'react-hook-form/dist/types/fields'

export const ListboxComponent = forwardRef<
  HTMLUListElement,
  React.HTMLAttributes<HTMLElement> & {
    options: FieldValues
    onClick: () => void
  }
>((props, ref) => {
  const { options, onClick, ...prop } = props
  const children = (prop.children || []) as React.ReactNode[]

  return (
    <ul ref={ref} {...prop}>
      <Box>{children.filter(Boolean).map((child) => child)}</Box>
      {Boolean(options?.length) && (
        <>
          <Box sx={{ height: '22px' }} />
          <Button
            sx={{ width: '100%', position: 'absolute', bottom: 0 }}
            variant='contained'
            size='small'
            onClick={onClick}
            color='primary'
          >
            Выбрать все
          </Button>
        </>
      )}
    </ul>
  )
})
ListboxComponent.displayName = 'ListboxComponent'
