import { Camera as CapacitorCamera } from '@capacitor/camera'
import { CameraPreview } from '@capacitor-community/camera-preview'
import { CameraPreviewOptions } from '@capacitor-community/camera-preview/dist/esm/definitions'
import { IonFooter, IonHeader, IonPage } from '@ionic/react'
import CameraswitchIcon from '@mui/icons-material/Cameraswitch'
import CloseIcon from '@mui/icons-material/Close'
import { Box } from '@mui/material'
import { ReactNode, useCallback, useMemo, useState } from 'react'
import { Prompt } from 'react-router-dom'
import { isMobileApp } from '~/shared/config/constants'
import { BODY_MAX_WIDTH } from '~/shared/ui/theme'
import { CameraContext } from './context'
import { Painter } from './Painter'
import {
  IonToolbar,
  CameraAltButton,
  ToolbarWrapper,
  ErrorMessage,
  HelperText,
} from './styled'

type CameraProviderProps = {
  children: ReactNode
}

export function CameraProvider({ children }: CameraProviderProps) {
  const [isPermissionError, togglePermissionError] = useState(false)
  const [cameraPosition, setCameraPosition] = useState<'rear' | 'front'>('rear')

  const [isCameraOpen, toggleIsCameraOpen] = useState(false)
  const [imageFile, setImageFile] = useState<File>()
  const [helperIndex, setHelperIndex] = useState<number>(0)
  const [cameraParams, setCameraParams] = useState<{
    fn: (file: File) => void
    canPaint?: boolean
    helpers?: string[]
  }>()

  const cameraOptions: CameraPreviewOptions = useMemo(
    () => ({
      parent: 'cameraPreview',
      position: cameraPosition,
      toBack: true,
      disableAudio: true,
      enableHighResolution: true,
      enableOpacity: true,
      ...(isMobileApp
        ? {
            enableZoom: true,
            lockAndroidOrientation: true,
            rotateWhenOrientationChanged: false,
          }
        : {
            height: 1920,
          }),
    }),
    [cameraPosition],
  )

  const handleCameraStart = useCallback(async () => {
    const permission = await CapacitorCamera.checkPermissions()

    if (permission.camera === 'denied') {
      togglePermissionError(true)
      return
    }

    await CameraPreview.start(cameraOptions)
  }, [cameraOptions])

  const handleCameraOpen = useCallback(
    async (
      fn: (file: File) => void,
      canPaint?: boolean,
      helpers?: string[],
    ) => {
      setCameraParams({ fn, canPaint, helpers })
      toggleIsCameraOpen(true)
      handleCameraStart()
    },
    [handleCameraStart],
  )

  const handleCameraClose = useCallback(() => {
    toggleIsCameraOpen(false)
    setCameraParams(undefined)
    setHelperIndex(0)
    setImageFile(undefined)
    CameraPreview.stop()
  }, [])

  const handleFileDelete = () => {
    setHelperIndex((prevIndex) => prevIndex - 1)
    setImageFile(undefined)
  }

  const handleImageSave = async (file: File) => {
    setImageFile(undefined)
    cameraParams?.fn?.(file)
  }

  const handleCameraFlip = async () => {
    if (isMobileApp) {
      await CameraPreview.flip()
    } else {
      const position = cameraPosition === 'rear' ? 'front' : 'rear'
      setCameraPosition(position)

      await CameraPreview.stop()
      await CameraPreview.start({ ...cameraOptions, position })
    }
  }

  const handleCameraCapture = async () => {
    const res = await CameraPreview.capture({
      quality: 100,
    })
    const fileString = `data:image/png;base64,${res.value}`

    const response = await fetch(fileString as string).then((res) => res.blob())
    const resFile = new File([response as Blob], 'Photo', {
      type: 'image/png',
    })
    setImageFile(resFile)
    setHelperIndex((prevIndex) => prevIndex + 1)
  }

  const helperText = useMemo(() => {
    const helper = cameraParams?.helpers?.[helperIndex]

    return helper ? helper : ''
  }, [cameraParams?.helpers, helperIndex])

  return (
    <>
      {isCameraOpen && (
        <IonPage
          style={{ margin: '0 auto', maxWidth: BODY_MAX_WIDTH, width: '100%' }}
        >
          <Prompt
            when={isCameraOpen}
            message={() => {
              handleCameraClose()
              return false
            }}
          />
          <IonHeader>
            <IonToolbar>
              <CloseIcon
                sx={{ fontSize: '40px' }}
                onClick={handleCameraClose}
                color='aliceBlue'
              />
            </IonToolbar>
          </IonHeader>

          {imageFile && (
            <Painter
              canPaint={cameraParams?.canPaint}
              imageFile={imageFile}
              onDelete={handleFileDelete}
              onOk={handleImageSave}
            />
          )}

          <Box
            id='cameraPreview'
            sx={{
              position: 'absolute',
              width: '100%',
              height: '100%',

              ...(!isMobileApp && {
                maxWidth: BODY_MAX_WIDTH,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                video: {
                  width: '100%',
                  maxWidth: window.screen.width,
                },
              }),
            }}
          />

          {isPermissionError && (
            <ErrorMessage>
              Разрешите доступ к камере и перезапустите приложение для того
              чтобы иметь возможность делать фотографии и прикреплять их к
              осмотру автомобиля.
            </ErrorMessage>
          )}

          {!isPermissionError && !imageFile && helperText && (
            <HelperText>{helperText}</HelperText>
          )}

          {!imageFile && !isPermissionError && (
            <IonFooter>
              <IonToolbar>
                <ToolbarWrapper>
                  <CameraswitchIcon
                    onClick={handleCameraFlip}
                    color='aliceBlue'
                    sx={{ fontSize: '40px' }}
                  />
                  <CameraAltButton
                    onClick={handleCameraCapture}
                    color='aliceBlue'
                  />
                </ToolbarWrapper>
              </IonToolbar>
            </IonFooter>
          )}
        </IonPage>
      )}

      <CameraContext.Provider
        value={{
          isCameraOpen,
          cameraOpen: handleCameraOpen,
          cameraClose: handleCameraClose,
        }}
      >
        <div hidden={isCameraOpen}>{children}</div>
      </CameraContext.Provider>
    </>
  )
}
