import { Box, styled } from '@mui/material'
import { HEADER_HEIGHT } from '~/shared/ui/theme'

export const Wrapper = styled(Box)`
  height: ${HEADER_HEIGHT};
  padding: 0 16px;
  width: 100%;
  display: flex;
  align-items: center;
`
