import { IonToolbar as IonicIonToolbar } from '@ionic/react'
import CameraAltIcon from '@mui/icons-material/CameraAlt'
import { Box, styled, Typography } from '@mui/material'
import { myPalette } from '~/shared/ui/theme'

export const CameraAltButton = styled(CameraAltIcon)`
  font-size: 90px;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
`

export const HelperText = styled(Box)`
  font-size: 34px;
  line-height: 40px;
  font-weight: 900;
  color: ${myPalette.white.main};
  text-align: center;
  opacity: 0.5;
  position: absolute;
  z-index: 3;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
`

export const ErrorMessage = styled(Typography)`
  color: ${myPalette.black.main};
  text-align: center;
  margin: 40px;
  position: absolute;
  display: flex;
  align-items: center;
  height: 100%;
`

export const IonToolbar = styled(IonicIonToolbar)`
  --background: none;
  --border-width: 0 !important;
  --padding-end: 22px;
  --padding-start: 22px;
  --padding-bottom: 12px;
  --padding-top: 12px;
  --box-shadow: none;
  backdrop-filter: blur(15px);
`

export const ToolbarWrapper = styled(Box)`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
`
