export enum InspectionStepsEnum {
  SPECIFICATION_STEP = 'SPECIFICATION_STEP',
  CAR_BODY_INSPECTION_STEP = 'CAR_BODY_INSPECTION_STEP',
  CAR_CABIN_INSPECTION_STEP = 'CAR_CABIN_INSPECTION_STEP',
  CAR_TRUNK_INSPECTION_STEP = 'CAR_TRUNK_INSPECTION_STEP',
  CAR_UNDER_HOOD_INSPECTION_STEP = 'CAR_UNDER_HOOD_INSPECTION_STEP',
  DOCUMENTS_STEP = 'DOCUMENTS_STEP',
  SIGNATURE_STEP = 'SIGNATURE_STEP',
}

export enum InspectionReasonCodeEnum {
  PLAN = 'PLAN',
  INITIAL = 'INITIAL',
  REFUND = 'REFUND',
  CONFISCATION = 'CONFISCATION',
  AFTER_ACCIDENT = 'AFTER_ACCIDENT',
  RETURN_FROM_REPAIR = 'RETURN_FROM_REPAIR',
  RENTAL = 'RENTAL',
}

export enum CarEquipmentTypeEnum {
  EXTERIOR = 'EXTERIOR',
  INTERIOR = 'INTERIOR',
  TRUNK = 'TRUNK',
}

export enum DocumentsTypeEnum {
  STS = 'STS',
  OSAGO = 'OSAGO',
  DC = 'DC',
  LICENSE = 'LICENSE',
}

export enum CarDamageExtendsCodeEnum {
  LIGHT = 'LIGHT',
  MEDIUM = 'MEDIUM',
  HEAVY = 'HEAVY',
}

export enum CarDetailTypeEnum {
  EXTERIOR = 'EXTERIOR',
  INTERIOR = 'INTERIOR',
  TIRE = 'TIRE',
  ENVIRONMENT = 'ENVIRONMENT',
}

export enum CarDetailExteriorCodeEnum {
  FRONT_LEFT_DOOR = 'FRONT_LEFT_DOOR',
  FRONT_RIGHT_DOOR = 'FRONT_RIGHT_DOOR',
  REAR_LEFT_DOOR = 'REAR_LEFT_DOOR',
  REAR_RIGHT_DOOR = 'REAR_RIGHT_DOOR',
  LEFT_THRESHOLD = 'LEFT_THRESHOLD',
  RIGHT_THRESHOLD = 'RIGHT_THRESHOLD',
  HOOD = 'HOOD',
  TRUNK = 'TRUNK',
  ROOF = 'ROOF',
  REAR_GLASS = 'REAR_GLASS',
  FRONT_GLASS = 'FRONT_GLASS',
  FRONT_RIGHT_WING = 'FRONT_RIGHT_WING',
  FRONT_LEFT_WING = 'FRONT_LEFT_WING',
  REAR_RIGHT_WING = 'REAR_RIGHT_WING',
  REAR_LEFT_WING = 'REAR_LEFT_WING',
  FRONT_RIGHT_GLASS = 'FRONT_RIGHT_GLASS',
  FRONT_LEFT_GLASS = 'FRONT_LEFT_GLASS',
  REAR_RIGHT_GLASS = 'REAR_RIGHT_GLASS',
  REAR_LEFT_GLASS = 'REAR_LEFT_GLASS',
  FRONT_BUMPER = 'FRONT_BUMPER',
  FRONT_RIGHT_BUMPER = 'FRONT_RIGHT_BUMPER',
  FRONT_LEFT_BUMPER = 'FRONT_LEFT_BUMPER',
  REAR_BUMPER = 'REAR_BUMPER',
  REAR_RIGHT_BUMPER = 'REAR_RIGHT_BUMPER',
  REAR_LEFT_BUMPER = 'REAR_LEFT_BUMPER',
  RIGHT_HEADLIGHT = 'RIGHT_HEADLIGHT',
  LEFT_HEADLIGHT = 'LEFT_HEADLIGHT',
  RIGHT_TAILLIGHT = 'RIGHT_TAILLIGHT',
  LEFT_TAILLIGHT = 'LEFT_TAILLIGHT',
  RIGHT_MIRROR = 'RIGHT_MIRROR',
  LEFT_MIRROR = 'LEFT_MIRROR',
  FRONT_RIGHT_FOG_LIGHT = 'FRONT_RIGHT_FOG_LIGHT',
  FRONT_LEFT_FOG_LIGHT = 'FRONT_LEFT_FOG_LIGHT',
  REAR_RIGHT_FOG_LIGHT = 'REAR_RIGHT_FOG_LIGHT',
  REAR_LEFT_FOG_LIGHT = 'REAR_LEFT_FOG_LIGHT',
  FRONT_RIGHT_DRIVE = 'FRONT_RIGHT_DRIVE',
  FRONT_LEFT_DRIVE = 'FRONT_LEFT_DRIVE',
  REAR_RIGHT_DRIVE = 'REAR_RIGHT_DRIVE',
  REAR_LEFT_DRIVE = 'REAR_LEFT_DRIVE',
  FRONT_LEFT_PILLAR = 'FRONT_LEFT_PILLAR',
  FRONT_RIGHT_PILLAR = 'FRONT_RIGHT_PILLAR',
  REAR_LEFT_PILLAR = 'REAR_LEFT_PILLAR',
  REAR_RIGHT_PILLAR = 'REAR_RIGHT_PILLAR',
  LEFT_PILLAR = 'LEFT_PILLAR',
  RIGHT_PILLAR = 'RIGHT_PILLAR',
  REAR_LEFT_QUARTER_GLASS = 'REAR_LEFT_QUARTER_GLASS',
  REAR_RIGHT_QUARTER_GLASS = 'REAR_RIGHT_QUARTER_GLASS',
  RADIATOR_GRILLE = 'RADIATOR_GRILLE',
}

export enum CarDetailTireCodeEnum {
  FRONT_RIGHT_RUBBER = 'FRONT_RIGHT_RUBBER',
  FRONT_LEFT_RUBBER = 'FRONT_LEFT_RUBBER',
  REAR_RIGHT_RUBBER = 'REAR_RIGHT_RUBBER',
  REAR_LEFT_RUBBER = 'REAR_LEFT_RUBBER',
}

export enum CarDetailInteriorCodeEnum {
  DRIVER_SEAT = 'DRIVER_SEAT',
  PASSENGER_SEAT = 'PASSENGER_SEAT',
  BACK_SEAT = 'BACK_SEAT',
  DASHBOARD = 'DASHBOARD',
  ARMREST = 'ARMREST',
  FRONT_INTERIOR_CARPET = 'FRONT_INTERIOR_CARPET',
  BACK_INTERIOR_CARPET = 'BACK_INTERIOR_CARPET',
  LEFT_DEFLECTORS = 'LEFT_DEFLECTORS',
  RIGHT_DEFLECTORS = 'RIGHT_DEFLECTORS',
  CENTRAL_DEFLECTOR = 'CENTRAL_DEFLECTOR',
  COMFORT_UNIT = 'COMFORT_UNIT',
  CENTRAL_CONSOLE = 'CENTRAL_CONSOLE',
  TRUNK_SHELF = 'TRUNK_SHELF',
}

export enum CarDetailEnvironmentCodeEnum {
  CEIL_UPHOLSTERY = 'CEIL_UPHOLSTERY',
  DOOR_UPHOLSTERY = 'DOOR_UPHOLSTERY',
  FRONT_RIGHT_PILLAR_UPHOLSTERY = 'FRONT_RIGHT_PILLAR_UPHOLSTERY',
  FRONT_LEFT_PILLAR_UPHOLSTERY = 'FRONT_LEFT_PILLAR_UPHOLSTERY',
  LEFT_PILLAR_UPHOLSTERY = 'LEFT_PILLAR_UPHOLSTERY',
  RIGHT_PILLAR_UPHOLSTERY = 'RIGHT_PILLAR_UPHOLSTERY',
  REAR_RIGHT_PILLAR_UPHOLSTERY = 'REAR_RIGHT_PILLAR_UPHOLSTERY',
  REAR_LEFT_PILLAR_UPHOLSTERY = 'REAR_LEFT_PILLAR_UPHOLSTERY',
  SALON_MIRROR = 'SALON_MIRROR',
}

export const CarDetailCodeEnum = {
  ...CarDetailExteriorCodeEnum,
  ...CarDetailTireCodeEnum,
  ...CarDetailInteriorCodeEnum,
  ...CarDetailEnvironmentCodeEnum,
}

export enum InspectionServiceTypeEnum {
  MOTOR_OIL = 'MOTOR_OIL',
  ANTIFREEZE = 'ANTIFREEZE',
  WIPERS = 'WIPERS',
  LAMPS = 'LAMPS',
}

export enum DiskTypeEnum {
  STAMPED = 'STAMPED',
  STAMPED_WITH_HOOD = 'STAMPED_WITH_HOOD',
  CAST = 'CAST',
}

export enum RubberTypeEnum {
  WINTER = 'WINTER',
  SUMMER = 'SUMMER',
}

export enum WheelCodeEnum {
  FRONT_RIGHT = 'FRONT_RIGHT',
  FRONT_LEFT = 'FRONT_LEFT',
  REAR_RIGHT = 'REAR_RIGHT',
  REAR_LEFT = 'REAR_LEFT',
}

export enum InspectionPhotoTypeEnum {
  CAR = 'car',
  TARIFFS = 'tariffs',
  LAST_TO = 'lastTO',
  MILEAGE = 'mileage',
  DAMAGE = 'damage',
  DRIVER_SIGNATURE = 'driverSignature',
  DAMAGES_MAP = 'damagesMap',
}

export enum IncidentResponsibleEnum {
  DRIVER = 'DRIVER',
  NOT_DRIVER = 'NOT_DRIVER',
  UNKNOWN = 'UNKNOWN',
}
