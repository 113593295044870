import { IonContent, IonModal as IonicIonModal } from '@ionic/react'
import { styled, Box } from '@mui/material'
import { BODY_MAX_WIDTH, myPalette } from '../theme'

export const IonModal = styled(IonicIonModal)<{ isAutoHeight?: boolean }>(
  ({ isAutoHeight }) => ({
    '--max-width': BODY_MAX_WIDTH,
    '--border-radius': '4px',
    ...(isAutoHeight ? { '--height': 'auto !important' } : {}),

    '&::part(handle)': {
      width: '110px',
      background: myPalette.dark.drawerTopLine,
      top: '12px',
      height: '4px',
    },
  }),
)

export const IonContentCustom = styled(IonContent)`
  --background: ${myPalette.aliceBlue.main};

  &::part(scroll) {
    overscroll-behavior: none;
  }
`

export const ContentWrapper = styled(Box)`
  padding: 28px 16px 24px;
  background: ${myPalette.aliceBlue.main};
  height: 100%;
`
