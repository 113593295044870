import {
  InspectionDocumentAttributes,
  InspectionEquipmentAttributes,
} from '~/shared/api'

export const getEquipmentShortage = (
  lastCarEquipments: InspectionEquipmentAttributes[],
  currentEquipments: Record<string, boolean>,
) => {
  const selectedEquipmentsIds = Object.entries(currentEquipments)
    .filter(([, value]) => value)
    .map(([id]) => id)

  return lastCarEquipments.filter(
    (lastCarEquipment) =>
      lastCarEquipment.value &&
      !selectedEquipmentsIds.includes(lastCarEquipment.equipmentId),
  )
}

export const getDocumentShortage = (
  lastDocuments: InspectionDocumentAttributes[],
  currentDocuments: Record<string, boolean>,
) => {
  const selectedDocumentsTypes = Object.entries(currentDocuments)
    .filter(([, value]) => value)
    .map(([type]) => type)

  return lastDocuments.filter(
    (lastDocument) =>
      lastDocument.value && !selectedDocumentsTypes.includes(lastDocument.type),
  )
}
