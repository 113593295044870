import { LoadingButton } from '@mui/lab'
import { Box, Typography, Unstable_Grid2 as Grid } from '@mui/material'
import { useStore } from 'effector-react'
import React, { useCallback } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { FormContainer } from 'react-hook-form-mui'
import {
  $lastServices,
  $savingPhotosCount,
  savePhoto,
} from '~/entities/Inspection/model'
import {
  replacingHeadlightsOptions,
  replacingWipersOptions,
} from '~/shared/config/constants'
import {
  InspectionPhotoTypeEnum,
  InspectionServiceTypeEnum,
} from '~/shared/config/enums'
import { formatDateForUI } from '~/shared/lib/date'
import {
  ModalButtonCheckboxes,
  ModalButtonRange,
  Camera,
} from '~/shared/ui/form'
import { ButtonField } from './styled'
import { CarUnderHoodInspectionFormValues } from './types'

type CarUnderHoodInspectionFormProps = {
  onSuccess: () => void
  form: UseFormReturn<CarUnderHoodInspectionFormValues>
}

export function CarUnderHoodInspectionForm({
  onSuccess,
  form,
}: CarUnderHoodInspectionFormProps) {
  const savingPhotoCount =
    useStore($savingPhotosCount)[InspectionPhotoTypeEnum.LAST_TO]
  const isPhotoPending = savingPhotoCount > 0

  const lastServices = useStore($lastServices)

  const getLastServicesDateByType = useCallback(
    (type: InspectionServiceTypeEnum) => {
      const foundLastServices = lastServices?.find(
        (service) => service.type === type,
      )
      return foundLastServices?.createdAt
        ? formatDateForUI(foundLastServices.createdAt)
        : null
    },
    [lastServices],
  )

  const motorOilLastDate = getLastServicesDateByType(
    InspectionServiceTypeEnum.MOTOR_OIL,
  )
  const antifreezeLastDate = getLastServicesDateByType(
    InspectionServiceTypeEnum.ANTIFREEZE,
  )
  const wipersLastDate = getLastServicesDateByType(
    InspectionServiceTypeEnum.WIPERS,
  )
  const lampsLastDate = getLastServicesDateByType(
    InspectionServiceTypeEnum.LAMPS,
  )

  return (
    <Box>
      <FormContainer formContext={form} onSuccess={onSuccess}>
        <Grid container>
          <Grid xs={12}>
            <ButtonField>
              <Box>
                <Typography fontSize={18}>Долив масла</Typography>
                {motorOilLastDate && (
                  <>
                    <Typography fontSize={14} color='dimGray.main'>
                      Последнее действие
                    </Typography>
                    <Typography fontSize={14} color='dimGray.main'>
                      {motorOilLastDate}
                    </Typography>
                  </>
                )}
              </Box>
              <ModalButtonRange
                title='Долив масла'
                buttonTitle='Добавить'
                description='Выберите количество мл, которое необходимо долить'
                suffix=' мл'
                step={50}
                name={InspectionServiceTypeEnum.MOTOR_OIL}
              />
            </ButtonField>
          </Grid>

          <Grid xs={12}>
            <ButtonField>
              <Box>
                <Typography fontSize={18}>Долив антифриза</Typography>
                {antifreezeLastDate && (
                  <>
                    <Typography fontSize={14} color='dimGray.main'>
                      Последнее действие
                    </Typography>
                    <Typography fontSize={14} color='dimGray.main'>
                      {antifreezeLastDate}
                    </Typography>
                  </>
                )}
              </Box>
              <ModalButtonRange
                title='Долив антифриза'
                buttonTitle='Добавить'
                description='Выберите количество мл, которое необходимо долить'
                suffix=' мл'
                step={50}
                name={InspectionServiceTypeEnum.ANTIFREEZE}
              />
            </ButtonField>
          </Grid>

          <Grid xs={12}>
            <ButtonField>
              <Box>
                <Typography fontSize={18}>Замена дворников</Typography>
                {wipersLastDate && (
                  <>
                    <Typography fontSize={14} color='dimGray.main'>
                      Последнее действие
                    </Typography>
                    <Typography fontSize={14} color='dimGray.main'>
                      {wipersLastDate}
                    </Typography>
                  </>
                )}
              </Box>
              <ModalButtonCheckboxes
                title='Замена дворников'
                buttonTitle='Добавить'
                description='Укажите дворник, который подлежит замене'
                name={InspectionServiceTypeEnum.WIPERS}
                options={replacingWipersOptions}
              />
            </ButtonField>
          </Grid>

          <Grid xs={12}>
            <ButtonField>
              <Box>
                <Typography fontSize={18}>Замена лампочек</Typography>
                {lampsLastDate && (
                  <>
                    <Typography fontSize={14} color='dimGray.main'>
                      Последнее действие
                    </Typography>
                    <Typography fontSize={14} color='dimGray.main'>
                      {lampsLastDate}
                    </Typography>
                  </>
                )}
              </Box>
              <ModalButtonCheckboxes
                title='Замена лампочек'
                buttonTitle='Добавить'
                description='Укажите фару в которой нужно заменить лампочку'
                name={InspectionServiceTypeEnum.LAMPS}
                options={replacingHeadlightsOptions}
              />
            </ButtonField>
          </Grid>

          <Grid xs={12} sx={{ p: '16px 0' }}>
            <Camera
              buttonTitle='Добавить фото бирки ТО'
              name='lastTOPhotos'
              saveFileFn={(file) =>
                savePhoto({
                  file,
                  type: InspectionPhotoTypeEnum.LAST_TO,
                  form,
                  fieldName: 'lastTOPhotos',
                })
              }
              isLoading={isPhotoPending}
            />
          </Grid>

          <Grid xs={12} sx={{ mt: '16px' }}>
            <LoadingButton fullWidth type='submit' variant='limeFlooded'>
              Далее
            </LoadingButton>
          </Grid>
        </Grid>
      </FormContainer>
    </Box>
  )
}
