import { Box, styled } from '@mui/material'

export const Wrapper = styled(Box)`
  margin: 0 -16px 24px;
  padding: 0 16px;
  min-height: 460px;

  .react-transform-wrapper {
    margin-left: -16px;
    width: calc(100% + 32px);
  }

  .react-transform-component {
    display: block;
    width: 100%;
  }

  img {
    pointer-events: auto !important;
  }
`
