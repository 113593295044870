import { createDomain, sample } from 'effector'
import { createGate } from 'effector-react'
import { firstStepConfig, stepsConfigs } from './constant'

const domain = createDomain('features.inspection.step')

export const Gate = createGate()

export const goToNextStep = domain.createEvent()
export const goToBackStep = domain.createEvent()
export const goToFirstStep = domain.createEvent()

export const $currentStep = domain
  .createStore<string>(firstStepConfig)
  .on(goToNextStep, (state) => {
    const currentIndexStep = stepsConfigs.findIndex((step) => state === step)
    return stepsConfigs[currentIndexStep + 1]
  })
  .on(goToBackStep, (state) => {
    const currentIndexStep = stepsConfigs.findIndex((step) => state === step)
    return stepsConfigs[currentIndexStep - 1]
  })
  .on(goToFirstStep, () => firstStepConfig)

sample({
  clock: Gate.close,
  target: [goToFirstStep],
})
