import { isPlatform } from '@ionic/react'
import {
  DiskTypeEnum,
  DocumentsTypeEnum,
  IncidentResponsibleEnum,
  RubberTypeEnum,
  WheelCodeEnum,
} from '~/shared/config/enums'

export const AUTH_TOKEN_STORAGE_KEY = 'auth_token'
export const AUTH_CURRENT_USER_ID_STORAGE_KEY = 'auth_current_user_id'
export const HEADER_SEARCH_KEY = 'search'
export const USER_PERMISSIONS_STORAGE_KEY = 'user_permissions'

export const isMobileApp = isPlatform('capacitor') || isPlatform('cordova')
export const isIos = isMobileApp && isPlatform('ios')
export const isAndroid = isMobileApp && isPlatform('android')

export type Option = { id: UniqueId; label: string } & Record<string, unknown>
export type OptionEnum<T> = { id: T; label: string }

export const replacingWipersOptions: Option[] = [
  'Передний левый',
  'Передний правый',
  'Задний',
].map((value) => {
  return {
    label: value,
    id: value,
  }
})

export const replacingHeadlightsOptions: Option[] = [
  'Передняя левая фара',
  'Передняя правая фара',
  'Задняя левая фара',
  'Задняя правая фара',
].map((value) => {
  return {
    label: value,
    id: value,
  }
})

export const diskTypeEnumLabels: Record<DiskTypeEnum, string> = {
  [DiskTypeEnum.STAMPED]: 'Штампованный',
  [DiskTypeEnum.STAMPED_WITH_HOOD]: 'Штампованный с колпаком',
  [DiskTypeEnum.CAST]: 'Литые',
}
export const diskTypeEnumOptions: OptionEnum<DiskTypeEnum>[] = Object.keys(
  DiskTypeEnum,
).map((value) => {
  const label = diskTypeEnumLabels[value as keyof typeof DiskTypeEnum]
  return {
    label,
    id: value as DiskTypeEnum,
  }
})

export const rubberTypeEnumLabels: Record<RubberTypeEnum, string> = {
  [RubberTypeEnum.WINTER]: 'Летняя',
  [RubberTypeEnum.SUMMER]: 'Зимняя',
}
export const rubberTypeEnumOptions: OptionEnum<RubberTypeEnum>[] = Object.keys(
  RubberTypeEnum,
).map((value) => {
  const label = rubberTypeEnumLabels[value as keyof typeof RubberTypeEnum]
  return {
    label,
    id: value as RubberTypeEnum,
  }
})

export const wheelCodeEnumLabels: Record<WheelCodeEnum, string> = {
  [WheelCodeEnum.FRONT_LEFT]: 'Переднее левое колесо',
  [WheelCodeEnum.FRONT_RIGHT]: 'Переднее правое колесо',
  [WheelCodeEnum.REAR_LEFT]: 'Заднее левое колесо',
  [WheelCodeEnum.REAR_RIGHT]: 'Заднее правое колесо',
}
export const wheelCodeEnumOptions: OptionEnum<WheelCodeEnum>[] = Object.keys(
  WheelCodeEnum,
).map((value) => {
  const label = wheelCodeEnumLabels[value as keyof typeof WheelCodeEnum]
  return {
    label,
    id: value as WheelCodeEnum,
  }
})

export const documentsCodeEnumLabels: Record<DocumentsTypeEnum, string> = {
  [DocumentsTypeEnum.OSAGO]: 'Осаго',
  [DocumentsTypeEnum.STS]: 'СТС',
  [DocumentsTypeEnum.DC]: 'Диагностическая карта',
  [DocumentsTypeEnum.LICENSE]: 'Лицензия',
}
export const documentsCodeEnumOptions: OptionEnum<DocumentsTypeEnum>[] =
  Object.keys(DocumentsTypeEnum).map((value) => {
    const label =
      documentsCodeEnumLabels[value as keyof typeof DocumentsTypeEnum]
    return {
      label,
      id: value as DocumentsTypeEnum,
    }
  })

export const incidentResponsibleEnumLabels: Record<
  IncidentResponsibleEnum,
  string
> = {
  [IncidentResponsibleEnum.DRIVER]: 'Водитель',
  [IncidentResponsibleEnum.NOT_DRIVER]: 'Не водитель',
  [IncidentResponsibleEnum.UNKNOWN]: 'Не установлен',
}
export const incidentResponsibleEnumOptions: OptionEnum<IncidentResponsibleEnum>[] =
  Object.keys(IncidentResponsibleEnum).map((value) => {
    const label =
      incidentResponsibleEnumLabels[
        value as keyof typeof IncidentResponsibleEnum
      ]
    return {
      label,
      id: value as IncidentResponsibleEnum,
    }
  })
