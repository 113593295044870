import {
  Dialog as MuiDialog,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  styled,
} from '@mui/material'
import { BODY_MAX_WIDTH } from '~/shared/ui/theme'

export const Dialog = styled(MuiDialog)`
  .MuiPaper-root {
    width: calc(100% - 32px);
    max-width: calc(${BODY_MAX_WIDTH} - 32px);
    box-shadow: none;
    margin: 24px;
  }
`

export const DialogContent = styled(MuiDialogContent)`
  padding: 16px 24px 0;
  overflow-x: hidden;
`

export const DialogActions = styled(MuiDialogActions)<{
  $reverse?: boolean
}>`
  justify-content: space-between;
  padding: 10px 14px;
  flex-direction: ${({ $reverse }) => ($reverse ? 'row-reverse' : 'row')};
`
