import { debounce } from 'lodash'
import { useQueryParam } from 'use-query-params'
import { constants } from '~/shared/config'
import { SCROLLABLE_LIST_PAGE_KEY } from '~/shared/ui/ScrollableList'
import { IonSearchbar } from './styled'

export function Search() {
  const [search, setSearch] = useQueryParam<string>(constants.HEADER_SEARCH_KEY)
  const [, setPage] = useQueryParam<number>(SCROLLABLE_LIST_PAGE_KEY)

  const handleChange = (value: string) => {
    setSearch(value, 'replace')
    if (!value) setPage(1, 'replace')
  }

  const debouncedSearchChange = debounce(handleChange, 400)

  return (
    <IonSearchbar
      style={{ padding: '0', fontSize: '24px', marginRight: '16px' }}
      animated={true}
      placeholder='Введите номер авто'
      color='warning'
      onIonInput={(e) => {
        debouncedSearchChange(e.detail.value || '')
      }}
      value={search}
      onIonClear={() => {
        debouncedSearchChange('')
      }}
    />
  )
}
