import { IonActionSheet as IonicIonActionSheet } from '@ionic/react'
import MuiArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { Box, styled, Typography } from '@mui/material'
import { BODY_MAX_WIDTH, myPalette } from '~/shared/ui/theme'

export const ArrowDropDownIcon = styled(MuiArrowDropDownIcon)<{
  $reverse?: boolean
}>(
  ({ $reverse }) => `
    transform: rotate(${$reverse ? 180 : 0}deg);
    position: absolute;
    right: 12px;
    bottom: 12px;
    zIndex: 1;
`,
)

export const TextFieldElementWrapper = styled(Box)<{
  $disabled?: boolean
}>(
  ({ $disabled }) => `
    position: relative;
    width: 100%;
    height: 57px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.23);
    cursor: pointer;
    ${$disabled ? 'pointer-event: none' : ''};
`,
)

export const TextFieldLabel = styled(Typography)<{
  $isTop?: boolean
}>(
  ({ $isTop }) => `
    position: absolute;
    transition: .03s;
    left: 0;
    width: 100%;

    font-size: ${$isTop ? '12px' : '16px'};
    top: ${$isTop ? '0' : '22px'};
`,
)

export const TextFieldValue = styled(Typography)`
  padding-top: 22px;
`

export const IonActionSheet = styled(IonicIonActionSheet)`
  --max-width: ${BODY_MAX_WIDTH};
  --background: ${myPalette.aliceBlue.main};

  .action-sheet-group {
    border-radius: 4px 4px 0 0;
    padding: 0 16px 24px !important;
  }

  .action-sheet-title {
    font-size: 24px;
    padding-left: 0;
    font-weight: 500;
    color: ${myPalette.black.main};
  }

  .action-sheet-button {
    padding: 8px 16px;
    font-size: 18px;
    min-height: 44px;
    margin: 0;
    border-radius: 4px;

    &:hover {
      margin: 0;
    }

    &.action-sheet-selected {
      font-weight: 500;
      background: rgba(33, 150, 243, 0.08);
    }
  }
`
