import { Box, styled, Button as MuiButton } from '@mui/material'

export const Wrapper = styled(Box)`
  cursor: pointer;
  height: 100px;
  border-radius: 16px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 0 5px 0 rgba(0, 21, 70, 0.1);
  margin-bottom: 8px;
`

export const ContentWrapper = styled(Box)`
  padding: 16px 24px 16px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
`

export const Description = styled(Box)`
  display: flex;
  justify-content: space-between;
`

export const Button = styled(MuiButton)`
  && {
    min-width: 83px;
    display: flex;
    flex-direction: column;
    border-radius: 0;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
  }
`
