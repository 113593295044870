import { Box, styled } from '@mui/material'
import { myPalette } from '~/shared/ui/theme'

export const ButtonField = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;
  border-bottom: 1px solid ${myPalette.blue.lightSteelBlue};
`
