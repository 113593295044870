import { useCallback, useState } from 'react'

export const useDrawer = () => {
  const [isDrawerShow, toggleIsDrawerShow] = useState(false)

  const openDrawer = useCallback(() => {
    toggleIsDrawerShow(true)
  }, [])

  const closeDrawer = useCallback(() => {
    toggleIsDrawerShow(false)
  }, [])

  return {
    isDrawerShow,
    openDrawer,
    closeDrawer,
  }
}
