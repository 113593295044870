import {
  AppUpdate,
  AppUpdateAvailability,
} from '@capawesome/capacitor-app-update'
import { isPlatform } from '@ionic/react'
import { createDomain, sample } from 'effector'
import { createGate } from 'effector-react'
import { interval } from 'patronum'
import { isDevEnv } from '~/shared/config/env'

export const Gate = createGate()
const domain = createDomain('reload.app')

const checkAppUpdateFx = domain.createEffect({
  handler: async () => {
    try {
      const updateInfo = await AppUpdate.getAppUpdateInfo()
      if (isPlatform('android')) {
        return updateInfo.immediateUpdateAllowed
      }
      if (isPlatform('ios')) {
        return (
          updateInfo.updateAvailability ===
          AppUpdateAvailability.UPDATE_AVAILABLE
        )
      }
      return false
    } catch (e) {
      return false
    }
  },
})

export const openAppStore = domain.createEvent()
export const openAppStoreFx = domain.createEffect({
  handler: async () => {
    await AppUpdate.openAppStore()
  },
})

sample({
  clock: openAppStore,
  target: openAppStoreFx,
})

export const $needUpdateApp = domain
  .createStore<boolean>(false)
  .on(checkAppUpdateFx.doneData, (_, balance) => balance)
  .on(Gate.close, () => false)

sample({
  clock: Gate.open,
  target: checkAppUpdateFx,
})

const intervalRequestStarted = domain.createEvent()
const intervalRequestStopped = domain.createEvent()

if (!isDevEnv) {
  const { tick } = interval({
    timeout: 60000,
    start: intervalRequestStarted,
    stop: intervalRequestStopped,
    leading: false,
    trailing: false,
  })

  sample({
    clock: tick,
    target: checkAppUpdateFx,
  })
  sample({
    clock: Gate.open,
    target: intervalRequestStarted,
  })
  sample({
    clock: Gate.close,
    target: intervalRequestStopped,
  })
}
