import { Typography } from '@mui/material'
import { Modal } from '~/shared/ui/Modal'

type RejectInspectionModal = {
  isShow: boolean
  onClose: () => void
  onOk: () => void
}

export function RejectInspectionModal({
  isShow,
  onClose,
  onOk,
}: RejectInspectionModal) {
  return (
    <Modal
      isShow={isShow}
      onCancel={onClose}
      onOk={onOk}
      cancel='Нет'
      ok='Да'
      canBackdropClose={false}
      reverseButton
    >
      <Typography fontSize={20} fontWeight={500} mb='16px'>
        Вы хотите прервать осмотр?
      </Typography>
      <Typography fontSize={16} mb='26px'>
        После того как вы прервете осмотр данные не сохранятся
      </Typography>
    </Modal>
  )
}
