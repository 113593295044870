import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Typography } from '@mui/material'
import { Wrapper, ContentWrapper, Button, Description } from './styled'

type CardProps = {
  isBegin?: boolean
  onStart?: () => void
  plateNumber: string
  reason: string
  time: string
}

export function Card({
  isBegin,
  onStart,
  plateNumber,
  reason,
  time,
}: CardProps) {
  return (
    <Wrapper>
      <ContentWrapper>
        <Typography fontSize='24px'>{plateNumber}</Typography>
        <Description>
          <Typography fontSize='14px'>{reason}</Typography>
          <Typography fontSize='14px'>{time}</Typography>
        </Description>
      </ContentWrapper>
      {isBegin && (
        <Button
          variant='limeFlooded'
          size='small'
          sx={{ textTransform: 'none' }}
          onClick={onStart}
        >
          <ArrowForwardIcon />
          Начать
        </Button>
      )}
    </Wrapper>
  )
}
