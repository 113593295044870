import { Box, Typography } from '@mui/material'
import React from 'react'
import { LoginForm } from '~/features/auth/login'

export function AuthForms() {
  return (
    <Box sx={{ width: '100%', padding: '84px 16px 16px' }}>
      <Typography
        fontSize='40px'
        marginBottom='104px'
        sx={{ textAlign: 'center' }}
      >
        Авторизация
      </Typography>
      <LoginForm />
    </Box>
  )
}
