import { List, ListItem, Skeleton } from '@mui/material'

type SkeletonListProps = {
  count: number
  skeletonHeight?: number
  itemMarginBottom?: string
}

export function SkeletonList({
  count,
  skeletonHeight = 118,
  itemMarginBottom,
}: SkeletonListProps) {
  const skeletons = []

  for (let i = 0; i < count; i++) {
    skeletons.push(
      <Skeleton
        variant='rectangular'
        width='100%'
        height={skeletonHeight}
        sx={{ borderRadius: '12px' }}
      />,
    )
  }

  return (
    <List>
      {skeletons.map((skeleton, index) => (
        <ListItem
          key={index}
          sx={{
            p: 0,
            ...(itemMarginBottom ? { marginBottom: itemMarginBottom } : {}),
          }}
        >
          {skeleton}
        </ListItem>
      ))}
    </List>
  )
}
