import { z } from 'zod'
import { Inspection, InspectionFetchOption, ThumbType } from '~/shared/api'
import { uuidOptionSchema } from '~/shared/lib/schemas'
import { sanitizeNumber } from '~/shared/lib/utils'

export const specificationsFormSchema = Inspection.schema
  .pick({
    incidentResponsible: true,
    isExteriorClean: true,
    isCarRefueled: true,
    withDriverParticipation: true,
    isPlanned: true,
  })
  .merge(
    z.object({
      reasonOption: uuidOptionSchema,
      mileage: z.string().or(z.number()).transform(sanitizeNumber),
    }),
  )

export type TypeFormSchema = z.infer<typeof specificationsFormSchema>

export type SpecificationsFormValues = Omit<TypeFormSchema, 'reasonOption'> & {
  reasonOption: InspectionFetchOption
  carPhotos?: ThumbType[]
  mileagePhotos?: ThumbType[]
}
