import { LoadingButton } from '@mui/lab'
import { ButtonGroup, Typography } from '@mui/material'
import { useStore, useStoreMap } from 'effector-react'
import { useMemo, useState } from 'react'
import { inspectionFormModel } from '~/entities/Inspection'
import { InspectionPhotoTypeEnum } from '~/shared/config/enums'
import { ImageSliderModal } from '~/shared/ui/ImageSliderModal'
import { useModal } from '~/shared/ui/Modal'

type SelectedCarPhotos = 'initial' | 'rental' | 'confiscation'

export function CarPhotos() {
  const { isModalShow, openModal, closeModal } = useModal()

  const [selectedCarPhotos, setSelectedCarPhotos] = useState<
    'initial' | 'rental' | 'confiscation'
  >()

  const carInitialPhotos = useStoreMap(
    inspectionFormModel.$lastInitialInspection,
    (inspection) =>
      inspection
        ?.getPhotos()
        .filter((photo) => photo.type === InspectionPhotoTypeEnum.CAR),
  )
  const carInitialPhotosPending = useStore(
    inspectionFormModel.requestLastInitialInspectionFx.pending,
  )

  const carRentalPhotos = useStoreMap(
    inspectionFormModel.$lastRentalInspection,
    (inspection) =>
      inspection
        ?.getPhotos()
        .filter((photo) => photo.type === InspectionPhotoTypeEnum.CAR),
  )
  const carRentalPhotosPending = useStore(
    inspectionFormModel.requestLastRentalInspectionFx.pending,
  )

  const carConfiscationPhotos = useStoreMap(
    inspectionFormModel.$lastConfiscationInspection,
    (inspection) =>
      inspection
        ?.getPhotos()
        .filter((photo) => photo.type === InspectionPhotoTypeEnum.CAR),
  )
  const carConfiscationPhotosPending = useStore(
    inspectionFormModel.requestLastConfiscationInspectionFx.pending,
  )

  const handleSelectedCarPhotosChange = (type: SelectedCarPhotos) => {
    setSelectedCarPhotos(type)
    openModal()
  }

  const carPhotos = useMemo(() => {
    if (selectedCarPhotos === 'initial') return carInitialPhotos
    if (selectedCarPhotos === 'rental') return carRentalPhotos
    if (selectedCarPhotos === 'confiscation') return carConfiscationPhotos
    if (!selectedCarPhotos) return
  }, [
    carConfiscationPhotos,
    carInitialPhotos,
    carRentalPhotos,
    selectedCarPhotos,
  ])

  const emptyTitle = useMemo(() => {
    if (selectedCarPhotos === 'initial') return 'первичного осмотра'
    if (selectedCarPhotos === 'rental') return 'выдачи'
    if (selectedCarPhotos === 'confiscation') return 'изъятия'
  }, [selectedCarPhotos])

  return (
    <>
      <Typography sx={{ textAlign: 'center', marginBottom: '6px' }}>
        Круговые фото автомобиля
      </Typography>
      <ButtonGroup
        sx={{
          width: '100%',
          marginBottom: '16px',
          '.MuiButtonBase-root': {
            fontSize: '12px',
          },
        }}
      >
        <LoadingButton
          variant='whiteBorder'
          fullWidth
          loading={carInitialPhotosPending}
          onClick={() => handleSelectedCarPhotosChange('initial')}
        >
          Первичный
        </LoadingButton>
        <LoadingButton
          variant='whiteBorder'
          fullWidth
          loading={carRentalPhotosPending}
          onClick={() => handleSelectedCarPhotosChange('rental')}
        >
          Выдача
        </LoadingButton>
        <LoadingButton
          variant='whiteBorder'
          fullWidth
          loading={carConfiscationPhotosPending}
          onClick={() => handleSelectedCarPhotosChange('confiscation')}
        >
          Изъятие
        </LoadingButton>
      </ButtonGroup>

      <ImageSliderModal
        photos={carPhotos}
        isShow={isModalShow}
        onCancel={closeModal}
        title='Круговые фото автомобиля'
        empty={`Фото ${emptyTitle} отсутствуют`}
      />
    </>
  )
}
