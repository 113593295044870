import { Box, styled, IconButton as MuiIconButton } from '@mui/material'
import { myPalette } from '~/shared/ui/theme'

export const IconButton = styled(MuiIconButton)`
  width: 32px;
  height: 32px;
  padding: 0;
  background: ${myPalette.prussianBlue.main};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 0;

  &:hover {
    background: ${myPalette.prussianBlue.main};
  }
  &:disabled {
    background: ${myPalette.prussianBlue.main};
    opacity: 0.5;
  }
`

export const InputWrapper = styled(Box)`
  display: flex;
  overflow: hidden;
`

export const Amount = styled(Box)`
  font-size: 24px;
  width: 130px;
  height: 32px;
  text-align: center;
`
