import { Typography } from '@mui/material'
import { Modal } from '~/shared/ui/Modal'

type NotCompleteSetModalProps = {
  isShow: boolean
  onCancel: () => void
  onOk: () => void
  values?: string[]
}

export function NotCompleteSetModal({
  isShow,
  onCancel,
  onOk,
  values,
}: NotCompleteSetModalProps) {
  return (
    <Modal
      isShow={isShow}
      onCancel={onCancel}
      onOk={onOk}
      cancel='Нет'
      ok='Да'
      reverseButton
    >
      <Typography fontSize='20px' fontWeight={500} mb='16px'>
        Не полная комплектация
      </Typography>
      <Typography fontSize='16px'>
        На текущем осмотре выявлена нехватка комплектности
      </Typography>

      <Typography component='ul' m='16px 0' pl='20px'>
        {values?.map((value) => (
          <Typography key={value} component='li' fontWeight={500}>
            {value}
          </Typography>
        ))}
      </Typography>
      <Typography fontSize='16px'>Хотите продолжить?</Typography>
    </Modal>
  )
}
