import { Tab, Tabs } from '@mui/material'
import { useStore } from 'effector-react'
import { SyntheticEvent, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import {
  CarCabinInspectionForm,
  CarCabinInspectionFormValues,
  Defect,
  getEquipmentShortage,
  Header,
  inspectionFormModel,
  LegendModal,
  NotCompleteSetModal,
} from '~/entities/Inspection'
import { InspectionReasonCodeEnum } from '~/shared/config/enums'
import { useModal } from '~/shared/ui/Modal'
import { myPalette } from '~/shared/ui/theme'
import { goToNextStep } from '../stepModel'
import { EnvironmentMarkers, InteriorMarkers } from './Tabs'

type CarCabinInspectionStepProps = {
  form: UseFormReturn<CarCabinInspectionFormValues>
  defectsInterior: Defect[]
  onDefectsInteriorSet: (defects: Defect[]) => void
  defectsEnvironment: Defect[]
  onDefectsEnvironmentSet: (defects: Defect[]) => void
  canDefectRepair: boolean
  reasonCode: InspectionReasonCodeEnum
}

enum TabEnum {
  interior = 'interior',
  environment = 'environment',
}

export function CarCabinInspectionStep({
  form,
  defectsInterior,
  onDefectsInteriorSet,
  defectsEnvironment,
  onDefectsEnvironmentSet,
  canDefectRepair,
  reasonCode,
}: CarCabinInspectionStepProps) {
  const { isModalShow, openModal, closeModal } = useModal()

  const lastEquipments = useStore(inspectionFormModel.$lastEquipmentsInterior)

  const [unselectedEquipmentsTitles, setUnselectedEquipmentsTitles] = useState<
    string[]
  >([])

  const onSuccess = () => {
    const foundUnselectedEquipments = getEquipmentShortage(
      lastEquipments,
      form.getValues('equipmentsInterior'),
    )

    if (foundUnselectedEquipments?.length) {
      setUnselectedEquipmentsTitles(
        foundUnselectedEquipments.map(({ title }) => title),
      )
      openModal()
      return
    }

    goToNextStep()
  }

  const handleModalOk = () => {
    closeModal()
    goToNextStep()
  }

  const [selectedTab, setSelectedTab] = useState<TabEnum>(TabEnum.interior)

  const handleChange = (event: SyntheticEvent, selectedTab: TabEnum) => {
    setSelectedTab(selectedTab)
  }

  return (
    <>
      <Header title='Осмотр салона' extra={<LegendModal />} />

      <Tabs
        value={selectedTab}
        onChange={handleChange}
        sx={{
          marginBottom: '16px',
          'button.Mui-selected': {
            color: myPalette.prussianBlue.main,
          },
          '.MuiTabs-indicator': {
            backgroundColor: myPalette.prussianBlue.main,
          },
        }}
      >
        <Tab label='Салон' value={TabEnum.interior} />
        <Tab label='Окружение' value={TabEnum.environment} />
      </Tabs>

      {selectedTab === TabEnum.interior && (
        <InteriorMarkers
          defects={defectsInterior}
          onDefectsSet={onDefectsInteriorSet}
          canDefectRepair={canDefectRepair}
        />
      )}
      {selectedTab === TabEnum.environment && (
        <EnvironmentMarkers
          defects={defectsEnvironment}
          onDefectsSet={onDefectsEnvironmentSet}
          canDefectRepair={canDefectRepair}
        />
      )}

      <CarCabinInspectionForm
        reasonCode={reasonCode}
        onSuccess={onSuccess}
        form={form}
      />

      <NotCompleteSetModal
        isShow={isModalShow}
        onCancel={closeModal}
        onOk={handleModalOk}
        values={unselectedEquipmentsTitles}
      />
    </>
  )
}
