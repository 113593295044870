import { Stack, Typography } from '@mui/material'
import { ReactNode } from 'react'

type HeaderProps = {
  title: string
  extra?: ReactNode
}

export function Header({ title, extra }: HeaderProps) {
  return (
    <Stack direction='row' justifyContent='space-between' sx={{ mb: '12px' }}>
      <Typography fontSize='24px'>{title}</Typography>

      {extra}
    </Stack>
  )
}
