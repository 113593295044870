import { Button, Stack, Typography } from '@mui/material'
import { orange } from '@mui/material/colors'
import { Modal, useModal } from '~/shared/ui/Modal'
import { myPalette } from '~/shared/ui/theme'
import { Circle, Degree, Img, ItemWrapper } from './styled'

export function LegendModal() {
  const { isModalShow, openModal, closeModal } = useModal()

  return (
    <>
      <Button variant='text' onClick={openModal}>
        Легенда
      </Button>

      <Modal
        isShow={isModalShow}
        onCancel={closeModal}
        reverseButton
        cancel='Закрыть'
      >
        <Typography sx={{ mb: '12px' }}>Легенда</Typography>
        <Stack spacing={2} sx={{ fontSize: '12px' }}>
          <ItemWrapper>
            <Degree>Л</Degree> - Легкое
          </ItemWrapper>
          {/*<ItemWrapper>*/}
          {/*  <Degree>С</Degree> - Среднее*/}
          {/*</ItemWrapper>*/}
          <ItemWrapper>
            <Degree>Т</Degree> - Тяжелое
          </ItemWrapper>

          <ItemWrapper>
            <Img src='/images/marker.svg' alt='marker' />- выявленное в данном
            осмотре
          </ItemWrapper>
          <ItemWrapper>
            <Circle sx={{ background: orange[500] }} />- выявленное в предыдущих
            осмотрах
          </ItemWrapper>
          <ItemWrapper>
            <Circle sx={{ background: myPalette.limeGreen.main }} />-
            отремонтированное повреждение
          </ItemWrapper>
        </Stack>
      </Modal>
    </>
  )
}
