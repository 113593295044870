import * as z from 'zod'
import { dateSchema } from '~/shared/lib/schemas'
import { ApiModel } from './core'

const schema = z.object({
  mileage: z.number(),
  createdAt: dateSchema,
  updatedAt: dateSchema.optional(),
})

export type RepairOrderAttributes = z.infer<typeof schema>

export class RepairOrder extends ApiModel<
  typeof schema,
  RepairOrderAttributes
> {
  static jsonApiType = 'car-details'

  static schema = schema
}
