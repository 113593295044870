import { PredefinedColors } from '@ionic/core'
import { createDomain } from 'effector'

const domain = createDomain('notifications')

type EnqueueParams = {
  message: string
  variant: PredefinedColors
}

export const snackbarEnqueued = domain.createEvent<EnqueueParams>()
export const snackbarClosed = domain.createEvent<string>()
export const allSnackbarsClosed = domain.createEvent()

type Snackbar = {
  key: string
} & EnqueueParams

export const $notifications = domain
  .createStore<Snackbar[]>([])
  .on(snackbarEnqueued, (state, payload) => [
    ...state,
    {
      key: String(new Date().getTime() + Math.random()),
      ...payload,
    },
  ])
  .on(snackbarClosed, (notifications, key) =>
    notifications.filter((notification) => notification.key !== key),
  )
  .on(allSnackbarsClosed, () => [])
