import { IonContent, IonFooter, IonHeader, IonModal } from '@ionic/react'
import { Button, Typography } from '@mui/material'
import React, { useMemo } from 'react'
import { Defect } from '~/entities/Inspection'
import { ImageSlider } from '~/shared/ui/ImageSlider'
import { IonToolbarFooter, IonToolbarHeader } from '~/shared/ui/PageContainer'
import { WrapperFooter, WrapperHeader } from './styled'

type DefectRepairModalProps = {
  selectedDefect?: Defect
  isShow: boolean
  onCancel: () => void
  onRepairOk: () => void
  onRepairCancel: () => void
  canRepair: boolean
}

export function DefectRepairModal({
  selectedDefect,
  isShow,
  onCancel,
  onRepairOk,
  onRepairCancel,
  canRepair,
}: DefectRepairModalProps) {
  const images = useMemo(() => {
    return (selectedDefect?.photos || []).map((photo) => ({
      id: photo.uuid,
      src: photo.originalUrl,
    }))
  }, [selectedDefect?.photos])

  if (!selectedDefect) return null

  const handleOnOk = () => {
    selectedDefect?.isRepairDamage ? onRepairCancel() : onRepairOk()
  }

  const okTitle = selectedDefect?.isRepairDamage
    ? 'Отменить ремонт'
    : 'Отремонтировать'

  return (
    <IonModal isOpen={isShow} keepContentsMounted={false}>
      <IonHeader>
        <IonToolbarHeader>
          <WrapperHeader>
            <Typography fontSize={20} fontWeight={600} color='aliceBlue.main'>
              {selectedDefect?.carDetail?.label}
            </Typography>
            <Typography fontSize={16} color='aliceBlue.main'>
              {selectedDefect?.carDamageExtent?.label} повреждение
            </Typography>
          </WrapperHeader>
        </IonToolbarHeader>
      </IonHeader>
      <IonContent
        style={{
          '--overscroll-behavior': 'none',
          '--webkit-overscroll-behavior': 'none',
          '--overflow': 'hidden',
        }}
      >
        <ImageSlider images={images} />
      </IonContent>
      <IonFooter>
        <IonToolbarFooter>
          <WrapperFooter
            sx={{
              justifyContent: canRepair ? 'space-between' : 'flex-end',
            }}
          >
            {canRepair && (
              <Button
                variant='text'
                size='large'
                onClick={handleOnOk}
                color='crimson'
              >
                {okTitle}
              </Button>
            )}
            <Button
              variant='text'
              size='large'
              color='primary'
              onClick={onCancel}
            >
              Отмена
            </Button>
          </WrapperFooter>
        </IonToolbarFooter>
      </IonFooter>
    </IonModal>
  )
}
