import { Skeleton } from '@mui/material'
import React from 'react'

export function LoadingBlock() {
  return (
    <>
      <Skeleton
        variant='rectangular'
        width='100%'
        height={36}
        sx={{ mb: '12px' }}
      />
      <Skeleton
        variant='rectangular'
        width='100%'
        height={119}
        sx={{ mb: '30px' }}
      />
      <Skeleton
        variant='rectangular'
        width='100%'
        height={57}
        sx={{ mb: '14px' }}
      />
      <Skeleton
        variant='rectangular'
        width='100%'
        height={67}
        sx={{ mb: '14px' }}
      />
      <Skeleton
        variant='rectangular'
        width='100%'
        height={46}
        sx={{ m: '2px 0' }}
      />
      <Skeleton
        variant='rectangular'
        width='100%'
        height={46}
        sx={{ m: '2px 0' }}
      />
      <Skeleton
        variant='rectangular'
        width='100%'
        height={46}
        sx={{ m: '2px 0 16px' }}
      />
      <Skeleton variant='rectangular' width='100%' height={52} />
    </>
  )
}
