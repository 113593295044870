import { Box, styled } from '@mui/material'
import { grey } from '@mui/material/colors'

export const ItemWrapper = styled(Box)`
  display: flex;
  align-items: center;
`

export const Degree = styled(Box)`
  width: 25px;
  height: 25px;
  border: 1px solid ${grey[500]};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 6px;
`

export const Img = styled('img')`
  margin-right: 6px;
  width: 25px;
`

export const Circle = styled(Box)`
  width: 25px;
  height: 25px;
  min-width: 25px;
  min-height: 25px;
  border-radius: 100%;
  margin-right: 6px;
`
