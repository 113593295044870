import { blue } from '@mui/material/colors'
import { Components } from '@mui/material/styles/components'
import { Theme } from '@mui/material/styles/createTheme'
import { myPalette } from '../myPalette'

export const MuiForm: Components<Omit<Theme, 'components'>> = {
  MuiTextField: {
    defaultProps: {
      size: 'small',
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        padding: '8px',

        '& .MuiSvgIcon-root': {
          color: `${myPalette.prussianBlue.main} !important`,
          fontSize: '32px !important',
        },
        '&.Mui-disabled': {
          color: blue[700],
        },
        '&.Mui-checked': {
          color: `${myPalette.prussianBlue.main} !important`,
        },
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        background: 'none !important',
        transition: 'none !important',
        webkitTransition: 'none !important',

        '.MuiInputBase-input': {
          height: '32px',
          paddingLeft: 0,
        },

        input: {
          fontSize: '18px',
        },

        '&::before': {
          transition: 'none !important',
          borderColor: myPalette.black.main,
          webkitTransition: 'none !important',
        },
        '&::after': {
          borderBottom: `2px solid ${myPalette.black.main} !important`,
        },
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        fontSize: '18px',
        left: '-12px !important',
      },
    },
  },
  MuiSelect: {
    styleOverrides: {
      outlined: {
        height: '31px',
        lineHeight: '31px',
      },
    },
  },
  MuiFormControl: {
    styleOverrides: {
      root: {
        '.MuiFormHelperText-root': {
          marginLeft: 0,
        },
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        '&.Mui-disabled': {
          color: 'rgba(0, 0, 0, 0.6)',
        },
      },
      outlined: {
        lineHeight: '31px',
      },
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      label: {
        '&.Mui-disabled': {
          color: 'rgba(0, 0, 0, 1)',
        },
      },
    },
  },
  MuiAutocomplete: {
    styleOverrides: {
      root: {
        '& .Mui-disabled .MuiAutocomplete-tag': {
          opacity: '1',
        },
        '& .Mui-disabled .MuiChip-deleteIcon': {
          opacity: '0.3',
        },
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        background: myPalette.blue.paleBlue,
        borderRadius: '6px',
        borderColor: myPalette.blue.lightSteelBlue,

        '& input.Mui-disabled, .MuiSelect-select.Mui-disabled': {
          color: 'rgba(0, 0, 0, 1)',
          WebkitTextFillColor: 'rgba(0, 0, 0, 1)',
        },
      },
    },
  },
  MuiRadio: {
    styleOverrides: {
      root: {
        '&.Mui-checked': {
          color: `${myPalette.prussianBlue.main} !important`,
        },
      },
    },
  },
  MuiSwitch: {
    styleOverrides: {
      root: {
        '& .Mui-checked': {
          '& .MuiSwitch-thumb': {
            backgroundColor: myPalette.prussianBlue.main,
          },
          '&+.MuiSwitch-track': {
            backgroundColor: `${myPalette.blue.cornflowerBlue} !important`,
            opacity: '1 !important',
          },
        },
      },
    },
  },
}
